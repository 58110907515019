import dayjs from "dayjs";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AccionMenu } from "src/modules/common/components/AccionMenu";
import { AccionMenuItem } from "src/modules/common/components/AccionMenuItem";
import SkeletonLoaderRow from "src/modules/common/components/SkeletonLoaderRow";
import TableCustom from "src/modules/common/components/TableCustom";
import { commonActions } from "src/modules/common/handler/redux";
import { evaluacionActions } from "../handlers/redux";
import { ESTADOS_EVALUACION } from "../helpers/helpers";

function EvaluacionPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { tipoEvaluacion } = params;
  //
  const evaluaciones = useSelector((state) => state.evaluacion.evaluaciones);
  const evaluaciones_count = useSelector(
    (state) => state.evaluacion.evaluaciones_count
  );
  const evaluaciones_loading = useSelector(
    (state) => state.evaluacion.evaluaciones_loading
  );
  const evaluaciones_checked = useSelector(
    (state) => state.evaluacion.evaluaciones_checked
  );
  const evaluaciones_filter = useSelector(
    (state) => state.evaluacion.evaluaciones_filter
  );
  const { nombre } = evaluaciones_filter;
  //
  const EvaluacionListItem = ({ item }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const onClick = () => {
      navigate(`/evaluaciones/detail/${item.id}`);
    };
    const getEstadoStyle = (id) => {
      const styles = ["bg-green-500 text-white", "bg-zinc-200 text-black"];
      return styles[id - 1] || "";
    };
    const onEditarConfiguracion = () => {
      navigate(
        `/evaluaciones/configuracion/${item.tipo_evaluacion}/${item.id}`
      );
    };
    const verInformeEvaluacion = () => {
      navigate(`/evaluaciones/${item.tipo_evaluacion}/${item.id}/informe`);
    };
    const duplicarEvaluacion = () => {
      //
      dispatch(evaluacionActions.setDuplicarEvaluacionId(item.id));
      navigate("/evaluaciones/configuracion/" + item.tipo_evaluacion);
    };
    const reabrirEvaluacion = (id) => () => {
      let deseaReabrir = window.confirm(
        "Desea reabrir la evaluación seleccionada?"
      );
      if (deseaReabrir) {
        dispatch(evaluacionActions.setEvaluacionReabrir(id));
      }
    };
    const onEliminar = () => {
      let deseaEliminar = window.confirm(
        "Está seguro que sea eliminar la evaluación?"
      );
      if (deseaEliminar) {
        dispatch(evaluacionActions.evaluacionDelete(item));
      }
    };
    //
    return (
      <div className="flex flex-row flex-grow items-center">
        <div
          onClick={onClick}
          className="flex flex-col flex-grow cursor-pointer gap-1"
        >
          <div className="font-bold text-sm">{item.nombre}</div>
          <div className="text-sm">
            Fecha de inicio:{" "}
            {dayjs(item.fecha_inicio?.slice(0, 10)).format("DD/MM/YYYY")}
          </div>
          <div className="text-sm">
            Fecha de finalizacion:{" "}
            {item.fecha_finalizacion
              ? dayjs(item.fecha_finalizacion?.slice(0, 10)).format(
                "DD/MM/YYYY"
              )
              : "Sin especificar"}
          </div>
        </div>
        <div className="w-200">
          <div
            className={` text-sm px-2 py-1 rounded ${getEstadoStyle(
              item?.estado?.value
            )}`}
          >
            {item.estado?.label}
          </div>
        </div>
        <div className="p-1 w-24 flex flex-row items-center">
          <AccionMenu>
            {item.estado?.value === ESTADOS_EVALUACION.EN_CURSO && (
              <AccionMenuItem onClick={onEditarConfiguracion}>
                Editar
              </AccionMenuItem>
            )}
            <AccionMenuItem onClick={verInformeEvaluacion}>
              Informe
            </AccionMenuItem>
            <AccionMenuItem onClick={duplicarEvaluacion}>
              Duplicar
            </AccionMenuItem>
            {item.estado?.value === ESTADOS_EVALUACION.FINALIZADO && (
              <AccionMenuItem onClick={reabrirEvaluacion(item.id)}>
                Reabrir
              </AccionMenuItem>
            )}
            <AccionMenuItem onClick={onEliminar}>
              <div className="text-red-400">Eliminar</div>
            </AccionMenuItem>
          </AccionMenu>
        </div>
      </div>
    );
  };
  const onCheckEvaluaciones = (event, item) => {
    dispatch(
      evaluacionActions.checkEvaluacion({
        evaluacion: item,
        checked: event.target.checked,
      })
    );
  };
  const onSelectChange = (v, items = []) => {
    if (v.value === "eliminar") {
      let deseaEliminar = window.confirm(
        "Se eliminaran solo las evaluaciones que no tengan formularios relacionados.\n" +
        "Desea eliminar las Evaluaciones seleccionadas?"
      );
      if (deseaEliminar) {
        dispatch(evaluacionActions.evaluacionBulkDelete());
      }
    } else if (v.value === "cancel") {
      dispatch(evaluacionActions.resetCheckEvaluaciones());
    } else {
      // @ts-ignore
      window.alert(JSON.stringify(v));
    }
  };
  const onCreateEvaluacion = () => {
    navigate("/evaluaciones/configuracion/" + tipoEvaluacion);
  };
  const onChangeSearch = (event) => {
    dispatch(evaluacionActions.setTipoEvaluacionActual(tipoEvaluacion));
    dispatch(
      evaluacionActions.setEvaluacionesFilter({
        ...evaluaciones_filter,
        nombre: event.target.value,
      })
    );
  };

  const onPaginate = (page, pageSize) => {
    dispatch(evaluacionActions.setTipoEvaluacionActual(tipoEvaluacion));
    dispatch(
      evaluacionActions.setEvaluacionesFilter({
        ...evaluaciones_filter,
        pagination: {
          page: page,
          pageSize: pageSize,
        },
      })
    );
  };
  useEffect(() => {
    dispatch(commonActions.setTitle("Evaluaciones"));
    dispatch(
      commonActions.setTitleMenu([
        {
          name: "Desempeño",
          to: "/evaluaciones/1",
        },
        {
          name: "Potencial",
          to: "/evaluaciones/2",
        },
      ])
    );
    dispatch(commonActions.showSidebar(true));
  }, []);

  useEffect(() => {
    dispatch(evaluacionActions.setTipoEvaluacionActual(tipoEvaluacion));
    dispatch(evaluacionActions.getEvaluaciones());
  }, [tipoEvaluacion]);
  //
  return (
    <>
      <div className="md:w-11/12">
        {tipoEvaluacion === "undefined" && (
          <div className="flex justify-center m-8">Seleccione un tipo de evaluación en las pestañas.</div>
        )}
        <div className="mt-8 mb-36">
          {!evaluaciones_loading && tipoEvaluacion !== "undefined" && (
            <TableCustom
              fixedHeigth={true}
              data={evaluaciones || []}
              tableTitle={""}
              tableSecondTitle={"Histórico de evaluaciones"}
              subject={"evaluacion"}
              subjectGender={"F"}
              ItemComponent={EvaluacionListItem}
              addBtnClickHandler={onCreateEvaluacion}
              itemsChecked={evaluaciones_checked}
              onCheckHandler={onCheckEvaluaciones}
              onSelectChange={onSelectChange}
              selectOptions={[
                { value: "eliminar", label: "Eliminar" },
                { value: "cancel", label: "Cancelar" },
              ]}
              searchValue={nombre}
              onChangeSearch={onChangeSearch}
              noBottomButtom={true}
              //
              totalPaginate={evaluaciones_count}
              onPaginate={onPaginate}
            />
          )}
          {!!evaluaciones_loading && tipoEvaluacion !== "undefined" && (
            <div className="mt-28">
              {[1, 2, 3].map((r, i) => (
                <SkeletonLoaderRow key={i}></SkeletonLoaderRow>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default EvaluacionPage;
