import dayjs from "dayjs";

export function contarDiasHabiles(fechaDesde, fechaHasta, diasPermitidos = [0, 1, 2, 3, 4]) {
    if (!fechaDesde || !fechaHasta || !Array.isArray(diasPermitidos) || diasPermitidos.length === 0) {
        return 0;
    }
    let startDate = dayjs(fechaDesde);
    let endDate = dayjs(fechaHasta);
    let businessDaysCount = 0;

    while (startDate.isBefore(endDate) || startDate.isSame(endDate, 'day')) {
        let dayOfWeek = startDate.day(); // 0 = Domingo, 6 = Sábado
        // @ts-ignore
        dayOfWeek = (dayOfWeek === 0) ? 6 : dayOfWeek - 1; // Ajuste para que Lunes = 0, Domingo = 6
        
        if (diasPermitidos.includes(dayOfWeek)) {
            businessDaysCount++;
        }
        startDate = startDate.add(1, 'day');
    }

    return businessDaysCount;
}