import React, { useEffect, useState } from "react";
import { EyeFill } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CollapseCard from "src/modules/common/components/CollapseCard";
import CustomAlert from "src/modules/common/components/CustomAlert";
import { ErrorToast } from "src/modules/common/components/ErrorToast";
import SelectModal from "src/modules/common/components/SelectModal";
import { SuccessToast } from "src/modules/common/components/SuccessToast";
import backButton from "src/modules/common/components/images/back-arrow.png";
import { commonActions } from "src/modules/common/handler/redux";
import { formatNumber } from "src/utils/helpers";
import KpiColaboradorForm from "src/modules/kpi/pages/KpiColaboradorForm";
import { objetivoActions } from "../handlers/redux";
import { regexInputPeso } from "src/modules/common/helpers/helpers";

// TODO: Cambiar todo lo que dice "kpi" por "objetivo". Copie y pegué lo de kpi porque era urgente.

const initialKPI = {
  id: null,
  nombre: "",
  descripcion: "",
  periodo: null,
  metrica: null,
  meta: null,
  peso: 0.0,
  rangos: [],
};

function ObjetivoCargoAsignacionMasivaPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const colaboradores_checked = useSelector(
    (state) => state.objetivo.colaboradores_checked
  );
  const [kpisData, setKpisData] = useState([]);
  const [pesoTotalObjetivos, setPesoTotalObjetivos] = useState(0);
  const [kpiForm, setKpiForm] = useState(initialKPI);
  const [kpiFormIndex, setKpiFormIndex] = useState(null);
  //
  const showKpiMeta = (kpi) => {
    if (kpi.metrica_display === "Selección simple") return "Logro/No logro";
    return kpi.meta
      ? `${Number(formatNumber(kpi.meta)).toLocaleString("de-DE")}`
      : kpi.rangos.length > 0
        ? "Por escala"
        : "Sin definir";
  };

  const showEscala = (kpi) => {
    const { rangos } = kpi;
    return (
      <div className="group flex justify-center items-center border rounded-md p-1">
        <EyeFill></EyeFill>
        <div className="border rounded-md p-2 bg-white z-10 hidden group-hover:block group-hover:absolute bottom-0 left-28">
          <div className="flex gap-2 font-bold text-sm">
            <div className="flex-1">Desde</div>
            <div className="flex-1">Hasta</div>
            <div className="flex-1">Resultado</div>
          </div>
          {rangos.map((r, i) => (
            <div key={`rango-${kpi.id}-${i}`} className="flex gap-2 text-sm">
              <div className="flex-1">
                {r.desde ? r.desde.toLocaleString("de-DE") : r.desde}
              </div>
              <div className="flex-1">
                {r.hasta ? r.hasta.toLocaleString("de-DE") : r.hasta}
              </div>
              <div className="flex-1">
                {r.resultado
                  ? r.resultado.toLocaleString("de-DE")
                  : r.resultado}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };
  const renderKpisColaboradorList = () => {
    const onChangePeso = (index) => (e) => {
      let value = e.target.value;
      // Expresión regular: permite números hasta 3 dígitos enteros y hasta 5 decimales
      const regex = regexInputPeso;
      if (!regex.test(value)) {
        return; // No actualizar el estado si el valor no cumple con la validación
      }
      value = Number(value);
      if (value < 0 || value > 100) {
        return;
      }
      let kpisDataCopy = JSON.parse(JSON.stringify(kpisData));
      kpisDataCopy[index].peso = value;
      setKpisData(kpisDataCopy);
    };
    const onEditar = (kpi, index) => () => {
      setKpiForm(kpi);
      setKpiFormIndex(index);
      dispatch(commonActions.setSelectModal({ open: true }));
    };
    const onDeleteKPI = (id) => () => {
      const newKPIS = kpisData.filter((kpi, kidx) => kidx !== id);
      setKpisData(newKPIS);
    };
    //
    return kpisData.map((kpi, kidx) => (
      <CollapseCard
        key={`kpi-${kidx}-${kpi.id}`}
        titulo={kpi.nombre}
        extraClass="mb-8"
        titleClass={"font-semibold"}
        initCollapse={false}
        extraEndContent={
          true ? (
            <div className="flex justify-end items-center gap-2 text-sm">
              <input
                // disabled={!isEditing}
                type="number"
                value={kpi.peso}
                onChange={onChangePeso(kidx)}
                className="bg-slate-300x border border-slate-400 outline-none rounded-md p-2 w-2/3"
              />
              <div>%</div>
            </div>
          ) : (
            ""
          )
        }
      >
        <div className="flex flex-col gap-2">
          <div>{kpi.descripcion}</div>
          <div className="flex">
            <div className="flex flex-col flex-1">
              <div className="font-semibold">Tiempo</div>
              <div>{kpi.periodo_display}</div>
            </div>
            <div className="flex flex-col flex-1">
              <div className="font-semibold">Tipo de metrica</div>
              <div>{kpi.metrica_display}</div>
            </div>
          </div>
          <div className="flex relative">
            <div className="flex flex-col flex-1">
              <div className="font-semibold">Meta</div>
              <div className="flex gap-2">
                {showKpiMeta(kpi)}
                {showKpiMeta(kpi) === "Por escala" && (
                  <div>{showEscala(kpi)}</div>
                )}
              </div>
            </div>
            {/* <div className="flex flex-col flex-1">
              <div className="font-semibold">Creado por</div>
              {renderCreadoPor(kpi)}
            </div> */}
          </div>
        </div>
        {!!true && (
          <div className="flex mt-4 gap-2">
            <button
              type="button"
              className="rounded bg-sky-500 text-white px-3 py-1 text-sm"
              onClick={onEditar(kpi, kidx)}
            >
              Editar
            </button>
            <button
              type="button"
              className="rounded bg-red-500 text-white px-3 py-1 text-sm"
              onClick={onDeleteKPI(kidx)}
            >
              Eliminar
            </button>
          </div>
        )}
      </CollapseCard>
    ));
  };
  const onAgregarKPI = () => {
    setKpiForm(initialKPI);
    setKpiFormIndex(null);
    dispatch(commonActions.setSelectModal({ open: true }));
  };

  const agregarKpi = (index) => (kpi) => {
    let kpisDataCopy = JSON.parse(JSON.stringify(kpisData));
    //
    if (!!index || index === 0) {
      kpisDataCopy[index] = kpi;
    } else {
      kpisDataCopy.push({ ...kpi });
    }
    setKpisData(kpisDataCopy);
    setKpiForm(initialKPI);
    dispatch(commonActions.setSelectModal({ open: false }));
    SuccessToast({
      message: !!index ? "KPI editado" : "KPI agregado al listado",
    });
  };

  const validateKpis = () => {
    if (kpisData.length < 1) {
      return {
        isValid: false,
        message: "Debe agregar al menos un KPI.",
      };
    }
    if (pesoTotalObjetivos !== 0 && pesoTotalObjetivos !== 100) {
      return {
        isValid: false,
        message: "La suma de los pesos debe ser de 100%.",
      };
    }
    return {
      isValid: true,
      message: "KPIs enviados con éxito!",
    };
  };
  const onAsignarSubmit = () => {
    const validationResponse = validateKpis();
    const { isValid, message } = validationResponse;
    //
    if (isValid) {
      // let kpisCopy = JSON.parse(JSON.stringify(kpisData));
      // kpisCopy = kpisCopy.map((obj) => {
      //   return {
      //     ...obj,
      //     rangos: obj.rangos.filter((rango) => {
      //       return Object.values(rango).every((value) => value !== "");
      //     }),
      //   };
      // });
      // dispatch(kpiActions.asignarKpisMasivo({ kpis: kpisCopy }));
      setShowAlert(true);
    } else {
      ErrorToast({ message: message });
    }
  };
  const [showAlert, setShowAlert] = useState(false);
  const onAceptarAlert = () => {
    let kpisCopy = JSON.parse(JSON.stringify(kpisData));
    kpisCopy = kpisCopy.map((obj) => {
      return {
        ...obj,
        rangos: obj.rangos.filter((rango) => {
          return Object.values(rango).every((value) => value !== "");
        }),
      };
    });
    kpisCopy.forEach((obj) => {
      if (obj.meta === null) obj.meta = 0;
    });
    dispatch(
      objetivoActions.asignarObjetivosCargoMasivo({ objetivos: kpisCopy })
    );
    setShowAlert(false);
  };
  const onCancelarAlert = () => {
    setShowAlert(false);
  };
  //
  useEffect(() => {
    dispatch(commonActions.setTitleMenu([]));
    dispatch(commonActions.setTitle(""));
    dispatch(commonActions.showSidebar(false));
  }, []);

  useEffect(() => {
    const sumaPesos = kpisData.reduce((total, objetivo) => {
      return total + (objetivo.peso || 0);
    }, 0);
    const sumaRedondeada = parseFloat(sumaPesos.toFixed(5));
    setPesoTotalObjetivos(sumaRedondeada);
  }, [kpisData])
  //
  return (
    <div className="">
      <div className="flex justify-between items-center bg-zinc-100 py-2.5 px-7 border-b -mx-8">
        <div className="flex items-center">
          <div
            onClick={() => navigate("/objetivos/cargos")}
            className={
              "h-full  flex items-center pr-2 -mr-2 z-10 cursor-pointer"
            }
          >
            <img className="back" src={backButton} alt="Regresar" />
          </div>
          <div className="font-medium px-4 text-lg">
            Asignar objetivos por cargo
          </div>
        </div>
      </div>
      {/*  */}
      <div className="grid grid-cols-12 justify-center mt-4">
        <div className="col-span-12 md:col-span-8 md:col-start-3">
          <div className="flex flex-col justify-center">
            <div className="flex flex-col gap-2 w-full">
              <h3 className="text-lg font-semibold">
                Colaboradores seleccionados
              </h3>
              <p>
                <span className="font-semibold">Total seleccionados:</span>{" "}
                {colaboradores_checked.length}
              </p>
              <div className="w-full flex gap-2 flex-wrap">
                {colaboradores_checked.length > 0 &&
                  colaboradores_checked.map((colab, cidx) => (
                    <div
                      key={`rango-${colab.id}-${cidx}`}
                      className="p-1 rounded-md bg-slate-100 text-sm"
                    >
                      {`${colab.nombre} ${colab.apellido}`}
                    </div>
                  ))}
              </div>
            </div>
            {/*  */}
            <div className="w-full flex flex-col mt-4">
              <div className="font-bold text-lg mb-4">
                Objetivos por cargo a ser asignados
              </div>
              {kpisData.length > 0 && renderKpisColaboradorList()}
              {kpisData.length < 1 && (
                <div className="w-full flex justify-center border rounded-md py-2 mb-4">
                  No se registran objetivos por cargo
                </div>
              )}
              <div
                className="flex flex-row gap-2 items-center pl-3"
                onClick={onAgregarKPI}
              >
                <div className="w-5 h-5 rounded-full bg-sky-500 text-white flex items-center justify-center text-2xl">
                  +
                </div>
                <div className="text-sky-500 text-sm">{`Agregar ${kpisData.length > 0 ? "otro" : ""
                  } objetivo por cargo`}</div>
              </div>
            </div>
            {/*  */}
            <div className="w-full flex items-center justify-end gap-1">
              <span className="font-semibold">Total pesos: </span>
              <span> {pesoTotalObjetivos}%</span>
            </div>
            {/*  */}
            <div className="w-full my-8">
              <button
                onClick={onAsignarSubmit}
                className="w-full bg-sky-500 text-white rounded-md p-2"
              >
                Asignar objetivos por cargo
              </button>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <CustomAlert
        message="Se borrarán los objetivos por cargo anteriormente registrados de los colaboradores seleccionados.
        ¿Desea continuar?"
        visible={showAlert}
        onAceptarHandler={onAceptarAlert}
        onCancelarHandler={onCancelarAlert}
      ></CustomAlert>
      <SelectModal
        title={`${!!kpiForm.id ? "Editar" : "Agregar"} objetivo`}
        content={
          <div className="max-h-[500px] overflow-auto custom-scrollbar">
            <KpiColaboradorForm
              kpiColaborador={kpiForm}
              onSave={agregarKpi(kpiFormIndex)}
            />
          </div>
        }
      />
    </div>
  );
}

export default ObjetivoCargoAsignacionMasivaPage;
