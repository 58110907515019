import React, { useEffect, useState } from "react";
import { AjusteLayout } from "../components/AjusteLayout";
import SkeletonLoaderRow from "src/modules/common/components/SkeletonLoaderRow";
import ChevronCard from "src/modules/common/components/ChevronCard";
import Modal from "src/modules/common/components/Modal";
import { X } from "react-bootstrap-icons";
import { PrimaryButton } from "src/modules/common/components/PrimaryButton";
import { useDispatch, useSelector } from "react-redux";
import { empresaActions } from "../handlers/redux";
import dayjs from "dayjs";
import WeekdaySelector from "src/modules/common/components/WeekdaySelector";

export function AjusteLaboral() {
  const dispatch = useDispatch();

  const empresa_laboral = useSelector((state) => state.empresa.empresa_laboral);
  const empresa_laboral_loading = useSelector(
    (state) => state.empresa.empresa_laboral_loading
  );

  const {
    salario_minimo_vigente = 0,
    fecha_actualizacion_salario_minimo = null,
  } = empresa_laboral;

  const [openHorasDiariasModal, setOpenHorasDiariasModal] = useState(false);
  const [openSalarioMinimoModal, setOpenSalarioMinimoModal] = useState(false);
  const [horasPorDia, setHorasPorDia] = useState([]);
  const [salarioMinimo, setSalarioMinimo] = useState(0);

  useEffect(() => {
    dispatch(empresaActions.getEmpresaLaboral());
  }, []);

  useEffect(() => {
    if (empresa_laboral.dias_habiles?.length > 0) {
      setHorasPorDia(empresa_laboral.dias_habiles);
    }
    if (empresa_laboral.salario_minimo_vigente) {
      setSalarioMinimo(empresa_laboral.salario_minimo_vigente);
    }
  }, [empresa_laboral]);

  const options = [
    {
      title: "Horas diarias",
      description: "Cantidad de horas trabajadas al día.",
      action: () => setOpenHorasDiariasModal(true),
    },
    {
      title: "Salario mínimo vigente",
      description: `Fecha de actualización: ${fecha_actualizacion_salario_minimo
        ? dayjs(fecha_actualizacion_salario_minimo).format("DD/MM/YYYY")
        : "Sin asignar"
        }`,
      data: `Gs. ${salario_minimo_vigente.toLocaleString("de-DE")}`,
      action: () => setOpenSalarioMinimoModal(true),
    },
  ];
  const RenderSelectOption = ({ title, description, data, action }) => {
    return (
      <div
        className="ml-6 border-b py-4 px-2 hover:cursor-pointer hover:bg-sky-100"
        onClick={action}
      >
        <ChevronCard title={title} description={description} data={data} />
      </div>
    );
  };

  const onSubmitHorasPorDia = () => {
    dispatch(empresaActions.createHorasPorDia(horasPorDia));
    setOpenHorasDiariasModal(false);
  };

  const handleDayToggle = (name, index, value) => {
    let dias_habiles = JSON.parse(JSON.stringify(horasPorDia)) || [];
    if (name === "dia") {
      if (value) {
        // Si value es true y el día no está en dias_habiles, agregarlo con 0 horas
        if (!dias_habiles.some(dia => dia.dia === index)) {
          dias_habiles.push({ dia: index, cantidad_horas: 0 });
        }
      } else {
        // Si value es false, eliminar el día de dias_habiles
        dias_habiles = dias_habiles.filter(dia => dia.dia !== index);
      }
    } else if (name === "cantidad_horas") {
      // Buscar el día y actualizar la cantidad de horas
      const diaIndex = dias_habiles.findIndex(dia => dia.dia === index);
      if (diaIndex !== -1) {
        dias_habiles[diaIndex].cantidad_horas = value;
      }
    }
    setHorasPorDia(dias_habiles);
  }

  const onSubmitSalarioMinimo = () => {
    const param = { salario_minimo_vigente: salarioMinimo };
    dispatch(empresaActions.updateSalarioMinimo(param));
    setOpenSalarioMinimoModal(false);
  };

  const onChangeSalarioMinimo = (e) => {
    const value = e.target.value;
    if (value < 0) return;
    setSalarioMinimo(value);
  };

  return (
    <AjusteLayout>
      <div className="w-full mt-4">
        <div className="flex items-start w-100 border-b py-4 mb-3">
          <h5 className="font-bold text-lg">Configuración laboral</h5>
        </div>
        {!empresa_laboral_loading ? (
          <div className="flex flex-col gap-4">
            {options.map((option, i) => (
              <RenderSelectOption key={`option-${i}`} {...option} />
            ))}
          </div>
        ) : (
          <div>
            <div className="flex flex-col mt-4 w-full pr-14">
              {[1, 2, 3].map((sl, sli) => (
                <SkeletonLoaderRow key={`skeleton-loader-${sli}`} />
              ))}
            </div>
          </div>
        )}
      </div>
      <Modal isOpen={openHorasDiariasModal}>
        <div className="rounded-lg bg-white w-4/12 p-2 md:p-5 flex flex-col gap-5">
          {/* header */}
          <div className="w-full flex justify-between items-center">
            <h2 className="font-semibold text-lg">Horas por día</h2>
            <button
              onClick={() => {
                setOpenHorasDiariasModal(false);
                setHorasPorDia(empresa_laboral.dias_habiles || []);
              }}
            >
              <X size={24} />
            </button>
          </div>
          {/* body */}
          <div className="flex flex-col gap-4">
            <WeekdaySelector
              editable={true}
              onDayToggle={handleDayToggle}
              selectedDays={horasPorDia}
              extraClasses="w-full"
            />
            <div className="flex justify-center">
              <PrimaryButton className="w-2/3 bg-sky-500" onClick={onSubmitHorasPorDia}>Guardar</PrimaryButton>
            </div>
          </div>
        </div>
      </Modal>
      <Modal isOpen={openSalarioMinimoModal}>
        <div className="rounded-lg bg-white w-4/12 p-2 md:p-5 flex flex-col gap-5">
          {/* header */}
          <div className="w-full flex justify-between items-center">
            <h2 className="font-semibold text-lg">Salario mínimo legal</h2>
            <button
              onClick={() => {
                setOpenSalarioMinimoModal(false);
              }}
            >
              <X size={24} />
            </button>
          </div>
          {/* body */}
          {/* body */}
          <div>
            <div className="mb-4">
              <input
                type="number"
                value={salarioMinimo}
                onChange={onChangeSalarioMinimo}
                className="w-1/2 h-10 border border-gray-300 rounded-md p-1 text-center"
              />
            </div>
            <PrimaryButton onClick={onSubmitSalarioMinimo}>
              Guardar
            </PrimaryButton>
          </div>
        </div>
      </Modal>
    </AjusteLayout>
  );
}
