import axios from "axios";

export const DescargarReporteFeedbacks = (token, feedbacksIds, onComplete) => {
    const URL_BASE = process.env.REACT_APP_API_URL || "http://localhost:8000/api/admin";
    const requestURL = URL_BASE + `/feedback/exportar-feedbacks`;
    const headers = {
        "Content-Type": "application/json", // Se pone application/json porque se envía un json en el request
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };

    axios({
        url: requestURL,
        method: "POST",
        data: { feedbacks_ids: feedbacksIds },
        headers: headers,
        responseType: "blob",
    })
        .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `Feedbacks - ${new Date().toLocaleString()}.xlsx`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
            //
            if (!!onComplete) {
                onComplete();
            }
        })
        .catch((e) => {
            console.info("Error al descargar el archivo");
            if (!!onComplete) {
                onComplete();
            }
        });
}