import React from "react";
import PreguntaComponent from "../components/PreguntaComponent";

const EncuestaPreguntas = ({
  encuesta,
  handleAddPregunta,
  handleInputChangePregunta,
  handleRemovePregunta,
  handleAddOpcion,
  handleRemoveOpcion,
}) => {
  return (
    <div className="flex flex-col gap-4 w-full p-4">
      {encuesta.preguntas.map((pregunta, index) => (
        <div key={index}>
          <PreguntaComponent
            index={index + 1}
            texto={pregunta.texto}
            descripcion={pregunta.descripcion}
            tipoRespuesta={pregunta.tipo_respuesta}
            opciones={pregunta.opciones}
            handleAddPregunta={handleAddPregunta}
            handleInputChange={handleInputChangePregunta}
            handleDelete={handleRemovePregunta}
            handleAddOpcion={handleAddOpcion}
            handleDeleteOpcion={handleRemoveOpcion}
          />
        </div>
      ))}
      <span
        className="text-sky-500 cursor-pointer mt-2 text-sm"
        onClick={() => handleAddPregunta(null)}
      >
        Agregar nueva pregunta
      </span>
    </div>
  );
};

export default EncuestaPreguntas;
