import React from "react";
import {
  CheckLg,
  PencilFill,
  TrashFill,
  XLg,
} from "react-bootstrap-icons";

export const CalificacionRow = (
  calificacion,
  idx,
  onEditRow,
  isEditing,
  setIsEditing,
  isEditable = true,
  onDelete,
  disabled = false
) => {
  const onEdit = () => {
    setIsEditing(true);
  };
  const onSave = () => {
    setIsEditing(false);
  };
  const onCancel = () => {
    setIsEditing(false, true);
  };
  const onChange =
    (field) =>
      ({ target }) => {
        let value = target.value;
        let { nombre, puntaje_desde } = calificacion;
        if (field === "nombre") onEditRow(idx, value, puntaje_desde);
        if (field === "puntaje_desde") onEditRow(idx, nombre, value);
      };
  //
  return (
    <div className="w-full justify-between flex border-2x border-red-500x">
      {!!isEditable && (
        <>
          {/* Nombre y acciones */}
          <div className="group h-12 flex justify-between items-center gap-2 borderx border-blue-400x w-1/2 font-bold uppercase py-2">
            {!isEditing && <div>{calificacion.nombre}</div>}
            {!!isEditing && (
              <div>
                <input
                  type="text"
                  disabled={disabled}
                  className="border rounded placeholder-zinc-600 border-zinc-200 w-2/3 px-2 py-1"
                  value={calificacion.nombre || ""}
                  onChange={onChange("nombre")}
                />
              </div>
            )}
            {!disabled && (
              <div className="gap-1 mr-8 hidden group-hover:flex">
                {!isEditing && (
                  <button className="border p-2 rounded-md" onClick={onEdit}>
                    <PencilFill />
                  </button>
                )}
                {!!isEditing && (
                  <>
                    <button
                      className="border p-2 rounded-md bg-sky-500"
                      onClick={onSave}
                    >
                      <CheckLg size={20} color={"white"} />
                    </button>
                    <button
                      className="border p-2 rounded-md"
                      onClick={onCancel}
                    >
                      <XLg size={20} />
                    </button>
                  </>
                )}
              </div>
            )}
          </div>
          {/* Puntajes */}
          <div className="flex flex-1 gap-2 items-center borderx border-blue-500x py-2 w-1/2">
            {/* Puntaje desde */}
            <div className="flex-1">
              {!isEditing && <>{calificacion.puntaje_desde}%</>}
              {!!isEditing && (
                <input
                  type="text"
                  disabled={disabled}
                  maxLength={4}
                  className="border rounded placeholder-zinc-600 border-zinc-200 w-2/3 px-2 py-1"
                  value={calificacion.puntaje_desde}
                  onChange={onChange("puntaje_desde")}
                />
              )}
            </div>
            {/* Puntaje hasta */}
            <div className="flex-1">
              <div className="flex items-center justify-between gap-2 w-1/2">
                <div>{calificacion.puntaje_hasta}%</div>
                {!!isEditing && !!calificacion.isRemovable && (
                  <button className="border p-2 rounded-md" onClick={onDelete}>
                    <TrashFill />
                  </button>
                )}
              </div>
            </div>
          </div>
        </>
      )}
      {/* ReadOnly Row */}
      {!isEditable && (
        <>
          <div className="group h-12 flex justify-between items-center gap-2 borderx border-blue-400x w-1/2 font-bold uppercase py-2">
            <div>{calificacion.nombre}</div>
          </div>
          <div className="flex flex-1 gap-2 items-center borderx border-blue-500x py-2 w-1/2">
            <div className="flex-1">{calificacion.puntaje_desde}%</div>
            <div className="flex-1">{calificacion.puntaje_hasta}%</div>
          </div>
        </>
      )}
    </div>
  );
};
