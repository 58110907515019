import React, { useState } from "react";

const WeekdaySelector = ({ editable = false, onDayToggle = (a, b, c) => { }, selectedDays = [], extraClasses = "" }) => {
    const days = [
        "Lunes", // 0
        "Martes", // 1
        "Miércoles", // 2
        "Jueves", // 3
        "Viernes", // 4
        "Sábado", // 5
        "Domingo", // 6
    ];

    const handleToggle = (index) => (e) => {
        const value = e.target.checked;
        onDayToggle("dia", index, value); // El value es el valor del index 
    };

    const handleHourChange = (index) => (e) => {
        const value = e.target.value;
        onDayToggle("cantidad_horas", index, value);
    };

    const getDay = (index) => {
        return selectedDays.find((item) => item.dia === index);
    }

    return (
        <div className={`p-2 border rounded-lg ${extraClasses}`}>
            {days.map((day, index) => (
                <div key={index} className="flex items-center justify-between py-1">
                    <label className="flex items-center gap-2 w-auto mx-2">
                        <input
                            type="checkbox"
                            checked={!!getDay(index)?.dia || getDay(index)?.dia === 0}
                            onChange={handleToggle(index)}
                            className="w-4 h-4"
                            disabled={!editable}
                        />
                        {day}
                    </label>
                    {(!!getDay(index)?.dia || getDay(index)?.dia === 0) && (
                        <div className="flex items-center gap-2 w-full mx-2">
                            <div className="flex-grow border-b border-dotted border-gray-400"></div>
                            <input
                                type="number"
                                min="0"
                                max="24"
                                className="w-12 p-1 border rounded text-center text-sm"
                                value={getDay(index)?.cantidad_horas}
                                onChange={handleHourChange(index)}
                                disabled={!editable}
                            />
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default WeekdaySelector;
