import dayjs from 'dayjs';
import React from 'react'
import userDefault from "src/modules/common/components/images/user_default.png";

export const vacacionesDatatableConfig = (setOrdering) => ({
    headers: [
        {
            title: "Colaboradores",
            onOrder: setOrdering("nombre"),
            contentRenderer: (item) => (
                <div
                    className="flex items-center cursor-pointer gap-2"
                >
                    <img
                        src={item.foto_empleado || userDefault}
                        className="h-12 w-12 rounded object-cover"
                    />
                    <div className="flex flex-col flex-1">
                        <p className="font-semibold whitespace-normal">
                            {item.nombre} {item.apellido}
                        </p>
                        <p className="text-sm whitespace-normal">
                            {item.cargo ? item.cargo.nombre : "Sin cargo"}
                        </p>
                    </div>
                </div>
            ),
        },
        {
            title: "Antigüedad",
            onOrder: setOrdering("fecha_ingreso"),
            accessor: "antiguedad",
        },
        {
            title: "Causadas",
            onOrder: setOrdering("dias_vacaciones_causados"),
            accessor: "dias_vacaciones_causados",
        },
        {
            title: "Utilizadas",
            onOrder: setOrdering("dias_vacaciones_utilizados"),
            accessor: "dias_vacaciones_utilizados",
        },
        {
            title: "Disponibles",
            onOrder: setOrdering("dias_vacaciones_disponibles"),
            accessor: "dias_vacaciones_disponibles",
        },
    ],
    rowActions: [],
});

const formatearFecha = (fecha, con_hora = false) => {
    if (con_hora) return dayjs(fecha).format('DD MMM. YYYY HH:mm');
    return dayjs(fecha).format('DD MMM. YYYY');
};

export const registrosVacacionesDatatableConfig = (onEdit, onDelete) => {
    return {
        headers: [
            {
                title: "Desde - Hasta",
                contentRenderer: (item) => (
                    <div>
                        {item.fecha_desde ? formatearFecha(item.fecha_desde) : "Sin asignar"}
                        {" - "}
                        {item.fecha_hasta ? formatearFecha(item.fecha_hasta) : "Sin asignar"}
                    </div>
                )
            },
            {
                title: "Días tomados",
                accessor: "dias_tomados",
            },
            {
                title: "Responsable",
                contentRenderer: (item) => (
                    <div
                        className="flex items-center gap-2"
                    >
                        <img
                            src={item.responsable ? (item.responsable.foto_empleado || userDefault) : userDefault}
                            className="h-12 w-12 rounded object-cover"
                        />
                        <div className="flex flex-col flex-1">
                            <p className="font-semibold whitespace-normal">
                                {item.responsable ? item.responsable.nombre : item.responsable_nombre}
                                {" "}
                                {item.responsable ? item.responsable.apellido : item.responsable_apellido}
                            </p>
                            <p className="text-sm whitespace-normal">
                                {item.responsable ? (item.responsable.cargo ? item.responsable.cargo.nombre : "Sin cargo") : "Sin asignar"}
                            </p>
                        </div>
                    </div>
                ),
            },
            {
                title: "Actualizado",
                contentRenderer: (item) => (
                    <div>
                        {item.fecha_actualizacion ? formatearFecha(item.fecha_actualizacion, true) : "Sin asignar"}
                    </div>
                )
            },
        ],
        rowActions: [
            {
                label: "Editar",
                action: (item) => {
                    onEdit(item);
                }
            },
            {
                label: "Eliminar",
                className: "text-red-500 hover:text-red-600",
                action: (item) => {
                    onDelete(item);
                }
            }
        ],
    }
};