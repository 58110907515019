import SearchInput from "src/modules/remuneracion/components/SearchInput";
import { FeedbackLayout } from "../components/FeedbackLayout";
import MostrarPaginas from "src/modules/common/components/MostrarPaginas";
import ReactSelect from "react-select";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "src/modules/common/components/DataTable";
import userDefault from "src/modules/common/components/images/user_default.png";
import { ChevronRight } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { feedbackActions } from "../handlers/redux";
import dayjs from "dayjs";
import React from "react";
import { getToken } from "src/utils/helpers";
import { DescargarReporteFeedbacks } from "../helpers/helpers";
import { ErrorToast } from "src/modules/common/components/ErrorToast";

export function FeedbackGeneralPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const feedbacks = useSelector((state) => state.feedback.feedbacks);
  const feedbacks_loading = useSelector(
    (state) => state.feedback.feedbacks_loading
  );
  const feedbacks_count = useSelector(
    (state) => state.feedback.feedbacks_count
  );
  const feedbacks_filter = useSelector(
    (state) => state.feedback.feedbacks_filter
  );
  const feedbacks_checked = useSelector(
    (state) => state.feedback.feedbacks_checked
  );

  const { nombre = "" } = feedbacks_filter;
  const [outerPageSize, setOuterPageSize] = useState(50);
  const [descargarReporteLoading, setDescargarReporteLoading] = useState(false);

  useEffect(() => {
    dispatch(feedbackActions.getFeedbacks());
  }, []);

  const onSearchFeedback = (e) => {
    let searchText = e.target.value;
    dispatch(
      feedbackActions.setFeedbacksFilter({
        ...feedbacks_filter,
        nombre: searchText,
      })
    );
  };

  const onChangePageSize = (e) => {
    let value = e.target.value;
    dispatch(
      feedbackActions.setFeedbacksFilter({
        ...feedbacks_filter,
        pagination: {
          pageSize: parseInt(value),
          page: 1,
        },
      })
    );
    setOuterPageSize(parseInt(value));
  };

  const actionOptions = [
    {
      value: "export-all",
      label: "Exportar todo",
    },
    {
      value: "export-selected",
      label: "Exportar seleccionados",
    },
    {
      value: "delete",
      label: "Eliminar",
    },
  ];

  const onAction = (v) => {
    if (v.value === "export-all") {
      const feedbacksIds = feedbacks.map((feedback) => feedback.id);
      onDescargarReporteFeedbacks(feedbacksIds);
    }
    else if (v.value === "export-selected") {
      const feedbacksIds = feedbacks_checked.map((feedback) => feedback.id);
      if (feedbacksIds.length === 0) {
        ErrorToast({ message: "Debes seleccionar al menos un feedback" });
        return;
      }
      onDescargarReporteFeedbacks(feedbacksIds);
    }
    else if (v.value === "delete") {
      const deseaEliminar = window.confirm(
        "¿Está seguro de eliminar los feedbacks seleccionados?"
      );
      if (!!deseaEliminar) {
        const ids = feedbacks_checked.map((feedback) => feedback.id);
        console.log(ids);
        dispatch(feedbackActions.bulkDeleteFeedback({ ids: ids }));
      }
      return;
    }
  };

  const goToNuevoFeedback = () => {
    dispatch(feedbackActions.setFeedback({}));
    navigate("/feedback/configuracion/");
  };

  const goToDetail = (feedbackId) => {
    navigate("/feedback/inicio/" + feedbackId + "/");
  };

  const onEditFeedback = (feedbackId) => {
    navigate("/feedback/configuracion/" + feedbackId);
  };

  const onDeleteFeedback = (feedbackId) => {
    const deseaEliminar = window.confirm(
      "¿Está seguro de eliminar el feedback seleccionado?"
    );
    if (!!deseaEliminar) {
      const param = { ids: [feedbackId] };
      dispatch(feedbackActions.bulkDeleteFeedback(param));
    }
  };

  const cantidadJefesFotos = 5;

  const datatableConfig = {
    headers: [
      {
        title: "Histórico de Feedback",
        contentClass: "font-bold px-2 py-4",
        // onOrder: setOrdering("nombre"),
        // accessor: "nombre",
        contentRenderer: (item) => (
          <div className="flex flex-col gap-1">
            <span className="font-semibold text-base">{item.nombre}</span>
            <span className="text-base">
              Inicio:{" "}
              {dayjs(item.fecha_inicio?.slice(0, 10)).format("DD/MM/YYYY")}
            </span>
            <span className="text-base">
              Fin: {dayjs(item.fecha_fin?.slice(0, 10)).format("DD/MM/YYYY")}
            </span>
          </div>
        ),
      },
      {
        title: "Jefes directos",
        contentClass: "font-bold px-2 py-4",
        // onOrder: setOrdering(""),
        // accessor: "nombre",
        contentRenderer: (item) => (
          <div className="flex flex-col">
            <div className="flex flex-row align-middle gap-1">
              {item.jefes_directos
                .slice(0, cantidadJefesFotos)
                .map((jefe, index) => (
                  <div
                    key={index}
                    className="rounded-lg overflow-hidden w-8 h-8 "
                  >
                    <img
                      src={
                        jefe.foto_empleado
                          ? jefe.foto_empleado
                          : userDefault
                      }
                      alt="Perfil"
                      title={`${jefe.nombre} ${jefe.apellido
                        ? jefe.apellido
                        : ""
                        }`}
                      className="w-full h-full object-cover"
                    />
                  </div>
                ))}
              {/*Indicador de cuantos jefes más hay, mostrado solo si hay más de la cantidad que indique cantidadJefesFotos*/}
              {item.jefes_directos.length > 0 &&
                item.jefes_directos.length > cantidadJefesFotos && (
                  <div
                    className="flex justify-center items-center w-8 h-8 rounded-lg bg-gray-200 text-black cursor-default"
                    title={`${item.jefes_directos.length - cantidadJefesFotos
                      } ${item.jefes_directos.length - cantidadJefesFotos === 1
                        ? "jefe directo más"
                        : "jefes directos más"
                      }`}
                  >
                    +{item.jefes_directos.length - cantidadJefesFotos}
                  </div>
                )}
              {item.jefes_directos.length === 0 && (
                <div>Sin Jefes Directos</div>
              )}
            </div>
          </div>
        ),
      },
      {
        title: "Progreso",
        contentClass: "font-bold px-2 py-4",
        // onOrder: setOrdering(""),
        // accessor: "nombre",
        contentRenderer: (item) => (
          <div className="flex flex-col">
            <span className="">{item.porcentaje_avance} % completado</span>
          </div>
        ),
      },
      {
        title: "",
        contentClass: "font-bold px-2 py-4",
        // onOrder: setOrdering(""),
        // accessor: "nombre",
        contentRenderer: (item) => (
          <div className="cursor-pointer">
            <ChevronRight
              color="#0ea4e9"
              onClick={() => goToDetail(item.id)}
            ></ChevronRight>
          </div>
        ),
      },
    ],
    rowActions: [
      {
        label: "Editar",
        action: (item) => {
          onEditFeedback(item.id);
        },
      },
      {
        label: "Eliminar",
        action: (item) => {
          onDeleteFeedback(item.id);
        },
      },
    ],
  };

  const onPaginate = (page, pageSize) => {
    dispatch(
      feedbackActions.setFeedbacksFilter({
        ...feedbacks_filter,
        pagination: {
          page: page,
          pageSize: pageSize,
        },
      })
    );
  };

  const onCheckAll = () => {
    let checkedData = JSON.parse(JSON.stringify(feedbacks));
    if (feedbacks_checked.length > 0) {
      checkedData = [];
    }
    dispatch(feedbackActions.setCheckFeedbacks(checkedData));
  };

  const onCheckFeedback = (item) => (event) => {
    console.log(item);
    dispatch(
      feedbackActions.checkFeedbacks({
        feedback: item,
        checked: event.target.checked,
      })
    );
  };

  const onFinishDownloadReportLoading = () => {
    setDescargarReporteLoading(false);
  };

  const onDescargarReporteFeedbacks = (feedbacksIds) => {
    const token = getToken();
    setDescargarReporteLoading(true);
    DescargarReporteFeedbacks(token, feedbacksIds, onFinishDownloadReportLoading);
  }

  return (
    <FeedbackLayout>
      <div className="px-4 py-6 w-full md:w-11/12">
        <div className="flex items-center gap-2 mt-4">
          <SearchInput
            containerClass={`w-1/2`}
            placeHolder={"Buscar por nombre..."}
            value={nombre}
            onChange={onSearchFeedback}
          />
          {!descargarReporteLoading && (
            <ReactSelect
              className="w-52 h-10 inline-grid text-sm"
              value={{
                value: "",
                label: `(${feedbacks_checked.length}) seleccionados`,
              }}
              options={actionOptions}
              onChange={onAction}
            ></ReactSelect>
          )}
          {!!descargarReporteLoading && (
            <button
              disabled={true}
              className="flex items-center gap-2 bg-sky-400 text-white px-3 py-2 rounded animated-pulse"
            >
              <span className="font-semibold text-sm">Descargando...</span>
            </button>
          )}
          <MostrarPaginas
            containerClass={"w-1/2x"}
            onChangePageSize={onChangePageSize}
            defaultOption={50}
          />
          <div>
            <button
              onClick={goToNuevoFeedback}
              className="rounded-md bg-sky-500 text-white p-3"
            >
              Nuevo Feedback
            </button>
          </div>
        </div>
        <div></div>
      </div>
      <DataTable
        containerClass={"my-2"}
        config={datatableConfig}
        data={feedbacks}
        loading={feedbacks_loading}
        noHeaders={false}
        //
        totalRecords={feedbacks_count}
        onPaginate={onPaginate}
        outerPageSize={outerPageSize}
        //
        checkable={true}
        onCheckAll={onCheckAll}
        checkedItems={feedbacks_checked}
        onItemCheck={onCheckFeedback}
      />
    </FeedbackLayout>
  );
}
