import axios from "axios";
const URL_BASE =
    process.env.REACT_APP_API_URL || "http://localhost:8000/api/admin";

const vacacionApis = (token) => {
    const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
    };

    return {
        vacacionesList: async (params) => {
            const { searchField = "", pagination = {}, ordering = "nombre", area, sucursal, departamento } = params;
            const { page = 1, pageSize = 50 } = pagination;
            let url = `${URL_BASE}/vacacion/empleados-vacaciones?search=${searchField}&page=${page}&page_size=${pageSize}&ordering=${ordering}`;
            if (area) url = url + `&area=${area}`;
            if (departamento) url = url + `&departamento=${departamento}`;
            if (sucursal) url = url + `&sucursal=${sucursal}`;
            const response = await axios.get(url, { headers, });
            return response.data;
        },
        registrosVacacionesDetail: async (colaboradorId) => {
            const url = URL_BASE + `/vacacion/empleados-vacaciones/${colaboradorId}/detail`;
            const response = await axios.get(url, {
                headers,
            });
            return response.data;
        },
        createRegistroVacacion: async (params) => {
            const url = URL_BASE + `/vacacion/registro-vacacion/create/`;
            const response = await axios.post(url, params, {
                headers,
            });
            return response.data;
        },
        updateRegistroVacacion: async (params) => {
            const { registroId, request } = params;
            const url = URL_BASE + `/vacacion/registro-vacacion/${registroId}/update/`;
            const response = await axios.put(url, request, {
                headers,
            });
            return response.data;
        },
        deleteRegistroVacacion: async (registroId) => {
            const url = URL_BASE + `/vacacion/registro-vacacion/${registroId}/delete/`;
            const response = await axios.delete(url, {
                headers,
            });
            return response.data;
        },
        //
        updateColaboradorVacaciones: async (params) => {
            const { colaboradorId, request } = params;
            const url = URL_BASE + `/vacacion/empleados-vacaciones/${colaboradorId}/update/`;
            const response = await axios.put(url, request, {
                headers,
            });
            return response.data;
        },
    }
}

export default vacacionApis;