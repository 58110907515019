import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowLeft } from "react-bootstrap-icons";
import { commonActions } from "src/modules/common/handler/redux";

const EncuestaVisualizacionPage = ({ encuesta }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { encuestaId } = useParams();

  // const encuesta = useSelector((state) => state.encuesta.encuesta_previsualizar);

  const [showDescripcion, setShowDescripcion] = useState(false);

  useEffect(() => {
    dispatch(commonActions.setTitleMenu([]));
  }, []);

  return (
    <>
      <div className="container mx-auto p-4 w-2/3">
        <h1 className="text-xl font-bold">{encuesta.nombre}</h1>
        <span
          className="text-sm text-gray-500 cursor-pointer hover:text-gray-700 font-bold"
          onClick={() => setShowDescripcion(!showDescripcion)}
        >
          {showDescripcion
            ? "Ocultar descripción"
            : "Mostrar descripción"}
        </span>
        {showDescripcion && (
          <p className="flex whitespace-pre-wrap m-2 text-sm">{encuesta.descripcion || "Sin descripción"}</p>
        )}
        <div className="border-b mb-8"></div>

        {encuesta.preguntas.map((pregunta, index) => (
          <div
            key={pregunta.id}
            className="border rounded-2xl p-4 mb-4 shadow-md bg-white"
          >
            <h2 className="text-sm font-semibold mb-2">
              Pregunta {index + 1}
            </h2>
            <p className="mb-2">{pregunta.texto}</p>
            <p className="text-sm mb-2 px-2 flex whitespace-pre-wrap text-gray-700">{pregunta.descripcion}</p>
            {(pregunta.tipo_respuesta === "SIMPLE" ||
              pregunta.tipo_respuesta === "MULTIPLE") && (
                <div className="flex flex-col space-y-2">
                  <label className="text-gray-700 font-semibold text-sm">
                    {pregunta.tipo_respuesta === "MULTIPLE"
                      ? "Selecciona las opciones de respuesta"
                      : "Selecciona una opcion de respuesta"}
                  </label>
                  {pregunta.opciones.map((opcion) => (
                    <div key={opcion.id} className="flex items-center space-x-2">
                      <input
                        type={
                          pregunta.tipo_respuesta === "MULTIPLE"
                            ? "checkbox"
                            : "radio"
                        }
                        id={`opcion-${opcion.id}`}
                        name={`pregunta-${pregunta.id}`}
                        className="w-4 h-4"
                      />
                      <label
                        htmlFor={`opcion-${opcion.id}`}
                        className="text-gray-700"
                      >
                        {opcion.texto}
                      </label>
                    </div>
                  ))}
                </div>
              )}

            {pregunta.tipo_respuesta === "TEXTO" && (
              <div className="flex flex-col space-y-2 w-full">
                <textarea
                  name="descripcion"
                  placeholder="Escriba aquí..."
                  className="border rounded border-zinc-200 px-2 py-3 w-full"
                />
              </div>
            )}

            {pregunta.tipo_respuesta === "FECHA" && (
              <div className="flex flex-col space-y-2 w-1/2">
                <label className="text-gray-700 font-semibold">
                  Selecciona una fecha
                </label>
                <input
                  type="datetime-local"
                  name={`pregunta-${pregunta.id}`}
                  className="border rounded px-2 py-2 w-full"
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default EncuestaVisualizacionPage;
