import EncuestaConfiguracionPage from "../pages/EncuestaConfiguracionPage";
import EncuestaDetailPage from "../pages/EncuestaDetailPage";
import EncuestaReporteColaboradoresSinResponder from "../pages/EncuestaReporteColaboradoresSinResponder";
import EncuestaReporteGeneralPage from "../pages/EncuestaReporteGeneralPage";
import EncuestaReportePage from "../pages/EncuestaReportePage";
import EncuestaReporteResumenPage from "../pages/EncuestaReporteResumenPage";
import EncuestaResumenColaboradorPage from "../pages/EncuestaResumenColaboradorPage";
import EncuestasPage from "../pages/EncuestasPage";
import EncuestaVisualizacionPage from "../pages/EncuestaVisualizacionPage";

export const encuestaRoutes = [
  {
    path: "/encuesta/",
    // @ts-ignore
    element: <EncuestasPage />,
  },
  {
    path: "/encuesta/:idEncuesta/",
    // @ts-ignore
    element: <EncuestaDetailPage />,
  },
  {
    path: "/encuesta/:idEncuesta/reporte",
    // @ts-ignore
    element: <EncuestaReportePage />,
  },
  {
    path: "/encuesta/configuracion/:pantallaId",
    // @ts-ignore
    element: <EncuestaConfiguracionPage />,
  },
  {
    path: "/encuesta/:encuestaId/configuracion/:pantallaId", // Se hace con pantallaId para que al cambiar de pantalla en configuración, muestre desde la parte arriba de la pantalla (solo al cambiar el path se hace automáticamente)
    // @ts-ignore
    element: <EncuestaConfiguracionPage />,
  },
  {
    path: "/encuesta/:encuestaId/previsualizar",
    element: <EncuestaVisualizacionPage />,
  },
  {
    path: "/encuesta/:encuestaId/reporte/general",
    element: <EncuestaReporteGeneralPage />,
  },
  {
    path: "/encuesta/:encuestaId/reporte/resumen",
    element: <EncuestaReporteResumenPage />,
  },
  {
    path: "/encuesta/:encuestaId/reporte/sin_respuesta",
    element: <EncuestaReporteColaboradoresSinResponder />,
  },
  {
    path: "/encuesta/:encuestaId/reporte/resumen/colaborador/:colaboradorId",
    element: <EncuestaResumenColaboradorPage />,
  },
];
