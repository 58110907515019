import { createSlice } from "@reduxjs/toolkit";

const colaboradorLaboralSlice = createSlice({
  name: "ColaboradorLaboral",
  initialState: {
    // laboral
    colaboradores_libres: { results: [] },
    colaboradores_libres_loading: false,
    colaboradores_libres_checked: [],
    colaboradores_libres_filter: {},
    colaboradores_libres_select_modal: { open: false },
    //
    colaboradores_laboral_checked: [],
    colaboradores_laboral_loading: false,
    //
    colaborador_laboral: {},
    colaborador_laboral_loading: false,
    colaborador_laboral_update_loading: false,
    //
    hasChanged: false,
    esModoVista: true,
  },
  reducers: {
    setEsModoVista: (state, { payload = [] }) => {
      state.esModoVista = payload;
    },
    setHasChanged: (state, { payload = [] }) => {
      state.hasChanged = payload;
    },
    // list libres
    colaboradoresLibres: (state, { payload }) => {
      !!process.env.REACT_APP_DEBUG && console.info("colaboradores list");
      state.colaboradores_libres_loading = true;
    },
    setColaboradoresLibres: (state, { payload }) => {
      state.colaboradores_libres = payload.colaboradores_libres;
      state.colaboradores_libres_loading = false;
    },
    setCheckColaboradoresLibres: (state, { payload = [] }) => {
      state.colaboradores_libres_checked = payload;
      state.colaboradores_libres_loading = false;
    },
    resetCheckColaboradoresLibres: (state) => {
      state.colaboradores_libres_checked = [];
      state.colaboradores_libres_loading = false;
    },
    checkColaboradorLibre: (
      state,
      { payload: { colaborador, checked = true } }
    ) => {
      state.colaboradores_libres_checked =
        state.colaboradores_libres_checked.filter((id) => id != colaborador.id);
      if (!!checked) {
        state.colaboradores_libres_checked.push(colaborador.id);
      }
      state.colaboradores_libres_loading = false;
    },
    //
    setCheckColaboradoresLaborales: (state, { payload = [] }) => {
      state.colaboradores_laboral_checked = payload;
      state.colaboradores_laboral_loading = false;
    },
    resetCheckColaboradoresLaborales: (state) => {
      state.colaboradores_laboral_checked = [];
      state.colaboradores_laboral_loading = false;
    },
    checkColaboradorLaboral: (
      state,
      { payload: { colaborador, checked = true } }
    ) => {
      state.colaboradores_laboral_checked =
        state.colaboradores_laboral_checked.filter(
          (id) => id !== colaborador.id
        );
      if (!!checked) {
        state.colaboradores_laboral_checked.push(colaborador.id);
      }
      state.colaboradores_laboral_loading = false;
    },
    //
    setColaboradoresLibresFilter: (state, { payload = {} }) => {
      state.colaboradores_libres_filter = payload;
    },
    // Modal
    setColaboradoresLibresSelectModal: (state, { payload = {} }) => {
      !!process.env.REACT_APP_DEBUG && console.log({ payload });
      state.colaboradores_libres_select_modal = payload;
    },

    // LABORAL
    // get
    colaboradorLaboralGet: (state, { payload = {} }) => {
      state.colaborador_laboral = {};
      state.colaborador_laboral_loading = true;
    },
    colaboradorLaboralSet: (state, { payload = {} }) => {
      state.colaborador_laboral = payload;
      state.colaborador_laboral_loading = false;
    },
    // update
    colaboradorLaboralUpdate: (state, { payload = {} }) => {
      state.colaborador_laboral_update_loading = true;
    },
    colaboradorLaboralUpdateSuccess: (state) => {
      state.colaborador_laboral_update_loading = false;
      state.esModoVista = true;
      state.hasChanged = false;
    },
  },
});

export const colaboradorLaboralActions = colaboradorLaboralSlice.actions;
export const colaboradorLaboralReducer = colaboradorLaboralSlice.reducer;
