import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { commonActions } from 'src/modules/common/handler/redux';

const VacacionesPageLayout = ({ children }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(commonActions.setTitle("Vacaciones"));
        dispatch(commonActions.showSidebar(true));
    }, [])
    return <div className="flex flex-col my-8">{children}</div>
};

export default VacacionesPageLayout