import { useEffect, useState } from "react";
import { Search } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import DataTable from "src/modules/common/components/DataTable";
import LoadingSpinButton from "src/modules/common/components/LoadingSpinButton";
import SelectModal from "src/modules/common/components/SelectModal";
import { commonActions } from "src/modules/common/handler/redux";
import { CategoriaRow } from "../components/CategoriaRow";
import RemuneracionConfiguracionLayout from "../components/RemuneracionConfiguracionLayout";
import { remuneracionActions } from "../handlers/redux";
import { RutasRemuneracion } from "../handlers/routes";

const CargosTable = ({ categoria /*, datatableConfig*/ }) => {
  const categorias_filter_aux = useSelector(
    (state) => state.remuneracion.categorias_filter
  );
  const { valoraciones = [] } = categoria;
  const [valoracionesLocal, setValoracionesLocal] = useState(valoraciones);
  const setOrdering =
    (field) =>
    (order = "") => {
      console.log("Ordenar por: " + field + " | " + order);
      // se ordena desde el front porque la consulta al back es una y se divide para distintas grillas
      let valoracionesLocalAux = [...valoracionesLocal];
      if (field === "puntaje") {
        if (order === "asc") {
          valoracionesLocalAux.sort((a, b) => a.puntaje - b.puntaje);
        } else if (order === "desc") {
          valoracionesLocalAux.sort((a, b) => b.puntaje - a.puntaje);
        }
      } else if (field === "cargo.nombre") {
        if (order === "asc") {
          valoracionesLocalAux.sort((a, b) =>
            a.cargo.nombre.localeCompare(b.cargo.nombre)
          );
        } else if (order === "desc") {
          valoracionesLocalAux.sort((a, b) =>
            b.cargo.nombre.localeCompare(a.cargo.nombre)
          );
        }
      }

      setValoracionesLocal(valoracionesLocalAux);
    };
  const datatableConfig = {
    headers: [
      {
        title: "Lista de cargos",
        contentClass: "font-bold",
        accessor: "cargo.nombre",
        onOrder: setOrdering("cargo.nombre"),
      },
      {
        title: "Puntaje",
        accessor: "puntaje",
        onOrder: setOrdering("puntaje"),
      },
    ],
    rowActions: [],
  };
  const onSearch = (e) => {
    let value = e.target?.value;
    value = value.toUpperCase();
    let valoracionesCopy = [...valoraciones];
    //
    valoracionesCopy = valoracionesCopy.filter((val, vidx) =>
      val.cargo.nombre.toUpperCase().includes(value)
    );
    //
    setValoracionesLocal(valoracionesCopy);
  };
  useEffect(() => {
    setValoracionesLocal(valoraciones);
  }, [categoria]);
  //
  return (
    <div className="">
      <h3 className="text-text-uppercase text-sky-500 font-semibold text-2xl mb-4">
        {categoria.nombre}
      </h3>
      <div className="w-full md:w-2/3 flex bg-slate-100 items-center rounded-md p-2">
        <input
          type="text"
          placeholder="Buscar por nombre..."
          className="w-full bg-slate-100 outline-none"
          onChange={onSearch}
        />
        <Search />
      </div>
      <DataTable
        containerClass={"w-full"}
        rowClass={"hover:bg-slate-200 rounded-row"}
        data={valoracionesLocal}
        loading={false}
        config={datatableConfig}
        // pagination
        totalRecords={valoracionesLocal.length}
        // onPaginate={onPaginate}
        // outerPageSize={PageSize}
        //
        checkable={false}
      />
    </div>
  );
};

function RemuneracionCategorizacion() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { idEscala = null } = params;
  const categorias = useSelector((state) => state.remuneracion.categorias);
  const categorias_filter = useSelector(
    (state) => state.remuneracion.categorias_filter
  );
  const categorias_loading = useSelector(
    (state) => state.remuneracion.categorias_loading
  );
  let categoriasTemp = [
    { id: 1, nombre: "Gerentes", puntaje_desde: 76, puntaje_hasta: 100 },
    { id: 2, nombre: "Jefes", puntaje_desde: 51, puntaje_hasta: 75.99 },
    { id: 3, nombre: "Coordinadores", puntaje_desde: 21, puntaje_hasta: 50.99 },
    { id: 4, nombre: "Auxiliares", puntaje_desde: 1, puntaje_hasta: 20.99 },
  ];
  const [categoriasLocal, setCategoriasLocal] = useState(
    categoriasTemp.map((cat, ctidx) => ({ ...cat, isEditing: false }))
  );
  const getValoracionesTemp = (valoraciones = [], categoria = {}) => {
    const { puntaje_desde, puntaje_hasta } = categoria;
    let valoracionesFilter = valoraciones.filter(
      (valo, vidx) =>
        valo.puntaje >= puntaje_desde && valo.puntaje <= puntaje_hasta
    );
    return valoracionesFilter;
  };
  const [categoriasTablas, setCategoriasTablas] = useState([]);
  //
  const onClickBack = () => {
    let deseaRetroceder = true;
    if (!!hasChanged) {
      deseaRetroceder = window.confirm(
        "Desea volver atrás sin guardar los cambios?"
      );
    }
    if (!!deseaRetroceder) {
      navigate(RutasRemuneracion.ESCALA_VALORACION(idEscala));
    }
  };
  /*
  const datatableConfig = {
    headers: [
      {
        title: "Lista de cargos",
        contentClass: "font-bold",
        accessor: "cargo.nombre",
      },
      {
        title: "Puntaje",
        accessor: "puntaje",
      },
    ],
    rowActions: [],
  };
  */
  const openCategoriasModal = () => {
    dispatch(commonActions.setSelectModal({ open: true }));
  };
  const onEditRow = (idx, nombre, puntaje_desde) => {
    let categoriasCopy = [...categoriasLocal];
    let ultimoCaracter = puntaje_desde[puntaje_desde.length - 1];
    if (ultimoCaracter === ",") puntaje_desde = puntaje_desde.replace(",", ".");
    //
    if (categoriasCopy[idx]) {
      categoriasCopy[idx] = {
        ...categoriasCopy[idx],
        nombre: nombre,
        puntaje_desde: puntaje_desde,
      };
      //
      let actual = categoriasCopy[idx];
      let anterior = categoriasCopy[idx + 1];
      if (anterior) {
        let anteriorPuntajeDesde = anterior.puntaje_desde;
        anteriorPuntajeDesde = String(anteriorPuntajeDesde).replace(",", ".");
        anteriorPuntajeDesde = parseFloat(anteriorPuntajeDesde).toFixed(2);
        if (anterior && anteriorPuntajeDesde > 0.0) {
          let puntajeDesde = actual.puntaje_desde.toString();
          if (puntajeDesde.indexOf(",") !== puntajeDesde.length - 1) {
            puntajeDesde = puntajeDesde.replace(",", ".");
            puntajeDesde = parseFloat(puntajeDesde).toFixed(2);
          }
          categoriasCopy[idx + 1] = {
            ...anterior,
            // puntaje_hasta: actual.puntaje_desde - 0.1,
            puntaje_hasta: (puntajeDesde - 0.01).toFixed(2),
          };
        }
      }
    }
    //
    setCategoriasLocal(categoriasCopy);
    setHasChanged(true);
  };
  const onDeleteCategoria = (idx) => () => {
    if (categoriasLocal.length <= 3) return;
    //
    let categoriasCopy = categoriasLocal.filter((c, i) => i !== idx);
    // Se actualiza el valor Hasta del que estaba siguiente del que se elimina
    if (categoriasCopy[idx - 1] && categoriasCopy[idx + 1]) {
      let siguiente = categoriasCopy[idx - 1];
      let anterior = categoriasCopy[idx]; // El que estaba antes del eliminado ahora toma la posicion de idx
      if (anterior) {
        let anteriorPuntajeDesde = anterior.puntaje_desde;
        anteriorPuntajeDesde = String(anteriorPuntajeDesde).replace(",", ".");
        anteriorPuntajeDesde = parseFloat(anteriorPuntajeDesde).toFixed(2);
        if (anterior && anteriorPuntajeDesde > 0.0) {
          let puntajeDesde = siguiente.puntaje_desde.toString();
          if (puntajeDesde.indexOf(",") !== puntajeDesde.length - 1) {
            puntajeDesde = puntajeDesde.replace(",", ".");
            puntajeDesde = parseFloat(puntajeDesde).toFixed(2);
          }
          categoriasCopy[idx] = {
            ...anterior,
            puntaje_hasta: (puntajeDesde - 0.01).toFixed(2),
          };
        }
      }
    }
    //
    setCategoriasLocal(categoriasCopy);
    setHasChanged(true);
  };
  /* Maneja el valor del campo isEditing para mostrar botones */
  const onChangeEditing =
    (idx) =>
    (isEditing = false, isCancel = false) => {
      let categoriasCopy = [...categoriasLocal];
      if (categoriasCopy[idx]) {
        if (isCancel) {
          categoriasCopy[idx] = {
            ...categoriasLocal[idx],
            isEditing: false,
          };
          //
          let anterior = categoriasCopy[idx - 1];
          if (anterior) {
            categoriasCopy[idx - 1] = {
              ...categoriasLocal[idx - 1],
            };
          }
        } else {
          categoriasCopy[idx] = {
            ...categoriasLocal[idx],
            isEditing: isEditing,
          };
        }
      }
      //
      setCategoriasLocal(categoriasCopy);
      setHasChanged(true);
    };
  const onAddCategoria = () => {
    if (categoriasLocal.length >= 20) return;
    //
    let categoriasCopy = [...categoriasLocal];
    // let pendiente = calificaciones.pop();
    let ultimo = categoriasCopy[categoriasCopy.length - 1];
    let newCategoria = {
      id: 0,
      nombre: "Nueva categoría",
      puntaje_desde: 1,
      puntaje_hasta: ultimo.puntaje_desde - 0.01,
      isEditing: true,
      isRemovable: true,
    };
    categoriasCopy.push(newCategoria);
    setCategoriasLocal(categoriasCopy);
    setHasChanged(true);
  };
  const validateConfiguracion = () => {
    // TODO
    return true;
  };
  const onSaveConfig = () => {
    const categoriasLocalCopy = JSON.parse(JSON.stringify(categoriasLocal));
    categoriasLocalCopy.forEach((obj) => {
      // Convertir las propiedades puntaje_desde y puntaje_hasta a flotantes
      obj.puntaje_desde = parseFloat(
        String(obj.puntaje_desde).replace(",", ".")
      );
      obj.puntaje_hasta = parseFloat(
        String(obj.puntaje_hasta).replace(",", ".")
      );
    });
    dispatch(remuneracionActions.setCategorias(categoriasLocalCopy));
    //
    if (!!validateConfiguracion()) {
      dispatch(remuneracionActions.createCategorias({ id: idEscala }));
      setHasChanged(false);
    }
  };
  const goToEscalaLast = () => {
    if (!!idEscala) {
      navigate(RutasRemuneracion.ESCALA_ESCALA(idEscala));
    }
  };
  const categoriasIsEmpty = () => {
    if (categorias.length < 1) {
      return true;
    }
    if (categorias.length === 1) {
      let primeraCategoria = categorias[0];
      let soloSinCategoria = primeraCategoria.nombre === "Sin categoría";
      if (!!soloSinCategoria) {
        return true;
      }
    }
  };
  const [hasChanged, setHasChanged] = useState(false);
  //
  useEffect(() => {
    // PRIMERA VEZ CALCULO EN CLIENTE
    if (categorias.length === 1) {
      let primeraCategoria = categorias[0];
      let soloSinCategoria = primeraCategoria.nombre === "Sin categoría";
      if (!!soloSinCategoria) {
        let valoraciones = primeraCategoria.valoraciones;
        setCategoriasTablas(
          categoriasTemp.map((cat, ctidx) => ({
            ...cat,
            valoraciones: getValoracionesTemp(valoraciones, cat),
          }))
        );
      }
    }
  }, [categorias.length]);
  //
  useEffect(() => {
    dispatch(commonActions.showSidebar(false));
    dispatch(remuneracionActions.getCategorias({ id: idEscala }));
  }, []);
  //
  useEffect(() => {
    if (categorias.length > 1) {
      setCategoriasLocal(
        categorias
          .filter((cat, cati) => cat.id !== 0)
          .map((cat, cati) => ({
            ...cat,
            isRemovable: cati > 2,
            puntaje_desde: String(cat.puntaje_desde).replace(".", ","),
            puntaje_hasta: String(cat.puntaje_hasta).replace(".", ","),
          }))
      );
      //
      let categoriasCopy = categorias.map((cat, cati) => ({ ...cat }));
      if (categoriasCopy.length > 0) {
        setCategoriasTablas(categoriasCopy);
      }
    }
  }, [categorias.length]);
  //
  return (
    <RemuneracionConfiguracionLayout
      title={"Categorización"}
      onClickBack={onClickBack}
      currentStep={2}
    >
      <div className="w-full md:w-3/5 flex justify-between items-center bg-slate-100 rounded-md py-3 my-8 px-4">
        <h2 className="font-semibold text-3xl">Categorías por agrupación</h2>
        <button
          onClick={openCategoriasModal}
          className="bg-sky-500 text-white rounded-md py-3 px-4"
        >
          Configuración de categorías
        </button>
      </div>
      {/*  */}
      {!categorias_loading && (
        <div className="py-2 px-8 w-full flex justify-center">
          <div className="flex flex-col gap-8 w-full md:w-8/12">
            <div className="flex flex-col gap-3">
              <h2 className="font-semibold text-3xl">
                Agrupación por categorías
              </h2>
            </div>
            {!!categoriasTablas && (
              <>
                {categoriasTablas?.length < 1 && (
                  <div className="w-full flex justify-center border rounded-md py-4 text-slate-500 italic">
                    <p>No existen categorías creadas</p>
                  </div>
                )}
                {categoriasTablas?.length > 0 &&
                  categoriasTablas.map((categoria, cidx) =>
                    categoria.valoraciones?.length > 0 ? (
                      <CargosTable
                        key={`cargo-t-${cidx}`}
                        categoria={categoria}
                      />
                    ) : (
                      <></>
                    )
                  )}
              </>
            )}
            <div className="flex justify-center w-full my-2">
              {categorias.length > 0 && (
                <button
                  disabled={!!categoriasIsEmpty()}
                  onClick={goToEscalaLast}
                  className={`${
                    !!categoriasIsEmpty() ? "bg-sky-300" : "bg-sky-500 "
                  } w-full md:w-8/12 py-3 px-2 rounded-md text-white`}
                >
                  Configurar escala salarial
                </button>
              )}
              {categorias.length <= 0 && (
                <button
                  disabled={true}
                  className="w-full md:w-8/12 bg-slate-400 py-3 px-2 text-white rounded-md"
                >
                  Configurar escala salarial
                </button>
              )}
            </div>
          </div>
        </div>
      )}
      {/*  */}
      <SelectModal
        title={"Categorías"}
        titleClass={"text-2xl"}
        extraClass={"w-6/12 px-4"}
        content={
          <div>
            {!categorias_loading && (
              <>
                <div className="text-sm my-4">
                  Defina las categorías por cada agrupación. El rango debe ir de
                  1 a 100 puntos.
                </div>
                {/* headers */}
                <div className="w-full justify-between flex">
                  <div className="group h-12 flex justify-between items-center gap-2 w-1/2 font-bold py-2">
                    <div>Tipo de categoría</div>
                  </div>
                  <div className="flex flex-1 gap-2 items-center borderx border-blue-500x py-2 w-1/2">
                    <div className="flex-1 font-semibold">Desde</div>
                    <div className="flex-1">
                      <div className="flex items-center justify-between gap-2 w-1/2">
                        <div className="font-semibold">Hasta</div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*  */}
                {categoriasLocal.map((categoria, cidx) =>
                  CategoriaRow(
                    categoria,
                    cidx,
                    onEditRow,
                    categoria.isEditing, //isEditing,
                    onChangeEditing(cidx),
                    true, // isEditable,
                    onDeleteCategoria(cidx),
                    false, //disabled,
                    `cr-${cidx}`
                  )
                )}
                <div>
                  <button
                    disabled={categoriasLocal.find(
                      (cat, cati) => !!cat.isEditing
                    )}
                    onClick={onAddCategoria}
                    className={`${
                      categoriasLocal.find((cat, cati) => !!cat.isEditing)
                        ? "text-slate-500"
                        : "text-sky-500"
                    } font-bold text-sm`}
                  >
                    Agregar nueva categoría
                  </button>
                </div>
              </>
            )}
            {!!categorias_loading && (
              <div className="w-full flex items-center justify-center h-72">
                <LoadingSpinButton message="Guardando, por favor espere..." />
              </div>
            )}
            <div className="mt-4">
              {!categorias_loading && (
                <button
                  onClick={onSaveConfig}
                  className="w-full bg-sky-500 rounded-md p-4 text-white"
                >
                  Guardar configuración
                </button>
              )}
            </div>
          </div>
        }
      />
    </RemuneracionConfiguracionLayout>
  );
}
export default RemuneracionCategorizacion;
