import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactSelect from "react-select";
import TableCustom from "src/modules/common/components/TableCustom";
import ToggleSwitch from "src/modules/common/components/ToggleSwitch";
import { AreaSelect } from "../../../../estructura/modules/area/components/AreaSelect";
import { CargoSelect } from "../../../../estructura/modules/cargo/components/CargoSelect";
import { DepartamentoSelect } from "../../../../estructura/modules/departamento/components/DepartamentoSelect";
import { SucursalSelect } from "../../../../estructura/modules/sucursal/components/SucursalSelect";
import ColaboradoresLibresSelectModal from "../components/ColaboradoresLibresSelectModal";
import TableRow from "src/modules/common/components/TableRow";
import { ProfileInfoCard } from "src/modules/common/components/ProfileInfoCard";
import ribbon from "../../../../estructura/modules/estructura/images/blue-ribbon.png";
import { colaboradorLaboralActions } from "src/modules/colaborador/modules/laboral/handlers/redux";
import { useParams } from "react-router-dom";
import { empresaActions } from "src/modules/ajuste/handlers/redux";
import SelectModal from "src/modules/common/components/SelectModal";
import { colaboradorActions } from "../../colaborador/handlers/redux";
import { commonActions } from "src/modules/common/handler/redux";
import { type } from "@testing-library/user-event/dist/type";
import { sucursalActions } from "src/modules/estructura/modules/sucursal/handlers/redux";
import { areaActions } from "src/modules/estructura/modules/area/handlers/redux";
import { departamentoActions } from "src/modules/estructura/modules/departamento/handlers/redux";
import { cargoActions } from "src/modules/estructura/modules/cargo/handlers/redux";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import { X } from "react-bootstrap-icons";
import { utcToZonedTime } from "date-fns-tz";
import { TipoContratacionSelect } from "../components/TipoContratacionSelect";
import WeekdaySelector from "src/modules/common/components/WeekdaySelector";

function ColaboradorLaboralPage() {
  const dispatch = useDispatch();
  const esModoVista = useSelector(
    (state) => state.colaboradorLaboral.esModoVista
  );
  const hasChanged = useSelector(
    (state) => state.colaboradorLaboral.hasChanged
  );
  const setEsModoVista = (esModoVista) => {
    dispatch(colaboradorLaboralActions.setEsModoVista(esModoVista));
  };
  const setHasChanged = (hasChanged) => {
    dispatch(colaboradorLaboralActions.setHasChanged(hasChanged));
  };
  const params = useParams();
  const empresa = useSelector((state) => state.empresa.empresa);
  const colaborador = useSelector(
    (state) => state.colaborador.colaborador || {}
  );

  const colaborador_acceso = useSelector(
    (state) => state.colaborador.colaborador_acceso
  );

  const colaborador_laboral = useSelector(
    (state) => state.colaboradorLaboral.colaborador_laboral || {}
  );

  const colaboradores_libres = useSelector(
    (state) => state.colaboradorLaboral.colaboradores_libres || {}
  );

  const colaboradores_laboral_checked = useSelector(
    (state) => state.colaboradorLaboral.colaboradores_laboral_checked
  );

  const colaborador_laboral_update_loading = useSelector(
    (state) => state.colaboradorLaboral.colaborador_laboral_update_loading
  );

  const rolesResults = useSelector((state) => state.colaborador.roles) || [];
  const { results: roles } = rolesResults || [];

  const {
    sucursal = "",
    area,
    departamento,
    jefe_directo = null,
    fecha_ingreso,
    cargo,
    cargo_nombre = "",
    rol,
    colaboradores = [],
    centro_costo = "",
    tipo_contratacion = null,
    tiene_dias_habiles_personalizados = false,
  } = colaborador_laboral;
  const [subordinadosLocal, setSubordinadosLocal] = useState(colaboradores);
  const [subordinadosSearch, setSubordinadosSearch] = useState("");
  const onSearchSubordinados = (e) => {
    let value = e.target.value;
    value = value.toLowerCase();
    setSubordinadosSearch(value);
    let subordinadosFiltrados = JSON.parse(JSON.stringify(colaboradores));
    subordinadosFiltrados = subordinadosFiltrados.filter((colaborador) => {
      return (
        colaborador.nombre.toLowerCase().includes(value) ||
        colaborador.apellido.toLowerCase().includes(value)
      );
    });
    setSubordinadosLocal(subordinadosFiltrados);
  };

  useEffect(() => {
    if (colaboradores.length > 0) {
      setSubordinadosLocal(colaboradores);
    }
  }, [colaboradores]);

  useEffect(() => {
    // select fields values
    dispatch(sucursalActions.sucursales());
    dispatch(areaActions.areas());
    dispatch(departamentoActions.departamentos());
    dispatch(cargoActions.cargos());
    //
    setHasChanged(false);
    dispatch(empresaActions.empresa());
    dispatch(colaboradorActions.jefes());
    dispatch(colaboradorActions.roles());
    dispatch(
      colaboradorLaboralActions.colaboradorLaboralGet({
        id: params.idColaborador,
      })
    );
  }, []);

  useEffect(() => {
    if (esModoVista)
      dispatch(
        commonActions.setEditDetailButton({
          label: "Editar datos generales",
          action: () => modoVistaEdit(),
        })
      );
  }, [esModoVista]);

  const onChange =
    (name) =>
      ({ target = {} }) => {
        setHasChanged(true);
        let value = null;
        if (name === "tiene_dias_habiles_personalizados") {
          value = target.checked;
        }
        else if (name === "dias_habiles") {
          value = target.checked;
          console.log("dia, checked", value);
        }
        else {
          value = target.value;
        }
        dispatch(
          colaboradorLaboralActions.colaboradorLaboralSet({
            ...colaborador_laboral,
            [name]: value,
          })
        );
      };

  const onChangeDate = (name) => (date) => {
    setHasChanged(true);
    dispatch(
      colaboradorLaboralActions.colaboradorLaboralSet({
        ...colaborador_laboral,
        [name]: date,
      })
    );
  };

  const onChangeRol = (e) => {
    const selectedRolId = e.target.value;
    setHasChanged(true);
    dispatch(
      colaboradorLaboralActions.colaboradorLaboralSet({
        ...colaborador_laboral,
        rol: { id: parseInt(selectedRolId) },
      })
    );
  };

  const onClickAdd = () => {
    dispatch(colaboradorLaboralActions.colaboradoresLibres(colaborador));
    dispatch(
      colaboradorLaboralActions.setCheckColaboradoresLibres(
        colaboradores.map((c) => c.id)
      )
    );
    dispatch(
      colaboradorLaboralActions.setColaboradoresLibresSelectModal({
        open: true,
      })
    );
  };

  const showJefeModal = () => {
    dispatch(commonActions.setSelectModal({ open: true }));
  };

  const eliminarJefeDirecto = () => {
    dispatch(
      colaboradorLaboralActions.colaboradorLaboralSet({
        ...colaborador_laboral,
        jefe_directo: null,
      })
    );
    //
    setHasChanged(true);
  };

  //
  const renderEstructuraDetail = () => {
    return (
      <div className="col-span-1 md:col-span-4 flex flex-col  mb-4 md:mr-12">
        <span className="font-bold text-lg">Estructura organizacional</span>

        {!tienePlanAvanzado && (
          <div className="my-2">
            <div className="flex items-center p-1 border-2 rounded border-sky-500 bg-sky-50">
              <img className="p-2" src={ribbon} alt="ribbon" />
              <div className="p-2 font-[14px]">
                Estructura organizacional pertenece al PLAN AVANZADO.
              </div>
            </div>
          </div>
        )}
        <div className="flex flex-col mb-3 mt-2">
          <label className="font-bold text-md" htmlFor="">
            Sucursal:
          </label>
          <SucursalSelect
            isDisabled={true}
            onChange={onChange("sucursal")}
            value={sucursal}
            defaultLabel={"Sin sucursal"}
          />
        </div>
        <div className="flex flex-col mb-3">
          <label className="font-bold text-md" htmlFor="">
            Área:
          </label>
          <AreaSelect
            disabled={true}
            onChange={onChange("area")}
            value={area}
            defaultLabel={"Sin área"}
          />
        </div>
        <div className="flex flex-col mb-3">
          <label className="font-bold text-md" htmlFor="">
            Departamento:
          </label>

          <DepartamentoSelect
            isDisabled={true}
            onChange={onChange("departamento")}
            value={departamento}
            defaultLabel={"Sin departamento"}
          />
        </div>
        <div className="flex flex-col">
          <label className="font-bold text-md" htmlFor="">
            Jefe directo:
          </label>
          <div className="flex text-left p-2 border border-zinc-200 rounded-md bg-zinc-100 text-gray-400">
            {!!jefe_directo
              ? `${jefe_directo.apellido}, ${jefe_directo.nombre}`
              : "Sin asignar"}
          </div>
        </div>
        <div className="flex flex-col mt-2">
          <label className="font-bold text-md" htmlFor="">
            Rol:
          </label>
          <div className="flex text-left p-2 border border-zinc-200 rounded-md bg-zinc-100 text-gray-400">
            {rol?.name || "Sin asignar"}
          </div>
        </div>
        <div className="flex flex-col mt-2">
          <label className="font-bold text-md" htmlFor="">
            Tipo contratación:
          </label>
          <div className="flex text-left p-2 border border-zinc-200 rounded-md bg-zinc-100 text-gray-400">
            {tipo_contratacion ?? "Sin asignar"}
          </div>
        </div>
        {/*  */}
        <div className="flex flex-col mb-3 mt-2 gap-2">
          <div className="flex items-center mt-2">
            <ToggleSwitch
              checked={tiene_dias_habiles_personalizados}
              onChange={onChange("tiene_dias_habiles_personalizados")}
              disabled={true}
            />
            <span className="ml-2 text-sm font-bold">Días hábiles personalizados</span>
          </div>
          {tiene_dias_habiles_personalizados && (
            <WeekdaySelector selectedDays={colaborador_laboral.dias_habiles} extraClasses="w-64 text-sm" />
          )}
        </div>
      </div>
    );
  };

  const renderCargoDetail = () => {
    return (
      <div className="col-span-1 mr-6 my-2">
        <div className="flex flex-col">
          <label className="font-bold text-base" htmlFor="">
            Cargo:
          </label>
          <CargoSelect
            disabled={true}
            onChange={onChange("cargo")}
            value={cargo}
            cargoNombre={cargo_nombre}
          />
        </div>
      </div>
    );
  };

  const renderFechaIngresoDetail = () => {
    return (
      <div className="col-span-1 mr-6 my-2 ">
        <div className="flex flex-col">
          <label className="font-bold text-base" htmlFor="">
            Fecha de ingreso:
          </label>
          <div className="flex text-left p-2 border border-zinc-200 rounded-md bg-zinc-100 text-gray-400">
            {!!fecha_ingreso ? dayjs(fecha_ingreso).format("DD/MM/YYYY") : "Sin asignar"}
          </div>
        </div>
      </div>
    );
  };

  const renderSubordinadosDetail = () => {
    return (
      <>
        <div className="col-span-2 my-4 border-b pb-3">
          <div className="font-bold font-[18px]">
            Colaboradores bajo su cargo
          </div>
        </div>
        <div className="col-span-2 mr-6 my-2 ">
          <ColaboradoresLibresSelectModal
            colaboradores={colaboradores_libres.results || []}
          ></ColaboradoresLibresSelectModal>
          <TableCustom
            ItemComponent={ColaboradorItem}
            data={subordinadosLocal}
            subject={"colaborador"}
            subjectGender={"M"}
            hasAddBtn={false}
            noBottomButtom={true}
            onChangeSearch={onSearchSubordinados}
            searchValue={subordinadosSearch}
          />
        </div>
      </>
    );
  };

  const handleDayToggle = (name, index, value) => {
    setHasChanged(true);
    let dias_habiles = JSON.parse(JSON.stringify(colaborador_laboral.dias_habiles)) || [];
    if (name === "dia") {
      if (value) {
        // Si value es true y el día no está en dias_habiles, agregarlo con 0 horas
        if (!dias_habiles.some(dia => dia.dia === index)) {
          dias_habiles.push({ dia: index, cantidad_horas: 0 });
        }
      } else {
        // Si value es false, eliminar el día de dias_habiles
        dias_habiles = dias_habiles.filter(dia => dia.dia !== index);
      }
    } else if (name === "cantidad_horas") {
      // Buscar el día y actualizar la cantidad de horas
      const diaIndex = dias_habiles.findIndex(dia => dia.dia === index);
      if (diaIndex !== -1) {
        dias_habiles[diaIndex].cantidad_horas = value;
      }
    }
    dispatch(
      colaboradorLaboralActions.colaboradorLaboralSet({
        ...colaborador_laboral,
        dias_habiles: dias_habiles,
      })
    );
  }
  const renderEstructura = () => {
    return (
      <div className="col-span-1 md:col-span-4 flex flex-col  mb-4 md:mr-12">
        <span className="font-bold text-lg">Estructura organizacional</span>

        {!tienePlanAvanzado() && (
          <div className="my-2">
            <div className="flex items-center p-1 border-2 rounded border-sky-500 bg-sky-50">
              <img className="p-2" src={ribbon} alt="ribbon" />
              <div className="p-2 font-[14px]">
                Estructura organizacional pertenece al PLAN AVANZADO.
              </div>
            </div>
          </div>
        )}
        <div
          className={`${!tienePlanAvanzado()
            ? "border-2 border-dashed rounded-lg p-2 mb-4"
            : ""
            }`}
        >
          <div className="flex flex-col mb-3 mt-2">
            <label className="font-bold text-md" htmlFor="">
              Sucursal:
            </label>
            <SucursalSelect
              isDisabled={!tienePlanAvanzado()}
              onChange={onChange("sucursal")}
              defaultLabel={"Sin sucursal"}
              value={sucursal}
            />
          </div>
          <div className="flex flex-col mb-3">
            <label className="font-bold text-md" htmlFor="">
              Área:
            </label>
            <AreaSelect
              disabled={!tienePlanAvanzado()}
              onChange={onChange("area")}
              defaultLabel={"Sin área"}
              value={area}
            />
          </div>
          <div className="flex flex-col mb-3">
            <label className="font-bold text-md" htmlFor="">
              Departamento:
            </label>
            <DepartamentoSelect
              isDisabled={!tienePlanAvanzado()}
              onChange={onChange("departamento")}
              defaultLabel={"Sin departamento"}
              value={departamento}
            />
          </div>
        </div>
        <div className="flex flex-col">
          <label className="font-bold font-[15px]" htmlFor="">
            Jefe directo:
          </label>
          <div className="flex items-center justify-between gap-2 border border-zinc-200 rounded-md p-2">
            <button className="flex-1" onClick={showJefeModal}>
              <div className="text-left">
                {jefe_directo
                  ? `${jefe_directo.apellido}, ${jefe_directo.nombre}`
                  : "Sin asignar"}
              </div>
            </button>
            <button onClick={eliminarJefeDirecto}>
              <X size={24}></X>
            </button>
          </div>
        </div>
        {/*  */}
        {/* ROL */}
        <div className="flex flex-col mt-2">
          <label className="font-bold text-base" htmlFor="">
            Rol:
          </label>
          {colaborador_acceso.id > 0 && (
            <select
              name="rol-select"
              id="rol-select"
              onChange={onChangeRol}
              value={rol?.id}
              className={"py-2 pl-1 rounded-md bg-white border"}
            >
              <option>Seleccione una opción</option>
              {roles.map((rol, idx) => (
                <option value={rol.id}>{rol.name}</option>
              ))}
            </select>
          )}
          {!colaborador_acceso.id && (
            <div className="border rounded-md p-2 bg-zinc-100">Sin usuario</div>
          )}
        </div>
        {/*  */}
        <div className="flex flex-col mb-3 mt-2">
          <label className="font-bold text-md" htmlFor="">
            Tipo contratación:
          </label>
          <TipoContratacionSelect
            isDisabled={!tienePlanAvanzado()}
            onChange={onChange("tipo_contratacion")}
            value={tipo_contratacion}
          />
        </div>
        {/*  */}
        <div className="flex flex-col mb-3 mt-2 gap-2">
          <div className="flex items-center mt-2">
            <ToggleSwitch
              checked={tiene_dias_habiles_personalizados}
              onChange={onChange("tiene_dias_habiles_personalizados")}
            />
            <span className="ml-2 text-sm font-bold">Días hábiles personalizados</span>
          </div>
          {tiene_dias_habiles_personalizados && (
            <WeekdaySelector 
              editable={true} 
              onDayToggle={handleDayToggle} 
              selectedDays={colaborador_laboral.dias_habiles}
              extraClasses="w-64 text-sm"   
            />
          )}
        </div>
      </div>
    );
  };

  const renderCargo = () => {
    return (
      <div className="col-span-1 mr-6 my-2 ">
        <div className="flex flex-col">
          <label className="font-bold text-base" htmlFor="">
            Cargo:
          </label>

          <CargoSelect
            onChange={onChange("cargo")}
            value={cargo}
            defaultLabel={"Sin cargo"}
            cargoNombre={cargo_nombre}
          />
        </div>
      </div>
    );
  };

  const renderFechaIngreso = () => {
    return (
      <div className="col-span-1 mr-6 my-2 ">
        <div className="flex flex-col">
          <label className="font-bold text-base" htmlFor="">
            Fecha de ingreso:
          </label>
          <DatePicker
            className="border rounded placeholder-zinc-600 border-zinc-200 px-2 py-1.5 w-full"
            locale="es"
            selected={utcToZonedTime(
              fecha_ingreso,
              "America/Asuncion"
            ).getTime()}
            onChange={onChangeDate("fecha_ingreso")}
            dateFormat="dd/MM/yyyy"
          />
        </div>
      </div>
    );
  };

  const onEliminarVarios = () => {
    setHasChanged(true);
    const colaboradoresCopy = colaboradores.filter(
      (colaborador) => !colaboradores_laboral_checked.includes(colaborador.id)
    );

    dispatch(
      colaboradorLaboralActions.colaboradorLaboralSet({
        ...colaborador_laboral,
        colaboradores: colaboradoresCopy,
      })
    );
    dispatch(colaboradorLaboralActions.resetCheckColaboradoresLaborales());
  };

  const onSelectChange = (v, items = []) => {
    if (v.value === "eliminar") {
      let deseaEliminar = window.confirm(
        "Desea eliminar los Colaboradores seleccionados?"
      );
      if (deseaEliminar) {
        onEliminarVarios();
        setHasChanged(true);
      }
    } else if (v.value === "cancel") {
      dispatch(colaboradorLaboralActions.resetCheckColaboradoresLaborales());
    } else {
      // @ts-ignore
      window.alert(JSON.stringify(v));
    }
  };

  const onCheckColaboradores = (event, item) => {
    dispatch(
      colaboradorLaboralActions.checkColaboradorLaboral({
        colaborador: item,
        checked: event.target.checked,
      })
    );
  };
  //
  const [nombreSubordinado, setNombreSubordinado] = useState("");
  const onChangeNombreSubordinado = (e) => {
    let word = e.target.value;
    setNombreSubordinado(word);
    //
    let colaboradoresFilter = colaboradores.filter((c, i) =>
      c.nombre.toUpperCase().includes(word.toUpperCase())
    );
    !!process.env.REACT_APP_DEBUG &&
      console.info("colaboradoresFilter", colaboradoresFilter);
    // TODO
    dispatch(
      colaboradorLaboralActions.colaboradorLaboralSet({
        ...colaborador_laboral,
        colaboradoresFilter,
      })
    );
  };

  const renderSubordinados = () => {
    return (
      <>
        <div className="col-span-2 my-4 border-b pb-3">
          <div className="font-bold text-md">Colaboradores bajo su cargo</div>
        </div>
        <div className="col-span-2 mr-6 my-2 ">
          <ColaboradoresLibresSelectModal
            colaboradores={colaboradores_libres.results || []}
          ></ColaboradoresLibresSelectModal>
          {/*  */}
          <TableCustom
            ItemComponent={ColaboradorItem}
            data={subordinadosLocal}
            itemsChecked={colaboradores_laboral_checked}
            onCheckHandler={onCheckColaboradores}
            subject={"colaborador"}
            subjectGender={"M"}
            addBtnClickHandler={onClickAdd}
            onSelectChange={onSelectChange}
            selectOptions={[
              { value: "eliminar", label: "Eliminar" },
              { value: "cancel", label: "Cancelar" },
            ]}
            noBottomButtom={true}
            searchValue={nombreSubordinado}
            onChangeSearch={onChangeNombreSubordinado}
          />
        </div>
      </>
    );
  };

  const tienePlanAvanzado = () => {
    return empresa.tipo_plan === "Avanzado";
  };

  const ColaboradorItem = ({ item }) => {
    const dispatch = useDispatch();

    const cargos = useSelector((state) => state.cargo?.cargos?.results || []);

    const colaborador_laboral = useSelector(
      (state) => state.colaboradorLaboral.colaborador_laboral
    );

    let { cargo } = colaborador_laboral;

    cargo = cargos.find((v) => v.id == cargo) || {};

    return (
      <>
        <TableRow>
          <div
            className="p-4 flex flex-row items-center justify-center cursor-pointer"
            onClick={() => {
              let { colaboradores = [] } = colaborador_laboral;
              colaboradores = colaboradores.filter((c) => c.id != item.id);
              dispatch(
                colaboradorLaboralActions.colaboradorLaboralSet({
                  ...colaborador_laboral,
                  colaboradores,
                })
              );
            }}
          >
            <ProfileInfoCard
              name={`${item.nombre} ${item.apellido}`}
              image={item.foto_empleado ? item.foto_empleado : ""}
            >
              <div className="text-sm">
                Cargo: {!!item.cargo ? item.cargo.nombre : "Sin cargo"}
              </div>
            </ProfileInfoCard>
          </div>
        </TableRow>
      </>
    );
  };

  const onSubmitLaboral = () => {
    //
    const rolId = colaborador_laboral.rol ? colaborador_laboral.rol.id : null;
    const jefeId = colaborador_laboral.jefe_directo
      ? colaborador_laboral.jefe_directo.id
      : null;
    const tipoContratacionId = colaborador_laboral.tipo_contratacion
      ? colaborador_laboral.tipo_contratacion
      : null;
    //
    let parsedDate = null;
    const fecha_ingreso = colaborador_laboral.fecha_ingreso;
    if (fecha_ingreso) {
      parsedDate = dayjs(fecha_ingreso).format("YYYY-MM-DD");
    }

    dispatch(
      colaboradorLaboralActions.colaboradorLaboralSet({
        ...colaborador_laboral,
        jefe_directo: jefeId,
        rol: rolId,
        fecha_ingreso: parsedDate,
        tipo_contratacion: tipoContratacionId,
      })
    );
    //
    dispatch(
      colaboradorLaboralActions.colaboradorLaboralUpdate({
        id: params.idColaborador,
      })
    );
  };
  // TABLA JEFES
  const changeJefeDirecto = () => {
    if (jefes_checked.length < 1) return;
    const selectedJefeId = jefes_checked[0];
    const jefe = jefes.find((jefe, idx) => jefe.id === selectedJefeId);
    //
    dispatch(
      colaboradorLaboralActions.colaboradorLaboralSet({
        ...colaborador_laboral,
        jefe_directo: jefe,
      })
    );
    //
    setHasChanged(true);
    dispatch(commonActions.setSelectModal({ open: false }));
  };
  const jefesResults = useSelector((state) => state.colaborador.jefes) || [];
  const { results: jefes } = jefesResults || [];
  const jefes_filter = useSelector((state) => state.colaborador.jefes_filter);
  const { nombre: nombre_jefe = "" } = jefes_filter;
  const jefes_checked =
    useSelector((state) => state.colaborador.jefes_checked) || [];
  const ItemCommonComponent = ({ item }) => {
    return (
      <>
        <TableRow>
          <div
            className="p-4 flex gap-8 items-start justify-start cursor-pointer"
            onClick={() => { }}
          >
            <ProfileInfoCard
              name={`${item.nombre} ${item.apellido}`}
              image={item.foto_empleado ? item.foto_empleado : ""}
              nameWidth={52}
            ></ProfileInfoCard>
          </div>
        </TableRow>
      </>
    );
  };
  const commonTableProps = {
    ItemComponent: ItemCommonComponent,
    subject: "colaborador",
    subjectGender: "M",
    hasAddBtn: true,
    customAddBtnLabel: "Asignar",
    noBottomButtom: true,
    selectOptions: [],
    checkType: "radio",
  };
  const modalTableConfig = {
    tableSecondTitle: "Jefes directos",
    addBtnClickHandler: () => changeJefeDirecto(),
    checkedState: "jefes",
    onCheckHandler: (event, item) => {
      dispatch(colaboradorActions.resetCheckJefes());
      dispatch(
        colaboradorActions.checkJefe({
          colaborador: item,
          checked: event.target.checked,
        })
      );
    },
  };
  const onChangeJefeText = (event) => {
    dispatch(colaboradorActions.setJefesFilter({ nombre: event.target.value }));
  };
  const modoVistaEdit = () => {
    setEsModoVista(false);
    dispatch(commonActions.setEditDetailButton(false));
  };

  const onCancelar = () => {
    setEsModoVista(true);
    dispatch(
      commonActions.setEditDetailButton({
        label: "Editar datos laborales",
        action: modoVistaEdit,
      })
    );
    //
    if (params.idColaborador) {
      setHasChanged(false);
      dispatch(
        colaboradorLaboralActions.colaboradorLaboralGet({
          id: params.idColaborador,
        })
      );
    }
  };

  return (
    <>
      {!esModoVista && (
        <div className="flex justify-center items-center w-full mt-10">
          <div className="flex flex-col w-full md:w-5/6">
            {!colaborador_laboral_update_loading && (
              <div className="grid grid-cols-1 md:grid-cols-12">
                {/* ESTRUCTURA ORGANIZACIONAL */}
                {renderEstructura()}
                {/* DATOS PERSONALES */}
                <div className="col-span-1 md:col-span-8">
                  <div className="flex items-center justify-between border-b mb-2 pb-2">
                    <div className="text-lg font-bold">
                      Información del cargo
                    </div>
                    <button
                      onClick={onSubmitLaboral}
                      className={`${hasChanged
                        ? "bg-sky-500 border-sky-500"
                        : "bg-slate-400 border-slate-400"
                        } border-2 rounded px-4 py-2 text-white`}
                    >
                      Guardar datos
                    </button>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2">
                    {renderCargo()}
                    {renderFechaIngreso()}
                    <div className="col-span-1 mr-6 my-2">
                      <div className="flex flex-col">
                        <label className="font-bold text-base" htmlFor="">
                          Centro de costo:
                        </label>
                        <div>
                          {/* {centro_costo || "-"} */}
                          <input
                            className="border rounded placeholder-zinc-600 border-zinc-200 px-2 py-1.5 w-full"
                            value={centro_costo}
                            onChange={onChange("centro_costo")}
                          />
                        </div>
                      </div>
                    </div>
                    {renderSubordinados()}
                    <div className="col-span-2 mt-4 flex gap-2">
                      <button
                        onClick={onSubmitLaboral}
                        className={`${hasChanged
                          ? "bg-sky-500 border-2 border-sky-500"
                          : "bg-slate-400 border-2 border-slate-400"
                          } rounded w-full px-4 py-2 text-white`}
                      >
                        Guardar datos
                      </button>
                      <button
                        onClick={onCancelar}
                        className={`bg-white border-black border rounded w-full px-4 py-2 text-black`}
                      >
                        Cancelar
                      </button>
                    </div>
                  </div>
                </div>
                .{/*  */}
                <SelectModal
                  title={`Asignar ${modalTableConfig.tableSecondTitle}`}
                  content={
                    <TableCustom
                      {...modalTableConfig}
                      {...commonTableProps}
                      data={jefes || []}
                      searchValue={nombre_jefe}
                      onChangeSearch={onChangeJefeText}
                      itemsChecked={jefes_checked}
                      multiple={false}
                      fixedHeigth={true}
                    />
                  }
                ></SelectModal>
              </div>
            )}
            {/* LOADING */}
            {!!colaborador_laboral_update_loading && (
              <div className="grid grid-cols-1 md:grid-cols-12 gap-4 animate-pulse">
                <div className="h-96 col-span-1 md:col-span-4 rounded-md bg-zinc-100"></div>
                <div className="h-96 col-span-1 md:col-span-8 rounded-md bg-zinc-100"></div>
              </div>
            )}
          </div>
        </div>
      )}
      {!!esModoVista && (
        <div className="flex justify-center items-center w-full mt-10">
          <div className="flex flex-col w-full md:w-5/6">
            <div className="grid grid-cols-1 md:grid-cols-12">
              {/* ESTRUCTURA ORGANIZACIONAL */}
              {renderEstructuraDetail()}
              {/* DATOS PERSONALES */}
              <div className="col-span-1 md:col-span-8 ">
                <div className="font-bold text-lg border-b mb-2 pb-2">
                  Información del cargo
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2">
                  {renderCargoDetail()}
                  {renderFechaIngresoDetail()}
                  <div className="col-span-1 mr-6 my-2">
                    <div className="flex flex-col">
                      <label className="font-bold text-base" htmlFor="">
                        Centro de costo:
                      </label>
                      <div className="flex text-left p-2 border border-zinc-200 rounded-md bg-zinc-100 text-gray-400">
                        {centro_costo || "-"}
                      </div>
                    </div>
                  </div>
                  {renderSubordinadosDetail()}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ColaboradorLaboralPage;
