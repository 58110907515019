import React, { useState } from "react";
import "./css/ToggleSwitch.css";

function ToggleSwitch({ className, checked = false, onChange = null, disabled = false }) {
  return (
    <div className={className}>
      <label className="switch">
        <input type="checkbox" checked={checked} onChange={onChange} disabled={disabled} />
        <span className="slider round"></span>
      </label>
    </div>
  );
}

export default ToggleSwitch;
