import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { PrimaryButton } from "src/modules/common/components/PrimaryButton";
import Modal from "src/modules/common/components/Modal";
import { X } from "react-bootstrap-icons";
import SearchSelect from "src/modules/common/components/SearchSelect";
import ReactSelect from "react-select";
import MostrarPaginas from "src/modules/common/components/MostrarPaginas";
import { ErrorToast } from "src/modules/common/components/ErrorToast";
import { useDispatch, useSelector } from "react-redux";
import { encuestaActions } from "../handlers/redux";
import useSucursales from "src/modules/evaluacion/hooks/useSucursales";
import useAreas from "src/modules/evaluacion/hooks/useAreas";
import useDepartamentos from "src/modules/evaluacion/hooks/useDepartamentos";
import SearchInput from "src/modules/remuneracion/components/SearchInput";
import DataTable from "src/modules/common/components/DataTable";
import userDefault from "src/modules/common/components/images/user_default.png";
import LoadingSpinButton from "src/modules/common/components/LoadingSpinButton";
import LoadingSpinButton2 from "src/modules/common/components/LoadingSpinButton2";
import { useParams } from "react-router-dom";

const EncuestaDatosAlcance = ({ encuesta, handleInputChange }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const { encuestaId } = params;

  // Colaboradores seleccionables son todos los colaboradores cargados en la nómina
  const colaboradores_seleccionables = useSelector(
    (state) => state.encuesta.colaboradores_seleccionables
  );
  const colaboradores_seleccionables_count = useSelector(
    (state) => state.encuesta.colaboradores_seleccionables_count
  );
  const colaboradores_seleccionables_loading = useSelector(
    (state) => state.encuesta.colaboradores_seleccionables_loading
  );
  const colaboradores_seleccionables_filter = useSelector(
    (state) => state.encuesta.colaboradores_seleccionables_filter
  );
  const colaboradores_seleccionables_checked = useSelector(
    (state) => state.encuesta.colaboradores_seleccionables_checked
  );
  const { nombre = "" } = colaboradores_seleccionables_filter;

  // Colaboradores asignados son los colaboradores que estan asignados a la encuesta
  const colaboradores_asignados = useSelector(
    (state) => state.encuesta.colaboradores_asignados
  );
  const colaboradores_asignados_loading = useSelector(
    (state) => state.encuesta.colaboradores_asignados_loading
  );

  const {
    sucursales,
    nombre: nombreSucursal,
    filterSucursales,
  } = useSucursales();
  const { areas, nombre: nombreArea, filterAreas } = useAreas();
  const {
    departamentos,
    nombre: nombreDepartamento,
    filterDepartamentos,
  } = useDepartamentos();

  const [showColaboradoresModal, setShowColaboradoresModal] = useState(false);
  const [page, setPage] = useState(1);
  const [PageSize, setPageSize] = useState(1000);

  useEffect(() => {
    // Para precargar los colaboradores asignados en la encuesta para cuando es actualización de la encuesta
    if (encuestaId) {
      dispatch(
        encuestaActions.setColaboradoresSeleccionablesChecked(
          colaboradores_asignados
        )
      );
      const colaboradoresIds = colaboradores_asignados.map(
        (colaborador) => colaborador.id
      );
      handleInputChange({
        target: {
          name: "empleados_ids",
          value: colaboradoresIds,
        },
      });
    } else {
      dispatch(encuestaActions.setColaboradoresSeleccionablesChecked([]));
    }
  }, [colaboradores_asignados]);

  const onOpenModal = () => {
    dispatch(
      encuestaActions.setColaboradoresSeleccionablesFilter({
        ...colaboradores_seleccionables_filter,
        nombre: "",
        pagination: {
          page: 1,
          pageSize: PageSize,
        },
        sucursal: null,
        area: null,
        departamento: null,
      })
    );
    setShowColaboradoresModal(true);
    setPage(1);
  };

  const onCloseModal = () => {
    dispatch(
      encuestaActions.setColaboradoresSeleccionablesFilter({
        ...colaboradores_seleccionables_filter,
        nombre: "",
        pagination: {
          page: 1,
          pageSize: PageSize,
        },
        sucursal: null,
        area: null,
        departamento: null,
      })
    );
    setShowColaboradoresModal(false);
  };

  const onSubmitAddColaboradores = () => {
    const newColaboradores = [...colaboradores_seleccionables_checked];
    if (newColaboradores.length === 0) {
      ErrorToast({ message: "Debe seleccionar al menos un colaborador." });
      return;
    }
    const newColaboradoresIds = newColaboradores.map(
      (colaborador) => colaborador.id
    );
    handleInputChange({
      target: {
        name: "empleados_ids",
        value: newColaboradoresIds,
      },
    });
    onCloseModal();
  };

  const onSearchColaborador = (e) => {
    const value = e.target.value;
    dispatch(
      encuestaActions.setColaboradoresSeleccionablesFilter({
        ...colaboradores_seleccionables_filter,
        nombre: value || "",
        pagination: {
          page: 1,
          pageSize: PageSize,
        },
      })
    );
  };

  const onChangePageSize = (e) => {
    const value = e.target?.value;
    dispatch(
      encuestaActions.setColaboradoresSeleccionablesFilter({
        ...colaboradores_seleccionables_filter,
        pagination: {
          pageSize: parseInt(value),
          page: 1,
        },
      })
    );
    setPageSize(parseInt(value));
  };

  const datatableConfig = {
    headers: [
      {
        title: "Colaboradores",
        contentClass: "px-2 py-4",
        // onOrder: setOrdering("colaborador__nombre"),
        // accessor: "nombre",
        contentRenderer: (item) => (
          <div className="flex flex-row gap-2 items-center">
            <img
              className="object-cover h-12 w-12 rounded-md"
              src={item.foto_empleado || userDefault}
              alt={`Perfil`}
            />
            <div className="flex flex-col">
              <span className="">
                {item.nombre} {item.apellido}
              </span>
              <span className="text-sm">
                {item.cargo ? item.cargo.nombre : "Sin cargo"}
              </span>
            </div>
          </div>
        ),
      },
    ],
    rowActions: [],
  };

  const onPaginate = (page, pageSize) => {
    console.log(page, pageSize);
    //
    dispatch(
      encuestaActions.setColaboradoresSeleccionablesFilter({
        ...colaboradores_seleccionables_filter,
        pagination: {
          page: page,
          pageSize: pageSize,
        },
      })
    );
  };

  const onCheckAll = () => {
    let checkedData = JSON.parse(JSON.stringify(colaboradores_seleccionables));
    if (colaboradores_seleccionables_checked.length > 0) {
      checkedData = [];
    }
    dispatch(
      encuestaActions.setColaboradoresSeleccionablesChecked(checkedData)
    );
  };

  const onCheckItem = (item) => (event) => {
    dispatch(
      encuestaActions.checkColaboradoresSeleccionables({
        item: item,
        checked: event.target.checked,
      })
    );
  };

  return (
    <div className="flex flex-col gap-4 w-full p-4">
      {/* Sección: Datos Encuesta */}
      <div>
        <label className="font-bold">
          Nombre de la Encuesta <span className="text-red-500">*</span>
        </label>
        <input
          type="text"
          name="nombre"
          placeholder="Ingrese el nombre de la encuesta"
          value={encuesta.nombre}
          onChange={handleInputChange}
          className="border rounded border-zinc-200 px-2 py-3 w-full"
        />
      </div>
      <div>
        <label className="font-bold">Descripción de la Encuesta</label>
        <textarea
          name="descripcion"
          placeholder="Ingrese la descripción de la encuesta"
          value={encuesta.descripcion}
          onChange={handleInputChange}
          className="border rounded border-zinc-200 px-2 py-3 w-full h-32"
        />
      </div>
      <div className="flex flex-row justify-between gap-8">
        <div className="w-full">
          <label className="font-bold">
            Fecha de Inicio <span className="text-red-500">*</span>
          </label>
          <input
            type="datetime-local"
            name="fecha_inicio"
            value={dayjs(encuesta.fecha_inicio).format("YYYY-MM-DDTHH:mm:ss")}
            onChange={handleInputChange}
            className="border rounded border-zinc-200 px-2 py-3 w-full"
          />
        </div>
        <div className="w-full">
          <label className="font-bold">
            Fecha de Fin <span className="text-red-500">*</span>
          </label>
          <input
            type="datetime-local"
            name="fecha_fin"
            value={dayjs(encuesta.fecha_fin).format("YYYY-MM-DDTHH:mm:ss")}
            onChange={handleInputChange}
            className="border rounded border-zinc-200 px-2 py-3 w-full"
          />
        </div>
      </div>
      {/* Sección: Alcance */}
      <div className="flex flex-col gap-4">
        <div className="border-b py-4">
          <label className="font-bold">
            Alcance <span className="text-red-500">*</span>
          </label>
          <p className="text-sm text-gray-500">
            Defina quienes recibirán la encuesta para completarla
          </p>
        </div>
        {!colaboradores_asignados_loading &&
        !colaboradores_seleccionables_loading ? (
          <div>
            <div className="flex flex-col gap-1 py-4 border-b">
              <div className="flex gap-2 items-center font-bold">
                <input
                  type="radio"
                  id="alcance-completo"
                  name="alcance_total"
                  value="completo"
                  checked={encuesta.alcance_total === true}
                  onChange={handleInputChange}
                  className="border rounded border-zinc-200 px-2 py-3 w-4 h-4"
                />
                <label htmlFor="alcance">Enviar a toda la nómina actual</label>
              </div>
              <div className="flex flex-row gap-2 text-sm text-gray-500">
                <div className="w-4 h-4"></div>
                <h3 className="font-bold">Total:</h3>
                <span>{colaboradores_seleccionables_count} colaboradores</span>
              </div>
            </div>
            <div className="flex flex-row justify-between items-center border-b">
              <div className="flex flex-col gap-1 py-4">
                <div className="flex gap-2 items-center font-bold">
                  <input
                    type="radio"
                    id="alcance-parcial"
                    name="alcance_total"
                    value="parcial"
                    checked={encuesta.alcance_total === false}
                    onChange={handleInputChange}
                    className="border rounded border-zinc-200 px-2 py-3 w-4 h-4"
                  />
                  <label htmlFor="alcance">Carga manual de colaboradores</label>
                </div>
                <div className="flex flex-row gap-2 text-sm text-gray-500">
                  <div className="w-4 h-4"></div>
                  <h3 className="font-bold">Seleccionados:</h3>
                  <span>
                    {colaboradores_seleccionables_checked.length} colaboradores
                  </span>
                </div>
              </div>
              <button
                className="text-gray-700 border border-gray-500 rounded-md text-sm h-10 px-4"
                onClick={onOpenModal}
              >
                Agregar
              </button>
            </div>
          </div>
        ) : (
          <div className="flex justify-center">
            <LoadingSpinButton2 message={"Cargando, por favor espere"} />
          </div>
        )}
      </div>
      <Modal isOpen={showColaboradoresModal}>
        <div className="rounded-lg bg-white w-9/12 h-4/5 p-2 md:p-5 flex flex-col gap-5">
          <div className="modal-title">
            <div className="w-full flex justify-between items-center">
              <div className="flex items-center gap-4">
                <h2 className="font-semibold text-xl">
                  Seleccione los colaboradores
                </h2>
              </div>
              <button
                onClick={() => {
                  onCloseModal();
                }}
              >
                <X size={24} />
              </button>
            </div>
            <div className="my-4">
              <div className="flex justify-between items-center gap-4">
                <SearchInput
                  containerClass={`w-1/2`}
                  placeholder={"Buscar por nombre o cargo..."}
                  value={nombre}
                  onChange={onSearchColaborador}
                />
                {/* <MostrarPaginas
                  containerClass={"w-1/2x"}
                  onChangePageSize={onChangePageSize}
                /> */}
                <div className="text-sm">
                  <ReactSelect
                    value={{
                      value: "",
                      label: `(${colaboradores_seleccionables_checked.length}) seleccionados`,
                    }}
                    // options={actionOptions}
                    // onChange={onAction}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        width: 170, // Cambia el valor para ajustar el ancho del componente
                        minHeight: 40, // Ajusta la altura mínima
                      }),
                    }}
                  ></ReactSelect>
                </div>
                <PrimaryButton
                  className="bg-sky-500 text-white w-1/6"
                  onClick={onSubmitAddColaboradores}
                >
                  Asignar
                </PrimaryButton>
              </div>
              <div className="flex gap-4 mt-8">
                <SearchSelect
                  data={sucursales}
                  defaultText={"Sucursales: Todos"}
                  searchValue={nombreSucursal}
                  filterFunction={filterSucursales}
                  onSelectItem={(item) => {
                    dispatch(
                      encuestaActions.setColaboradoresSeleccionablesFilter({
                        ...colaboradores_seleccionables_filter,
                        sucursal: item,
                      })
                    );
                  }}
                />
                <SearchSelect
                  data={areas}
                  defaultText={"Áreas: Todas"}
                  searchValue={nombreArea}
                  filterFunction={filterAreas}
                  onSelectItem={(item) => {
                    dispatch(
                      encuestaActions.setColaboradoresSeleccionablesFilter({
                        ...colaboradores_seleccionables_filter,
                        area: item,
                      })
                    );
                  }}
                />
                <SearchSelect
                  data={departamentos}
                  defaultText={"Departamentos: Todos"}
                  searchValue={nombreDepartamento}
                  filterFunction={filterDepartamentos}
                  onSelectItem={(item) => {
                    dispatch(
                      encuestaActions.setColaboradoresSeleccionablesFilter({
                        ...colaboradores_seleccionables_filter,
                        departamento: item,
                      })
                    );
                  }}
                />
              </div>
            </div>
          </div>
          <div className="modal-body custom-scrollbar">
            <DataTable
              containerClass={"my-8x"}
              data={colaboradores_seleccionables}
              loading={colaboradores_seleccionables_loading}
              config={datatableConfig}
              noHeaders={false}
              //
              totalRecords={colaboradores_seleccionables_count}
              onPaginate={onPaginate}
              outerPageSize={PageSize}
              outerPage={page}
              outerSetPage={setPage}
              //
              checkable={true}
              onCheckAll={onCheckAll}
              checkedItems={colaboradores_seleccionables_checked}
              onItemCheck={onCheckItem}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default EncuestaDatosAlcance;
