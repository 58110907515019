import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { commonActions } from "src/modules/common/handler/redux";
import { encuestaActions } from "../handlers/redux";
import LoadingSpinButton from "src/modules/common/components/LoadingSpinButton";
import dayjs from "dayjs";
import { ArrowLeft, Download, FiletypePdf } from "react-bootstrap-icons";
import userDefault from "src/modules/common/components/images/user_default.png";
import DownloadingFileAnimation from "src/modules/common/components/DownloadingFileAnimation";
import DataTable from "src/modules/common/components/DataTable";
import EtiquetaComponent from "../components/EtiquetaComponent";
import ModalHistorialEtiquetasAsignadas from "../components/ModalHistorialEtiquetasAsignadas";

const EncuestaResumenColaboradorPage = () => {
  const { encuestaId, colaboradorId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const reporte_colaborador_resumen = useSelector(
    (state) => state.encuesta.reporte_colaborador_resumen
  );
  const reporte_colaborador_resumen_loading = useSelector(
    (state) => state.encuesta.reporte_colaborador_resumen_loading
  );
  const comprobante_colaborador_resumen_loading = useSelector(
    (state) => state.encuesta.comprobante_colaborador_resumen_loading
  );
  const {
    empleado = {},
    encuesta = {},
    respuestas = [],
  } = reporte_colaborador_resumen;
  const { preguntas = [] } = encuesta;

  const [showDescripcion, setShowDescripcion] = useState(false);
  const [showHistorialEtiquetasAsignadas, setShowHistorialEtiquetasAsignadas] =
    useState(false);

  useEffect(() => {
    dispatch(commonActions.setTitle("Encuesta"));
    dispatch(commonActions.showSidebar(false));
    dispatch(commonActions.setTitleMenu([]));
    if (encuestaId && colaboradorId) {
      dispatch(
        encuestaActions.getReporteColaboradorResumen({
          encuestaId,
          colaboradorId,
        })
      );
      dispatch(
        encuestaActions.getHistorialEtiquetasAsignadas({
          encuestaId,
          colaboradorId,
        })
      );
    }
  }, []);

  const getRespuesta = (preguntaId, name) => {
    if (respuestas.length === 0) return null;
    const respuesta = respuestas.find((r) => r.pregunta === preguntaId);
    if (name === "respuesta_fecha") {
      return respuesta
        ? respuesta[name]
          ? dayjs(respuesta[name]).format("YYYY-MM-DDTHH:mm")
          : null
        : null;
    }
    return respuesta ? respuesta[name] : null;
  };

  const isOpcionSeleccionada = (preguntaId, opcionId, name) => {
    const opcionesSeleccionadas = getRespuesta(preguntaId, name);
    return opcionesSeleccionadas
      ? opcionesSeleccionadas.includes(opcionId)
      : false;
  };

  const onDownloadReport = () => {
    console.log(encuestaId, colaboradorId);
    if (encuestaId && colaboradorId) {
      dispatch(
        encuestaActions.descargarComprobanteRespuestasColaborador({
          encuestaId,
          colaboradorId,
        })
      );
    }
  };

  return (
    <>
      <div className="relative flex justify-center">
        {/* Botón en la esquina superior izquierda */}
        <button
          className="absolute top-6 left-0"
          onClick={() => navigate(`/encuesta/${encuestaId}/reporte/resumen`)}
        >
          <ArrowLeft size={24} />
        </button>
        {!reporte_colaborador_resumen_loading && (
          <div className="flex flex-row gap-4 p-4 w-4/5">
            {/* Datos de la encuesta con las respuestas del colaborador */}
            <div className="w-8/12">
              <div className="mx-4">
                <h1 className="text-xl font-bold">{encuesta.nombre}</h1>
                <span
                  className="text-sm text-gray-500 cursor-pointer hover:text-gray-700 font-bold"
                  onClick={() => setShowDescripcion(!showDescripcion)}
                >
                  {showDescripcion
                    ? "Ocultar descripción"
                    : "Mostrar descripción"}
                </span>
                {showDescripcion && (
                  <p className="flex whitespace-pre-wrap m-2 text-sm">{encuesta.descripcion}</p>
                )}
                <div className="border-b m-2"></div>
              </div>
              {preguntas.map((pregunta, index) => (
                <div
                  key={pregunta.id}
                  className="border rounded-2xl p-4 mb-4 shadow-sm"
                >
                  <h2 className="text-sm font-semibold mb-2">
                    Pregunta {index + 1}
                  </h2>
                  <p className="mb-2">{pregunta.texto}</p>
                  {(pregunta.tipo_respuesta === "SIMPLE" ||
                    pregunta.tipo_respuesta === "MULTIPLE") && (
                    <div className="flex flex-col space-y-2">
                      <label className="text-gray-700 font-semibold">
                        {pregunta.tipo_respuesta === "MULTIPLE"
                          ? "Selecciona las opciones de respuesta"
                          : "Selecciona una opcion de respuesta"}
                      </label>
                      {pregunta.opciones.map((opcion) => (
                        <div
                          key={opcion.id}
                          className="flex items-center space-x-2"
                        >
                          <input
                            type={
                              pregunta.tipo_respuesta === "MULTIPLE"
                                ? "checkbox"
                                : "radio"
                            }
                            id={`opcion-${opcion.id}`}
                            name={`pregunta-${pregunta.id}`}
                            checked={isOpcionSeleccionada(
                              pregunta.id,
                              opcion.id,
                              "respuesta_opciones"
                            )}
                            className="w-4 h-4"
                            disabled={true}
                          />
                          <label
                            htmlFor={`opcion-${opcion.id}`}
                            className="text-gray-700"
                          >
                            {opcion.texto}
                          </label>
                        </div>
                      ))}
                    </div>
                  )}

                  {pregunta.tipo_respuesta === "TEXTO" && (
                    <div className="flex flex-col space-y-2 w-full">
                      <div className="border rounded border-zinc-200 px-2 py-3 w-full whitespace-pre-line">
                        {getRespuesta(pregunta.id, "respuesta_texto")}
                      </div>
                    </div>
                  )}

                  {pregunta.tipo_respuesta === "FECHA" && (
                    <div className="flex flex-col space-y-2 w-1/2">
                      <label className="text-gray-700 font-semibold">
                        Selecciona una fecha
                      </label>
                      <input
                        type="datetime-local"
                        name={`pregunta-${pregunta.id}`}
                        value={getRespuesta(pregunta.id, "respuesta_fecha")}
                        className="border rounded px-2 py-2 w-full"
                        disabled={true}
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
            {/* Datos del colaborador */}
            <div className="w-4/12">
              <h1 className="text-xl font-bold border-b pb-2">Colaborador</h1>
              <div className="my-4 flex flex-row gap-2 items-center rounded bg-gray-50">
                <img
                  src={empleado.foto_empleado || userDefault}
                  className="w-20 h-20 m-1 rounded"
                  alt=""
                />
                <div className="flex flex-col gap-2 justify-around">
                  <p className="font-semibold">
                    {empleado.nombre} {empleado.apellido}
                  </p>
                  <p>
                    Cargo:{" "}
                    {empleado.cargo ? empleado.cargo.nombre : "Sin cargo"}
                  </p>
                </div>
              </div>
              <div className="flex justify-center w-full">
                {!comprobante_colaborador_resumen_loading && (
                  <button
                    onClick={onDownloadReport}
                    className="flex items-center gap-2 border rounded-md py-2 px-3 border-gray-500 text-sm"
                  >
                    <FiletypePdf size={21} />
                    <span className="font-semibold text-sm">
                      Descargar respuestas
                    </span>
                  </button>
                )}
                {comprobante_colaborador_resumen_loading && (
                  <DownloadingFileAnimation />
                )}
              </div>
              <div className="flex justify-center w-full my-8">
                <span
                  className="text-sm text-sky-500 cursor-pointer hover:text-sky-700 font-bold"
                  onClick={() =>
                    setShowHistorialEtiquetasAsignadas(
                      !showHistorialEtiquetasAsignadas
                    )
                  }
                >
                  {showHistorialEtiquetasAsignadas
                    ? "Ocultar"
                    : "Mostrar historial de etiquetas"}
                </span>
              </div>
            </div>
          </div>
        )}
        {reporte_colaborador_resumen_loading && (
          <div className="flex justify-center items-center h-[500px]">
            <LoadingSpinButton />
          </div>
        )}
        {/* Mostrar historial de etiquetas asignadas */}
        <ModalHistorialEtiquetasAsignadas
          encuestaId={encuestaId}
          colaboradorId={colaboradorId}
          show={showHistorialEtiquetasAsignadas}
          onClose={() => setShowHistorialEtiquetasAsignadas(false)}
        />
      </div>
    </>
  );
};

export default EncuestaResumenColaboradorPage;
