import React, { useEffect, useState } from "react";
import {
  CaretDownFill,
  ChevronDown,
  ChevronRight,
  FiletypeXls,
  ThreeDotsVertical,
  X,
} from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { BuscarInput } from "src/modules/common/components/BuscarInput";
import { CheckInput } from "src/modules/common/components/CheckInput";
import { ErrorToast } from "src/modules/common/components/ErrorToast";
import { ProfileInfoCard } from "../../common/components/ProfileInfoCard";
import { evaluacionActions } from "../handlers/redux";
import {
  DescargarInforme,
  ESTADOS_EVALUACION,
  ESTADOS_FORMULARIO,
} from "../helpers/helpers";
import AreaEvaluacionSelect from "./AreaEvaluacionSelect";
import DepartamentoEvaluacionSelect from "./DepartamentoEvaluacionSelect";
import EvaluacionColaboradoresTableHeader from "./EvaluacionColaboradoresTableHeader";
import SucursalEvaluacionSelect from "./SucursalEvaluacionSelect";
import Dropdown from "react-multilevel-dropdown";
import { getToken } from "src/utils/helpers";

const renderFilters = () => {
  return (
    <>
      <AreaEvaluacionSelect></AreaEvaluacionSelect>
      <DepartamentoEvaluacionSelect></DepartamentoEvaluacionSelect>
      <SucursalEvaluacionSelect></SucursalEvaluacionSelect>
    </>
  );
};

const ReiniciarItem = ({
  onSubmit,
  extraClass,
  activeRowNumberMenuHandler,
  setMostrarReiniciar,
  setMostrarReiniciarMasivo,
}) => {
  const [checkedItems, setCheckedItems] = useState([]);
  const options = [
    { id: ESTADOS_FORMULARIO.ENVIADO, label: "Envío" },
    { id: ESTADOS_FORMULARIO.EVALUADO, label: "Evaluación" },
    { id: ESTADOS_FORMULARIO.CALIBRADO, label: "Calibración" },
    { id: ESTADOS_FORMULARIO.DEVUELTO, label: "Devolución" },
    { id: ESTADOS_FORMULARIO.COMENTADO, label: "Comentario" },
  ];
  const onClick = (id) => (e) => {
    e.stopPropagation();
    let checkedItems = options.filter((o, i) => o.id >= id);
    setCheckedItems(checkedItems);
  };
  const onSubmitLocal = () => {
    let deseaReiniciar = window.confirm(
      "¿Estás seguro de reiniciar los pasos seleccionados? Se perderán los datos actuales."
    );
    if (deseaReiniciar) {
      if (checkedItems.length < 1) {
        ErrorToast({ message: "Debe elegir al menos una etapa" });
        return;
      }
      if (onSubmit) {
        const etapas = checkedItems.map((ci, i) => ci.id);
        onSubmit(etapas);
      }
      setMostrarReiniciarMasivo(null);
    }
  };
  const onClose = () => {
    if (setMostrarReiniciarMasivo) {
      setMostrarReiniciarMasivo(null);
    } else if (setMostrarReiniciar) {
      setMostrarReiniciar(null);
    }
  };
  //
  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className={`bg-white z-50 border rounded-md p-4 ${extraClass}`}
    >
      <div className="flex justify-end items-end w-full">
        <button onClick={onClose}>
          <X size={18} />
        </button>
      </div>
      <ul className="">
        {options.map((option, idx) => (
          <li key={`li-a-${idx}`} className="p-2">
            <CheckInput
              checked={checkedItems.find((ci, ciIdx) => ci.id === option.id)}
              onClick={onClick(option.id)}
            ></CheckInput>{" "}
            {option.label}
          </li>
        ))}
      </ul>
      <button
        onClick={onSubmitLocal}
        className="bg-sky-500 p-2 rounded-md text-white"
      >
        Reiniciar ahora
      </button>
    </div>
  );
};

const FormularioRowItem = ({
  item,
  className = "",
  tieneCalibracion,
  tieneDevolucion,
  tieneComentarios,
  idEvaluacion,
  navigate,
  goToDetail,
  activeRowNumberMenu,
  firstCellIndex,
  formularios_checked,
  dispatch,
  hideDropDownMenuHandler,
  dropdownActions,
  activeRowNumberMenuHandler,
  evaluacionEstaEnCurso,
  mostrarReiniciar,
  setMostrarReiniciar,
  reiniciarFormulario,
  colActions,
  hayCalibracion,
  setMostrarReiniciarMasivo,
  esAdminParcial,
}) => {
  const getRelacionColor = (relacion) => {
    let relacionColor = "bg-slate-200 text-black";
    const colores = {
      "Jefe directo": "bg-red-500",
      Par: "bg-orange-400",
      Subalterno: "bg-sky-500",
      Autoevaluación: "bg-black",
    };
    if (colores[relacion]) return colores[relacion];
    return relacionColor;
  };
  const EvaluarButton = ({ formulario }) => {
    const { evaluador, configuracion } = formulario;
    if (!evaluador || !configuracion) {
      return (
        <button
          disabled={true}
          className="py-1 px-2 border rounded-md border-slate-400 text-slate-400 text-sm"
        >
          Incompleto
        </button>
      );
    }
    let label = "Evaluar";
    let action = "evaluacion";
    const labels = [
      "Evaluar", //Pendiente
      "Evaluar", //Enviado
      "Calibrar", //Evaluado
      "Dar devolución", //Calibrado
      "Comentar", //Devuelto
      "Finalizado", //Comentado
    ];
    const actions = [
      "evaluacion", //Pendiente
      "evaluacion", //Enviado
      "calibracion", //Evaluado
      "devolucion", //Calibrado
      "comentario", //Devuelto
      "finalizado", //Comentado
    ];
    const pasosBool = [true, true];
    const { calibrador, relacion, estado } = formulario;
    pasosBool.push(tieneCalibracion && !!calibrador);
    pasosBool.push(tieneDevolucion);
    pasosBool.push(tieneComentarios);
    labels.push("Finalizado");
    //
    if (relacion === "Autoevaluación") {
      if (estado.value > 1) {
        label = "Finalizado";
        action = "finalizado";
      }
    } else if (relacion === "Par" || relacion === "Subalterno") {
      let pasosIndex = estado.value;
      if (pasosIndex === ESTADOS_FORMULARIO.CONFIRMADO) {
        label = "Finalizado";
        action = "finalizado";
      } else {
        for (let index = pasosIndex; index < pasosBool.length; index++) {
          let paso = pasosBool[index];
          // Para Pares y Subalternos no corresponde la etapa Devolución
          if (paso && index !== 3) {
            label = labels[index];
            action = actions[index];
            break;
          }
        }
      }
    } else {
      // Es igual a Jefe directo
      let pasosIndex = estado.value;
      if (pasosIndex === ESTADOS_FORMULARIO.CONFIRMADO) {
        label = "Finalizado";
        action = "finalizado";
      } else {
        //
        for (let index = pasosIndex; index < pasosBool.length; index++) {
          let paso = pasosBool[index];
          if (paso) {
            label = labels[index];
            action = actions[index];
            break;
          }
        }
      }
    }
    //
    const btnClass = "py-1 px-2 border rounded-md border-black text-sm";
    const evaluarRoute = `/evaluaciones/${idEvaluacion}/formulario/${formulario.id}/${action}`;
    //
    return (
      <button className={btnClass} onClick={() => navigate(evaluarRoute)}>
        {label}
      </button>
    );
  };
  const getEstadoLabel = (label) => {
    let estadoLabel = label;
    if (label === "Pendiente") {
      estadoLabel = "Sin enviar";
    } else if (label === "Devuelto") {
      estadoLabel = "Devolución realizada";
    } else if (label === "Confirmado") {
      estadoLabel = "Finalizado";
    }
    return estadoLabel;
  };
  //
  const tableCellClass =
    "table-cell borderx p-4 align-middle text-center min-w-[150px]";
  return (
    <div
      className={"table-row hover:bg-sky-100 hover:cursor-pointerx" + className}
      onClick={() => goToDetail(item)}
    >
      {/* FIXED COL: Colaborador */}
      <div
        className={`${item.id !== activeRowNumberMenu && firstCellIndex ? "-z-10x" : "z-20"
          } table-cell hover:bg-sky-100 align-middle min-w-80 bg-white sticky left-0 border-red-400x p-4`}
      >
        <div className="flex items-center gap-2">
          <CheckInput
            checked={formularios_checked.includes(item.id)}
            onClick={(event) => event.stopPropagation()}
            onChange={(event) => {
              dispatch(
                evaluacionActions.checkFormulario({
                  formulario: item,
                  checked: event.target.checked,
                })
              );
            }}
          />
          <div className="flex items-center w-56 relative">
            <ProfileInfoCard
              key={"col-" + item.id}
              image={item.evaluado.foto_empleado}
              name={
                item.id
                  ? `${item.evaluado.nombre} ${item.evaluado.apellido}`
                  : "Sin especificar"
              }
            >
              <div className="text-sm w-36 whitespace-nowrap text-ellipsis overflow-hidden">
                {item.evaluado?.cargo?.nombre || "Sin asignar"}
              </div>
            </ProfileInfoCard>
            <div className="w-6 flex justify-center">
              <button onClick={hideDropDownMenuHandler(item.id)}>
                <ThreeDotsVertical size={18} />
              </button>
            </div>
          </div>
        </div>
        {/* dropdown menu */}
        {!isNaN(item.id) && item.id === activeRowNumberMenu && (
          <div className="ddmenu flex content-center absolute top-10 right-[-158px] border border-slate-300 rounded-md bg-white z-50">
            <ul className="flex flex-col content-center items-start text-left m-0 gap-2x text-md font-semibold">
              {!!dropdownActions &&
                dropdownActions(item.id, item).map((item, idx) => (
                  <li
                    key={`li-${idx}`}
                    className={`${item.className || ""
                      } hover:bg-blue-200 w-full`}
                  >
                    <button
                      className={`z-50 flex w-full px-4 py-3 ${!!item.disabled ? "text-slate-500" : ""
                        }`}
                      disabled={item.disabled || false}
                      onClick={(e) => {
                        e.stopPropagation();
                        activeRowNumberMenuHandler(null);
                        item.action();
                      }}
                    >
                      <span>{item.label}</span>
                    </button>
                  </li>
                ))}
              {/* {!!dropdownActions && (
                <DropDownMenuSingle
                  options={dropdownActions(item.id, item)}
                />
              )} */}
              {/* CUSTOM ACTIONS */}
              <li
                key={`li-${dropdownActions.length}`}
                className={`${item.className || ""} hover:bg-blue-200 w-full`}
              >
                <button
                  className={`flex w-full px-4 py-3 ${!evaluacionEstaEnCurso() ? "text-slate-400" : ""
                    }`}
                  disabled={!evaluacionEstaEnCurso()}
                  onClick={(e) => {
                    e.stopPropagation();
                    mostrarReiniciar
                      ? setMostrarReiniciar(null)
                      : setMostrarReiniciar(item.id);
                  }}
                >
                  <span>Reiniciar</span>
                </button>
              </li>
            </ul>
            {/*  */}
            {mostrarReiniciar === item.id && (
              <ReiniciarItem
                extraClass={"absolute top-1/4 right-[-180px]"}
                onSubmit={reiniciarFormulario(item.id)}
                setMostrarReiniciar={setMostrarReiniciar}
              ></ReiniciarItem>
            )}
            {/*  */}
          </div>
        )}
      </div>
      {/* EVALUADOR */}
      <div className="table-cell align-middle p-4 min-w-80">
        <div
          onClick={() => colActions[0](item)}
          className={`flex items-center justify-center hover:cursor-pointer`}
        >
          {!!item.evaluador && (
            <div className="">
              <ProfileInfoCard
                key={"evaluador-" + item.evaluador.id}
                image={item.evaluador.foto_empleado}
                name={
                  item.evaluador?.id
                    ? `${item.evaluador.nombre} ${item.evaluador?.apellido}`
                    : "Sin especificar"
                }
              >
                <div
                  title={item.evaluador?.cargo?.nombre || "Sin asignar"}
                  className="text-sm w-36 whitespace-nowrap text-ellipsis overflow-hidden"
                >
                  {item.evaluador?.cargo?.nombre || "Sin asignar"}
                </div>
              </ProfileInfoCard>
            </div>
          )}
          {!item.evaluador && (
            <button
              onClick={() => colActions[0](item)}
              className="flex justify-center items-center w-full text-sky-500 font-semibold "
            >
              Asignar
            </button>
          )}
        </div>
      </div>
      {/* RELACION */}
      <div className={`${tableCellClass} `}>
        <div
          className={`p-4 text-centerx relative ${activeRowNumberMenu !== null ? "-z-10" : "z-0"
            }`}
        >
          <span
            className={`${getRelacionColor(
              item.relacion
            )} p-1 rounded-md text-white text-sm`}
          >
            {item.relacion || "Sin asignar"}
          </span>
        </div>
      </div>
      {/* CALIBRADOR */}
      {!!hayCalibracion && (
        <div className="table-cell align-middle p-4 min-w-80">
          <div className="flex items-center relative ">
            {!!item.calibrador && (
              <div className="">
                <ProfileInfoCard
                  key={"calibrador-" + item.calibrador.id}
                  image={item.calibrador.foto_empleado}
                  name={
                    item.calibrador?.id
                      ? `${item.calibrador.nombre} ${item.calibrador?.apellido}`
                      : "Sin especificar"
                  }
                >
                  <div className="text-sm">
                    {item.calibrador?.cargo?.nombre || "Sin asignar"}
                  </div>
                </ProfileInfoCard>
              </div>
            )}
            {!item.calibrador && item.relacion === "Jefe directo" && (
              <button
                onClick={() => colActions[1](item)}
                className="flex justify-center items-center w-full text-sky-500 font-semibold "
              >
                Asignar
              </button>
            )}
            {!item.calibrador && item.relacion !== "Jefe directo" && (
              <div className="flex justify-center items-center w-full">
                Sin calibración
              </div>
            )}
          </div>
        </div>
      )}
      {/* FORMULARIO */}
      <div className={`${tableCellClass} `}>
        <div className="p-4 text-center relative ">
          {!!item.configuracion && item.configuracion?.nombre}
          {!item.configuracion && (
            <button
              onClick={() => colActions[2](item)}
              className="text-sky-500 font-semibold"
            >
              Asignar
            </button>
          )}
        </div>
      </div>
      {/* PUNTAJE OBTENIDO */}
      {!esAdminParcial && (
        <div className={`${tableCellClass}`}>
          <div className="p-4 text-center relative">
            {item.puntaje_obtenido.value === "-" ? (
              <>{item.puntaje_obtenido.label}</>
            ) : (
              <>{item.puntaje_obtenido.value}% - {item.puntaje_obtenido.label}</>
            )}
          </div>
        </div>
      )}
      {/* ESTADO */}
      <div className={`${tableCellClass}`}>
        <div className="p-4 text-center">
          {getEstadoLabel(item.estado?.label)}
          {/* {item.estado?.label === "Devuelto"
            ? "Devolución realizada"
            : item.estado?.label} */}
        </div>
      </div>
      {/* BOTON ACCION */}
      {!esAdminParcial && (
        <div className={`${tableCellClass}`}>
          <EvaluarButton formulario={item}></EvaluarButton>
        </div>
      )}
    </div>
  );
};

const ReiniciarMenu = ({
  mostrarReiniciarMasivo,
  reiniciarFormularios,
  setMostrarReiniciarMasivo,
}) => {
  //
  return (
    <div className="relativex">
      <div className="absolute -right-40x  -bottom-80x z-50">
        <ReiniciarItem
          onSubmit={(etapas) => {
            reiniciarFormularios(etapas);
            // reiniciarFormulariosLocal();
            setMostrarReiniciarMasivo(false);
          }}
          setMostrarReiniciarMasivo={setMostrarReiniciarMasivo}
        ></ReiniciarItem>
      </div>
    </div>
  );
};
//
const DropDownMenuMasivo = ({
  title,
  headerDropdownOptions,
  toggleReiniciarMasivo,
}) => {
  return (
    <div className="flex items-center gap-1 border rounded-md p-2">
      <Dropdown title={title} className="inline-grid z-40">
        {headerDropdownOptions.map((item, idx) => {
          const onClickHandler = (e) => {
            if (item.label === "Reiniciar") {
              console.log("Reiniciar");
              toggleReiniciarMasivo();
              return;
            }
            if (!item.items) {
              item.action();
            }
          };
          return (
            <Dropdown.Item
              key={`ddi-${idx}`}
              isDisabled={item.isDisabled ?? false}
              className={item.className || ""}
              onClick={onClickHandler}
            >
              <div className="relative w-full">
                <span className={item.labelClass || ""}>{item.label}</span>
                {item.items?.length > 0 && (
                  <span className="absolute right-0">{">"}</span>
                )}
              </div>
              {item.items?.length > 0 && (
                <Dropdown.Submenu>
                  {item.items?.map((subItem, sIdx) => (
                    <Dropdown.Item
                      key={`ddsi-${idx}-${sIdx}`}
                      onClick={() => subItem.action()}
                    >
                      {subItem.label}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Submenu>
              )}
            </Dropdown.Item>
          );
        })}
      </Dropdown>
      <ChevronDown size={12}></ChevronDown>
    </div>
  );
};

const EvaluacionColaboradoresTable = React.memo(function ({
  data = [],
  headers = [],
  hayCalibracion = true,
  headerDropdownOptions = [],
  headerDropdownActions = {},
  colActions = [],
  actionButtonHandler = null,
  actionButtonLabel = "Agregar",
  extraClass = "",
  dropdownActions,
  loading = false,
  extraData = {},
  onPaginate,
  totalPaginate = 1,
  esAdminParcial = false,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = getToken();
  const params = useParams();

  const { idEvaluacion: evaluacionId } = params;
  //
  const [checkAll, setCheckAll] = useState(false);
  const [firstCellIndex, setFirstCellIndex] = useState(false);
  const [activeRowNumberMenu, setActiveRowNumberMenu] = useState(null);
  const [mostrarReiniciar, setMostrarReiniciar] = useState(null);
  const { idEvaluacion, tieneCalibracion, tieneDevolucion, tieneComentarios } =
    extraData;
  const evaluacion = useSelector((state) => state.evaluacion.evaluacion);
  const formularios_filter = useSelector(
    (state) => state.evaluacion.formularios_filter
  );
  const { nombre = "" } = formularios_filter;
  // Pagination
  const [Page, setPage] = useState(1);
  const [PageSize, setPageSize] = useState(100);
  const [PagesButtonsArray, setPagesButtonsArray] = useState([1]);
  const evaluacionEstaEnCurso = () => {
    return evaluacion?.estado.value === ESTADOS_EVALUACION.EN_CURSO;
  };
  const activeRowNumberMenuHandler = (rowNumber) => {
    activeRowNumberMenu === rowNumber
      ? setActiveRowNumberMenu(null)
      : setActiveRowNumberMenu(rowNumber);
    setMostrarReiniciar(null);
  };
  const hideDropDownMenuHandler = (rowNumber) => (e) => {
    e.stopPropagation();
    activeRowNumberMenuHandler(rowNumber);
    setMostrarReiniciar(null);
  };
  const onTableClick = (e) => {
    e.stopPropagation();
    activeRowNumberMenuHandler(null);
    setMostrarReiniciar(null);
    // setMostrarReiniciarMasivo(false);
  };

  const positionRef = React.useRef(0);

  const onTableScroll = (e) => {
    const x = e.currentTarget.scrollLeft;
    if (x !== positionRef.current) {
      // !!process.env.REACT_APP_DEBUG && console.log("Horizontal scroll event occurred ...");
      positionRef.current = x;
      activeRowNumberMenuHandler(null);
    }
  };

  const formularios_checked = useSelector(
    (state) => state.evaluacion.formularios_checked
  );

  const goToDetail = (colaborador) => {
    // navigate(`/nomina/colaborador/${colaborador.id}`);
  };

  const reiniciarFormulario = (formularioId) => (etapas) => {
    const payload = {
      formularioId: formularioId,
      etapas: etapas,
    };
    !!process.env.REACT_APP_DEBUG &&
      console.log("reiniciarFormulario", payload);
    dispatch(evaluacionActions.reiniciarFormulario(payload));
  };

  const reiniciarFormularios = (etapas) => {
    !!process.env.REACT_APP_DEBUG &&
      console.log("reiniciarFormularios etapas", etapas);
    if (formularios_checked.length < 1) {
      ErrorToast({ message: "Debe seleccionar al menos un formulario." });
      return;
    }
    //
    !!process.env.REACT_APP_DEBUG &&
      console.log("reiniciarFormulariosss", etapas);
    dispatch(evaluacionActions.reiniciarFormularios(etapas));
  };

  const onChangeText = (event) => {
    console.log("### onChangeText");
    dispatch(
      evaluacionActions.setFormulariosFilter({
        id: idEvaluacion,
        nombre: event.target.value,
      })
    );
  };

  const selectAllChange = (event) => {
    data.map((item, idx) =>
      dispatch(
        evaluacionActions.checkFormulario({
          formulario: item,
          checked: event.target.checked,
        })
      )
    );
  };

  const [mostrarReiniciarMasivo, setMostrarReiniciarMasivo] = useState(false);
  const toggleReiniciarMasivo = () => {
    setMostrarReiniciarMasivo(!mostrarReiniciarMasivo);
  };

  // Pagination
  const onChangePageSize = (e) => {
    const value = e.target.value;
    setPageSize(value);
    setPage(1);
  };
  const getPageButtonsArray = (pagesCount) => {
    let pagesArr = [1];
    const pagesCountCeil = Math.ceil(pagesCount);
    for (let index = 2; index <= pagesCountCeil; index++) {
      pagesArr.push(index);
    }
    return pagesArr;
  };
  const [descargarInformeLoading, setDescargarInformeLoading] = useState(false);
  const onFinishDescargarLoading = () => {
    setDescargarInformeLoading(false);
  };
  const onDescargarInforme = () => {
    setDescargarInformeLoading(true);
    DescargarInforme(evaluacionId, token, onFinishDescargarLoading);
  };
  //
  useEffect(() => {
    setFirstCellIndex(!!activeRowNumberMenu);
  }, [activeRowNumberMenu]);

  useEffect(() => {
    let isAllChecked =
      formularios_checked.length === data.length && data.length > 0;
    setCheckAll(isAllChecked);
  }, [formularios_checked, data]);

  useEffect(() => {
    if (!!onPaginate && !!Page && !!PageSize && !!totalPaginate) {
      // TODO refactor this...
      onPaginate(Page, PageSize);
      setPagesButtonsArray(getPageButtonsArray(totalPaginate / PageSize));
    }
  }, [Page, PageSize, totalPaginate]);

  return (
    <div
      className={`flex flex-col gap-8 ${extraClass}`}
      onClick={onTableClick}
      onScroll={onTableScroll}
    >
      {/* FILTERS */}
      <div className="flex gap-4 w-full">{renderFilters()}</div>
      {/* SEARCH BAR AND ACTIONS */}
      <div>
        <div className="flex items-center justify-between w-full relative">
          <div className="flex w-1/2 gap-4">
            <BuscarInput
              onChange={onChangeText}
              value={nombre}
              placeholder={"Buscar por nombre o cargo del colaborador"}
            ></BuscarInput>
            <DropDownMenuMasivo
              title={`(${formularios_checked.length}) seleccionados`}
              {...{ headerDropdownOptions, toggleReiniciarMasivo }}
            ></DropDownMenuMasivo>
            {!!mostrarReiniciarMasivo && (
              <ReiniciarMenu
                {...{ reiniciarFormularios, setMostrarReiniciarMasivo }}
              />
            )}
          </div>
          {/*  */}
          <div className="flex gap-2 items-center">
            <label className="font-semibold" htmlFor="pageSize">
              Mostrar
            </label>
            <select
              name="pageSize"
              id="pageSize"
              className="border rounded-md p-2"
              onChange={onChangePageSize}
            >
              <option value="10">10</option>
              <option value="50">50</option>
              <option value="100" selected>
                100
              </option>
              <option value="200">200</option>
              <option value="500">500</option>
              <option value="1000">1000</option>
            </select>
          </div>
          {/*  */}
          <div className="flex items-center gap-4">
            <div>
              {!descargarInformeLoading && (
                <button
                  onClick={onDescargarInforme}
                  className="flex items-center gap-2 border rounded-md py-2 px-3 border-gray-500 text-sm"
                >
                  <FiletypeXls size={21} />
                  <span className="font-semibold text-sm">
                    Descargar reporte
                  </span>
                </button>
              )}
              {!!descargarInformeLoading && (
                <button
                  disabled={true}
                  className="flex items-center gap-2 bg-sky-400 text-white px-3 py-2 rounded animated-pulse"
                >
                  <span className="font-semibold text-sm">Descargando...</span>
                </button>
              )}
            </div>
            {!!actionButtonHandler && (
              <button
                onClick={actionButtonHandler}
                className="h-10 bg-sky-500 text-white text-sm px-4 rounded font-semibold"
              >
                {actionButtonLabel}
              </button>
            )}
            {!actionButtonHandler && (
              <button
                disabled={true}
                className="h-10 bg-slate-400 text-white text-sm px-4 rounded font-semibold"
              >
                {actionButtonLabel}
              </button>
            )}
          </div>
        </div>
      </div>
      {/* ========================== TABLE ======================== */}
      <div
        onScroll={onTableScroll}
        className="table-container relativex custom-scrollbar overflow-y-scrollx overflow-auto max-h-[780px]"
      >
        <div
          className={`${data.length < 1 ? "" : "table"
            } h-1/2 overflow-x-scrollx overflow-autox w-fullx border rounded-md`}
        >
          <div className="table-header-group font-bold text-sm">
            {/* HEADERS */}
            {headers.length > 0 && (
              <div className="table-row">
                <div className="table-cell min-w-80 bg-white z-40 sticky top-0 left-0 border-b border-red-400x py-3 px-4">
                  <CheckInput checked={checkAll} onChange={selectAllChange} />
                  <span className="ml-2">{headers[0].text}</span>
                </div>
                {headers.map(
                  (header, idx) =>
                    idx !== 0 && (
                      <div
                        key={`header-${idx}`}
                        className="table-cell border-b min-w-80 bg-white z-30 sticky top-0 py-3 px-4"
                      >
                        <EvaluacionColaboradoresTableHeader
                          text={header.text}
                          onOrder={header.onOrder}
                          onFilter={header.onFilter}
                        ></EvaluacionColaboradoresTableHeader>
                      </div>
                    )
                )}
              </div>
            )}
          </div>
          {/* DATA */}
          {!!loading && (
            <div className="table-row-group animate-pulse">
              {[1, 2, 3].map((item, idx) => (
                <div key={`h-l-${idx}`} className="table-row">
                  {headers.map((h, i) => (
                    <div
                      key={`h-l-i-${i}`}
                      className="table-cell p-4 min-w-80 min-h-[88px]"
                    >
                      <div className="h-10 w-80">
                        <div className="bg-slate-200 rounded-md h-2 w-1/2 mt-2"></div>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          )}
          {!loading && data.length > 0 && (
            <div className="table-row-group">
              {data.map((item, idx) => (
                <FormularioRowItem
                  key={`fri-${idx}`}
                  item={item}
                  esAdminParcial={esAdminParcial}
                  {...{
                    tieneCalibracion,
                    tieneDevolucion,
                    tieneComentarios,
                    idEvaluacion,
                    navigate,
                    goToDetail,
                    activeRowNumberMenu,
                    firstCellIndex,
                    formularios_checked,
                    dispatch,
                    hideDropDownMenuHandler,
                    dropdownActions,
                    activeRowNumberMenuHandler,
                    evaluacionEstaEnCurso,
                    mostrarReiniciar,
                    setMostrarReiniciar,
                    reiniciarFormulario,
                    colActions,
                    hayCalibracion,
                    setMostrarReiniciarMasivo,
                  }}
                ></FormularioRowItem>
              ))}
              {/* workaround provisorio para que no se pierda height de las rows */}
              {data.length < 4 &&
                [1, 2, 3, 4, 5, 6, 7, 8].map((r, i) => (
                  <div key={`prov-${i}`} className="table-row opacity-0">
                    {r}
                  </div>
                ))}
            </div>
          )}
          {!loading && data.length < 1 && (
            <div className="flex justify-center w-full mt-8">
              <div className="table-cellx">
                No existen registros para mostrar.
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="flex items-center gap-2 justify-between border-t-2 border-dashed pt-2">
        <div>{`Mostrando ${Page} de ${PagesButtonsArray.length} de un total de ${totalPaginate} registros`}</div>
        <div className="flex justify-end gap-0 rounded-sm border">
          {PagesButtonsArray.map((b, bidx) => (
            <button
              onClick={() => setPage(b)}
              className={`${b === Page
                ? "bg-sky-500 text-white"
                : "hover:bg-sky-500 hover:text-white"
                } py-1 px-2`}
            >
              {b}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
});
export default EvaluacionColaboradoresTable;
