import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ReactSelect from "react-select";
import { TwitterPicker } from "react-color";
import { Filter, FiletypeXls } from "react-bootstrap-icons";
import EncuestaReportePage from "./EncuestaReportePage";
import { encuestaActions } from "../handlers/redux";
import DataTable from "src/modules/common/components/DataTable";
import { buildDatatableConfig } from "../helpers/GenerateDataTableConfig";
import MostrarPaginas from "src/modules/common/components/MostrarPaginas";
import Modal from "src/modules/common/components/Modal";
import SearchInput from "src/modules/remuneracion/components/SearchInput";
import Input from "src/modules/common/components/Input";
import EtiquetaComponent from "../components/EtiquetaComponent";
import { ErrorToast } from "src/modules/common/components/ErrorToast";
import LoadingSpinButton from "src/modules/common/components/LoadingSpinButton";
import DownloadingFileAnimation from "src/modules/common/components/DownloadingFileAnimation";

const EncuestaReporteResumenPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const store = useStore();
  const { encuestaId } = useParams();

  const encuesta = useSelector((state) => state.encuesta.encuesta);
  const encuesta_loading = useSelector(
    (state) => state.encuesta.encuesta_loading
  );
  const reporte_resumen = useSelector(
    (state) => state.encuesta.reporte_resumen
  );
  const reporte_resumen_loading = useSelector(
    (state) => state.encuesta.reporte_resumen_loading
  );
  const reporte_resumen_count = useSelector(
    (state) => state.encuesta.reporte_resumen_count
  );
  const reporte_resumen_filter = useSelector(
    (state) => state.encuesta.reporte_resumen_filter
  );
  const reporte_resumen_checked = useSelector(
    (state) => state.encuesta.reporte_resumen_checked
  );
  const reporte_resumen_downloading = useSelector(
    (state) => state.encuesta.reporte_resumen_downloading
  );
  const { etiquetas = [], preguntas = [] } = encuesta;
  const { nombre = "" } = reporte_resumen_filter;

  const [preguntasFiltradas, setPreguntasFiltradas] = useState(preguntas);
  const [showEtiquetasModal, setShowEtiquetasModal] = useState(false);
  const [asignacionActual, setAsignacionActual] = useState({});
  const [etiquetasSeleccionadas, setEtiquetasSeleccionadas] = useState([]);
  const [showCreateEtiquetaModal, setShowCreateEtiquetaModal] = useState(false);
  const [etiquetaColor, setEtiquetaColor] = useState("#ffffff");
  const [nombreEtiqueta, setNombreEtiqueta] = useState("");
  const [showPreguntasModal, setShowPreguntasModal] = useState(false);
  const [datatableConfig, setDatatableConfig] = useState({
    headers: [],
    rowActions: [],
  });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);

  useEffect(() => {
    if (!encuestaId) return;
    dispatch(encuestaActions.getEncuestaComplete(encuestaId));
    dispatch(encuestaActions.getReporteResumen({ encuestaId }));
    dispatch(encuestaActions.setReporteResumenChecked([]));
    dispatch(encuestaActions.setTitleReporte("Reporte de encuestados"))
  }, []);

  useEffect(() => {
    if (Object.keys(encuesta).length === 0) return;
    setDatatableConfig(
      buildDatatableConfig(
        preguntas,
        etiquetas,
        onRowClick,
        onSelectEtiquetas,
        setFiltering
      )
    );
    setPreguntasFiltradas(preguntas);
  }, [encuesta]);

  useEffect(() => {
    setDatatableConfig(
      buildDatatableConfig(
        preguntasFiltradas,
        etiquetas,
        onRowClick,
        onSelectEtiquetas,
        setFiltering
      )
    );
  }, [preguntasFiltradas]);

  // Sección: Selección de etiquetas
  const onSelectEtiquetas = (item) => {
    setAsignacionActual(item);
    setEtiquetasSeleccionadas(item.etiquetas);
    setShowEtiquetasModal(true);
  };

  const setFiltering = (field, value) => {
    const currentState = store.getState(); // Se usa getStore porque no se puede acceder a reporte_resumen_filter desde setFiltering porque se ejecuta antes de que el useSelector devuelva el valor.
    const currentFilter = currentState.encuesta.reporte_resumen_filter || {};
    // Si es array, se está filtrando por opciones, si no, se filtra por texto.
    if (Array.isArray(value) && field !== "Etiquetas") {
      const { opciones_respuestas = [] } = currentFilter;
      let opcionesRespuestasList = [...opciones_respuestas];

      const pregunta = preguntas.find((p) => p.texto === field);
      const opcionesDisponibles = pregunta.opciones.map((o) => o.id);
      const opcionesSeleccionadas = value.map((item) => item.value);
      const opcionesNoSeleccionadas = opcionesDisponibles.filter(
        (opcion) => !opcionesSeleccionadas.includes(opcion)
      );

      // Agregar las nuevas opciones que aún no están en la lista
      opcionesSeleccionadas.forEach((opcion) => {
        if (!opcionesRespuestasList.includes(opcion)) {
          opcionesRespuestasList.push(opcion);
        }
      });

      // Sacar las opciones que ya no se seleccionaron
      opcionesNoSeleccionadas.forEach((opcion) => {
        if (opcionesRespuestasList.includes(opcion)) {
          opcionesRespuestasList = opcionesRespuestasList.filter(
            (o) => o !== opcion
          );
        }
      });

      dispatch(
        encuestaActions.setReporteResumenFilter({
          ...currentFilter,
          encuestaId,
          opciones_respuestas: opcionesRespuestasList,
        })
      );
    } else if (Array.isArray(value) && field === "Etiquetas") {
      const etiquetasIds = value.map((item) => item.value);
      dispatch(
        encuestaActions.setReporteResumenFilter({
          ...currentFilter,
          encuestaId,
          etiquetas: etiquetasIds,
        })
      );
    } else {
      const { respuesta_texto = "" } = currentFilter;
      const pregunta = preguntas.find((p) => p.texto === field);

      if (!pregunta) return;

      const pregunta_id = pregunta.id;

      // Descomponer `respuesta_texto` en un array de pares `pregunta_id:respuesta`
      let pares = respuesta_texto.split(";").filter((par) => par.trim() !== "");

      if (value) {
        // Actualizar o agregar la respuesta si `value` no está vacío
        let actualizado = false;
        pares = pares.map((par) => {
          const [id, respuesta] = par.split(":");
          if (id === pregunta_id.toString()) {
            actualizado = true;
            return `${id}:${value}`; // Actualiza la respuesta
          }
          return par;
        });

        if (!actualizado) {
          pares.push(`${pregunta_id}:${value}`); // Agregar nueva respuesta
        }
      } else {
        // Eliminar el par si `value` está vacío
        pares = pares.filter((par) => {
          const [id] = par.split(":");
          return id !== pregunta_id.toString();
        });
      }

      // Reconstruir el `respuesta_texto`
      const nuevoRespuestaTexto = pares.join(";");

      // Actualizar el estado con el nuevo `respuesta_texto`
      dispatch(
        encuestaActions.setReporteResumenFilter({
          ...currentFilter,
          encuestaId,
          respuesta_texto: nuevoRespuestaTexto,
        })
      );
    }
  };

  const onCloseModalSelectEtiquetas = () => {
    setAsignacionActual({});
    setEtiquetasSeleccionadas([]);
    setShowEtiquetasModal(false);
  };

  const onSaveEtiquetas = () => {
    const asignacionId = asignacionActual.id;
    if (!asignacionId)
      ErrorToast({ message: "No se seleccionó a ningun colaborador." });

    const etiquetasIds = etiquetasSeleccionadas.map((e) => e.id);
    const params = {
      encuestaId,
      asignacionId,
      etiquetasIds,
    };
    dispatch(encuestaActions.asignarEtiquetas(params));
    setEtiquetasSeleccionadas([]);
    setAsignacionActual({});
    setShowEtiquetasModal(false);
  };

  const onAddRemoveEtiqueta = (etiqueta) => {
    const etiquetaCopy = etiquetasSeleccionadas.find(
      (e) => e.id === etiqueta.id
    );
    if (!etiquetaCopy) {
      setEtiquetasSeleccionadas([...etiquetasSeleccionadas, etiqueta]);
    } else {
      setEtiquetasSeleccionadas(
        etiquetasSeleccionadas.filter((e) => e.id !== etiqueta.id)
      );
    }
  };

  const onDeleteEtiqueta = (etiqueta) => {
    const deseaEliminar = window.confirm(
      "¿Desea eliminar la etiqueta seleccionada?"
    );
    if (!!deseaEliminar) {
      dispatch(
        encuestaActions.deleteEtiqueta({ encuestaId, etiquetaId: etiqueta.id })
      );
    }
  };

  // Sección: Crear nueva etiqueta
  const onChangeNombreEtiqueta = (e) => {
    setNombreEtiqueta(e.target.value);
  };

  const onShowModalNewEtiqueta = () => {
    setNombreEtiqueta("");
    setEtiquetaColor("#ffffff");
    setShowEtiquetasModal(false);
    setShowCreateEtiquetaModal(true);
  };

  const onCloseModalNewEtiqueta = () => {
    setShowCreateEtiquetaModal(false);
    setShowEtiquetasModal(true);
  };

  const onSaveNewEtiqueta = (e) => {
    e.preventDefault();
    if (!nombreEtiqueta.trim()) {
      ErrorToast({ message: "Debe ingresar el nombre de la etiqueta." });
      return;
    }
    const params = {
      encuestaId,
      etiquetas: [
        ...etiquetas,
        { nombre: nombreEtiqueta, color: etiquetaColor },
      ],
    };
    dispatch(encuestaActions.updateEtiquetas(params));
    setShowCreateEtiquetaModal(false);
    setShowEtiquetasModal(true);
  };

  const onChangeColor = (e) => {
    console.log(e.hex);
    setEtiquetaColor(e.hex);
  };

  // Sección: Tabla con los datos
  const onPaginate = (page, pageSize) => {
    //
    dispatch(
      encuestaActions.setReporteResumenFilter({
        ...reporte_resumen_filter,
        encuestaId,
        pagination: {
          page: page,
          pageSize: pageSize,
        },
      })
    );
  };

  const onCheckAll = () => {
    let checkedData = JSON.parse(JSON.stringify(reporte_resumen));
    if (reporte_resumen_checked.length > 0) {
      checkedData = [];
    }
    dispatch(encuestaActions.setReporteResumenChecked(checkedData));
  };

  const onCheckItem = (item) => (event) => {
    dispatch(
      encuestaActions.checkReporteResumen({
        item,
        checked: event.target.checked,
      })
    );
  };

  const onChangePageSize = (e) => {
    let value = e.target.value;
    //
    dispatch(
      encuestaActions.setReporteResumenFilter({
        ...reporte_resumen_filter,
        encuestaId,
        pagination: {
          pageSize: parseInt(value),
          page: 1,
        },
      })
    );
    //
    setPageSize(parseInt(value));
  };

  const onSearch = (e) => {
    let searchText = e.target.value;
    dispatch(
      encuestaActions.setReporteResumenFilter({
        ...reporte_resumen_filter,
        encuestaId,
        nombre: searchText,
      })
    );
  };

  const onRowClick = (item) => {
    const { empleado = {} } = item;
    const colaboradorId = empleado.id;
    if (!colaboradorId) return;
    navigate(
      `/encuesta/${encuestaId}/reporte/resumen/colaborador/${colaboradorId}`
    );
  };

  const actionOptions = [
    {
      value: "exportarSeleccionados",
      label: "Exportar seleccionados",
    },
  ];
  const onAction = (v) => {
    if (v.value === "exportarSeleccionados") {
      onExportData("selected");
      return;
    }
  };

  // Sección: Descargar reporte
  /* 
    type: 
      - complete: Reporte de todas las líneas que aparezcan en la tabla.
      - selected: Reporte de las líneas seleccionadas con el check.
  */
  const onExportData = (type) => {
    let asignacionesIds = [];
    if (type === "complete") {
      asignacionesIds = reporte_resumen.map((e) => e.id);
    } else if (type === "selected") {
      asignacionesIds = reporte_resumen_checked.map((e) => e.id);
    }

    if (asignacionesIds.length === 0) {
      ErrorToast({
        message:
          "Debe seleccionar al menos una asignación para descargar el reporte.",
      });
      return;
    }
    const params = {
      encuestaId,
      asignacionesIds,
    };
    dispatch(encuestaActions.exportarReporteResumen(params));
  };

  const handleCheckboxChange = (pregunta) => {
    // Verifica si la pregunta ya está en el array preguntasFiltradas
    const preguntaIndex = preguntasFiltradas.findIndex(
      (p) => p.id === pregunta.id
    );

    let preguntasFiltradasCopy = [...preguntasFiltradas];

    if (preguntaIndex >= 0) {
      // Si la pregunta ya está en el array, la eliminamos
      preguntasFiltradasCopy.splice(preguntaIndex, 1);
    } else {
      // Si no está en el array, la añadimos
      preguntasFiltradasCopy.push(pregunta);
    }

    // Actualiza el estado con el nuevo array
    setPreguntasFiltradas(preguntasFiltradasCopy);
  };

  return (
    <EncuestaReportePage>
      <div>
        <div className="p-4 w-full flex justify-center">
          <div className="w-full">
            <div className="w-full flex items-center mb-4 gap-4 justify-between px-6">
              <SearchInput
                value={nombre}
                onChange={onSearch}
                placeholder="Buscar por nombre o cargo"
              />
              <MostrarPaginas
                containerClass={"w-1/2x"}
                defaultOption={50}
                onChangePageSize={onChangePageSize}
              />
              <ReactSelect
                className="w-auto h-10 inline-grid text-sm"
                value={{
                  value: "",
                  label: `(${reporte_resumen_checked.length}) seleccionados`,
                }}
                options={actionOptions}
                onChange={onAction}
              />
              {!reporte_resumen_downloading && (
                <button
                  onClick={() => onExportData("complete")}
                  className="flex items-center gap-2 border rounded-md py-2 px-3 border-gray-500 text-sm"
                >
                  <FiletypeXls size={21} />
                  <span className="font-semibold text-sm">Exportar datos</span>
                </button>
              )}
              {reporte_resumen_downloading && <DownloadingFileAnimation />}
              <button
                title="Filtrar columnas"
                onClick={() => setShowPreguntasModal(true)}
              >
                <Filter size={32} />
              </button>
            </div>
            <DataTable
              containerClass={"my-8 custom-scrollbar border rounded p-4"}
              data={reporte_resumen}
              loading={reporte_resumen_loading || encuesta_loading}
              config={datatableConfig}
              // Pagination
              totalRecords={reporte_resumen_count}
              onPaginate={onPaginate}
              outerPageSize={pageSize}
              outerPage={page}
              //
              checkable={true}
              onCheckAll={onCheckAll}
              checkedItems={reporte_resumen_checked}
              onItemCheck={onCheckItem}
              //
              fixedColumns={1}
              minWidthColumn="min-w-[260px]"
              addBorder={true}
            />
          </div>
        </div>
      </div>
      {/* Modal para mostrar las etiquetas disponibles. */}
      <Modal isOpen={showEtiquetasModal}>
        <div className=" rounded-lg bg-white w-6/12">
          <form onSubmit={onSaveEtiquetas} className="flex flex-col gap-3 p-4">
            <div className="flex flex-row justify-between text-lg font-bold">
              <div>Etiquetas</div>
              <div
                className="cursor-pointer"
                onClick={onCloseModalSelectEtiquetas}
              >
                X
              </div>
            </div>
            <span className="text-sm -mt-3">
              Obs: Clic derecho para eliminar una etiqueta de la encuesta (Se
              sacaran de todos los registros asignados donde aparezca)
            </span>
            {!encuesta_loading ? (
              <div>
                <div className="flex flex-wrap gap-4 max-h-[380px] custom-scrollbar">
                  {etiquetas.map((etiqueta) => (
                    <div className="flex flex-col items-center gap-1">
                      <EtiquetaComponent
                        key={etiqueta.id}
                        name={etiqueta.nombre}
                        color={etiqueta.color}
                        onClick={() => onAddRemoveEtiqueta(etiqueta)}
                        onDelete={() => onDeleteEtiqueta(etiqueta)}
                      />
                      {etiquetasSeleccionadas.find(
                        (e) => e.id === etiqueta.id
                      ) && (
                          <div className="h-2 w-2 rounded-full bg-sky-500"></div>
                        )}
                    </div>
                  ))}
                  <button
                    type="button"
                    className="text-sky-500"
                    onClick={onShowModalNewEtiqueta}
                  >
                    + Agregar etiqueta
                  </button>
                </div>
                <button
                  type="submit"
                  className="bg-sky-500 text-white w-full rounded-md px-3 py-2 mt-4 active:bg-gray-200"
                >
                  Guardar
                </button>
              </div>
            ) : (
              <div className="flex justify-center">
                <LoadingSpinButton />
              </div>
            )}
          </form>
        </div>
      </Modal>
      <Modal isOpen={showCreateEtiquetaModal}>
        <div className=" rounded-lg bg-white w-6/12">
          <form
            onSubmit={onSaveNewEtiqueta}
            className="flex flex-col gap-3 p-4"
          >
            <div className="flex flex-row justify-between text-lg font-bold">
              <div>Crear etiqueta</div>
              <div className="cursor-pointer" onClick={onCloseModalNewEtiqueta}>
                X
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div className="flex justify-between gap-4">
                <div className="w-1/2">
                  <Input
                    label="Nombre"
                    name="nombre"
                    placeholder="Ingrese el nombre de la etiqueta"
                    className="w-full"
                    value={nombreEtiqueta}
                    onChange={onChangeNombreEtiqueta}
                  />
                  <div className="mt-6 flex justify-center">
                    <EtiquetaComponent
                      name={nombreEtiqueta || "Etiqueta"}
                      color={etiquetaColor}
                    />
                  </div>
                </div>
                <TwitterPicker color={etiquetaColor} onChange={onChangeColor} />
              </div>
              <button
                type="submit"
                className="bg-sky-500 text-white rounded-md px-3 py-2 active:bg-gray-200"
              >
                Guardar
              </button>
            </div>
          </form>
        </div>
      </Modal>
      {/* Modal para mostrar las preguntas disponibles y poder seleccionar las que queremos mostrar en la tabla. */}
      <Modal isOpen={showPreguntasModal}>
        <div className="flex flex-col gap-4 p-6 rounded-lg bg-white w-6/12 shadow-lg">
          {/* Encabezado */}
          <div className="flex flex-row justify-between text-lg font-bold">
            <div>Filtrar Preguntas</div>
            <div
              className="cursor-pointer text-gray-500 hover:text-gray-700"
              onClick={() => setShowPreguntasModal(false)}
            >
              ✕
            </div>
          </div>

          {/* Lista de preguntas */}
          <div className="flex flex-col gap-4 max-h-[550px] custom-scrollbar">
            {preguntas.map((pregunta) => (
              <div
                key={pregunta.id}
                className="flex justify-start border rounded-lg p-4 shadow-sm"
              >
                <div className="flex items-center gap-3">
                  <input
                    type="checkbox"
                    name={`${pregunta.id}`}
                    className="w-5 h-5 flex-shrink-0 accent-blue-600 mt-1"
                    checked={preguntasFiltradas.some(
                      (p) => p.id === pregunta.id
                    )}
                    onChange={() => handleCheckboxChange(pregunta)}
                  />
                  <div className="text-gray-700 text-sm">{pregunta.texto}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Modal>
    </EncuestaReportePage>
  );
};

export default EncuestaReporteResumenPage;
