import { createSlice } from "@reduxjs/toolkit";

const estructuraSlice = createSlice({
  name: "Estructura",
  initialState: {
    estructura_create_loading: false,
    estructura_update_loading: false,
    estructura_delete_loading: false,
    estructura: {},
    estructuras: [],
    totals: [],
    estructuras_loading: false,
    totals_loading: false,
  },
  reducers: {
    totals: (state, { payload }) => {
      state.totals_loading = true;
    },
    setTotals: (state, { payload }) => {
      state.totals = payload.totals;
      state.totals_loading = false;
    },
    //
    estructuraSet: (state, { payload = {} }) => {
      state.estructura = payload;
    },
    setEstructuras: (state, { payload }) => {
      state.estructuras = payload.estructuras;
      state.estructuras_loading = false;
    },
    // create
    estructuraCreate: (state, { payload = {} }) => {
      state.estructura_create_loading = true;
    },
    estructuraCreateSuccess: (state) => {
      state.estructura_create_loading = false;
    },
    // update
    estructuraUpdate: (state, { payload = {} }) => {
      state.estructura_update_loading = true;
    },
    estructuraUpdateSuccess: (state) => {
      state.estructura_update_loading = false;
    },
    // delete
    estructuraDelete: (state, { payload = {} }) => {
      state.estructura_delete_loading = true;
    },
    estructuraDeleteSuccess: (state) => {
      state.estructura_delete_loading = false;
    },
    // list
    estructuras: (state, { payload }) => {
      state.estructuras_loading = true;
    },
  },
});

export const estructuraActions = estructuraSlice.actions;
export const estructuraReducer = estructuraSlice.reducer;
