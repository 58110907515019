import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import StatisticVertical from "src/modules/analisis/components/StatisticVertical";
import DataTable from "src/modules/common/components/DataTable";
import LoadingSpinButton from "src/modules/common/components/LoadingSpinButton";
import PieCenterLabel from "src/modules/common/components/PieCenterLabel";
import { ProfileInfoCard } from "src/modules/common/components/ProfileInfoCard";
import SelectModal from "src/modules/common/components/SelectModal";
import { commonActions } from "src/modules/common/handler/redux";
import {
  DownloadReport,
  insertArrayAt,
  setOrderingHelper,
} from "src/modules/common/helpers/helpers";
import { formatNumber, getToken } from "src/utils/helpers";
import FinalizarConfiguracion from "../components/FinalizarConfiguracion";
import RemuneracionConfiguracionLayout from "../components/RemuneracionConfiguracionLayout";
import SearchInput from "../components/SearchInput";
import { remuneracionActions } from "../handlers/redux";
import { RutasRemuneracion } from "../handlers/routes";
import {
  generarArrayConSalto,
  getEstadoColaboradorColor,
  getEstadoColor,
  obtenerLetrasColumna,
} from "../helpers/helpers";
import ComparativoColaborador from "../components/ComparativoColaborador";
import { colaboradorGraficotemp } from "./drawings";
import MostrarPaginas from "src/modules/common/components/MostrarPaginas";
import { FileExcel, FilePdf } from "react-bootstrap-icons";

const CheckItem = ({
  label = "Write a label",
  labelStyle = "text-sm text-slate-500",
  containerStyle = "",
  ...props
}) => {
  return (
    <div className={`flex gap-1 ${containerStyle}`}>
      <input type="checkbox" {...props} />
      <div className={labelStyle}>{label}</div>
    </div>
  );
};
const CategoriasTabs = ({
  defaultItem = [],
  categorias = [],
  showMercados = true,
  setTodosIsSelected,
  loading = false,
  filterFn,
  setCurrentCategoria,
}) => {
  const [activeTab, setActiveTab] = useState(-1);
  const [selectedCategoria, setSelectedCategoria] = useState(defaultItem);
  const onChangeTab = (idx) => {
    setActiveTab(idx);
    if (!!categorias[idx]) {
      setSelectedCategoria(categorias[idx]);
      setCurrentCategoria(categorias[idx]);
      setTodosIsSelected(false);
      if (!!filterFn) {
        filterFn(categorias[idx].nombre);
      }
    } else {
      setSelectedCategoria(defaultItem);
      setCurrentCategoria(defaultItem);
      setTodosIsSelected(true);
      if (!!filterFn) {
        filterFn("");
      }
    }
  };
  useEffect(() => {
    if (activeTab === -1) {
      setSelectedCategoria(defaultItem);
    }
  }, [defaultItem]);
  //
  return (
    <div>
      {!loading && (
        <>
          <div className="flex flex-wrap gap-2 text-sm">
            <button
              onClick={() => onChangeTab(-1)}
              className={`bg-slate-200 p-1 rounded-md border ${
                -1 === activeTab ? "border-sky-500" : "border-slate-200"
              }`}
            >
              Todos
            </button>
            {categorias.map((categoria, cidx) => (
              <button
                key={`cat-btn-${cidx}`}
                onClick={() => onChangeTab(cidx)}
                className={`bg-slate-200 p-1 rounded-md border ${
                  cidx === activeTab ? "border-sky-500" : "border-slate-200"
                }`}
              >
                {categoria.nombre}
              </button>
            ))}
          </div>
          {/*  */}
          <div
            className={`flex ${
              !!showMercados ? "justify-between" : "justify-center"
            } mt-8`}
          >
            {/* RANGO SALARIAL */}
            <div className={`${!!showMercados ? "w-1/2" : ""} `}>
              <h3 className="font-semibold">Rango escala salarial</h3>
              <div className="flex gap-4 mt-2">
                <div className="border-3 w-48">
                  {selectedCategoria?.por_encima?.porcentaje +
                    selectedCategoria?.dentro?.porcentaje +
                    selectedCategoria?.por_debajo?.porcentaje ===
                    0 && (
                    <div className="border rounded-full h-full flex justify-center items-center">
                      Sin datos
                    </div>
                  )}
                  {selectedCategoria?.por_encima?.porcentaje +
                    selectedCategoria?.dentro?.porcentaje +
                    selectedCategoria?.por_debajo?.porcentaje >
                    0 && (
                    <PieCenterLabel
                      colorScale={["green", "blue", "red"]}
                      data={[
                        {
                          x: "E",
                          y: selectedCategoria?.por_encima?.porcentaje,
                        },
                        { x: "DN", y: selectedCategoria?.dentro?.porcentaje },
                        {
                          x: "DB",
                          y: selectedCategoria?.por_debajo?.porcentaje,
                        },
                      ]}
                      // label={`Escala`}
                    ></PieCenterLabel>
                  )}
                </div>
                <div className="flex flex-col gap-4 w-28">
                  <StatisticVertical
                    title={"Por encima"}
                    percentage={Math.round(
                      selectedCategoria?.por_encima?.porcentaje
                    )}
                    amount={selectedCategoria?.por_encima?.cantidad}
                    titleDotColor="green"
                  ></StatisticVertical>
                  <StatisticVertical
                    title={"Dentro"}
                    percentage={Math.round(
                      selectedCategoria?.dentro?.porcentaje
                    )}
                    amount={selectedCategoria?.dentro?.cantidad}
                    titleDotColor="blue"
                  ></StatisticVertical>
                  <StatisticVertical
                    title={"Por debajo"}
                    percentage={Math.round(
                      selectedCategoria?.por_debajo?.porcentaje
                    )}
                    amount={selectedCategoria?.por_debajo?.cantidad}
                    titleDotColor="red"
                  ></StatisticVertical>
                </div>
              </div>
            </div>
            {/* RANGO SALARIAL MERCADO */}
            {!!showMercados && (
              <div className="flex-1x w-1/2">
                <h3 className="font-semibold">
                  Rango escala salarial según mercado
                </h3>
                <div className="flex gap-4 mt-2">
                  <div className="border-3 w-48">
                    {selectedCategoria.por_encima_mercado.porcentaje +
                      selectedCategoria.dentro_mercado.porcentaje +
                      selectedCategoria.por_debajo_mercado.porcentaje ===
                      0 && (
                      <div className="border rounded-full h-full flex justify-center items-center">
                        Sin datos
                      </div>
                    )}
                    {selectedCategoria.por_encima_mercado.porcentaje +
                      selectedCategoria.dentro_mercado.porcentaje +
                      selectedCategoria.por_debajo_mercado.porcentaje >
                      0 && (
                      <PieCenterLabel
                        colorScale={["green", "blue", "red"]}
                        data={[
                          {
                            x: "E",
                            y: selectedCategoria.por_encima_mercado.porcentaje,
                          },
                          {
                            x: "DN",
                            y: selectedCategoria.dentro_mercado.porcentaje,
                          },
                          {
                            x: "DB",
                            y: selectedCategoria.por_debajo_mercado.porcentaje,
                          },
                        ]}
                        // label={`Mercado`}
                      ></PieCenterLabel>
                    )}
                  </div>
                  <div className="flex flex-col gap-4 w-28">
                    <StatisticVertical
                      title={"Por encima"}
                      percentage={Math.round(
                        selectedCategoria.por_encima_mercado.porcentaje
                      )}
                      amount={selectedCategoria.por_encima_mercado.cantidad}
                      titleDotColor="green"
                    ></StatisticVertical>
                    <StatisticVertical
                      title={"Dentro"}
                      percentage={Math.round(
                        selectedCategoria.dentro_mercado.porcentaje
                      )}
                      amount={selectedCategoria.dentro_mercado.cantidad}
                      titleDotColor="blue"
                    ></StatisticVertical>
                    <StatisticVertical
                      title={"Por debajo"}
                      percentage={Math.round(
                        selectedCategoria.por_debajo_mercado.porcentaje
                      )}
                      amount={selectedCategoria.por_debajo_mercado.cantidad}
                      titleDotColor="red"
                    ></StatisticVertical>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
      {!!loading && (
        <div className="w-full h-48 flex items-center justify-center p-4">
          <LoadingSpinButton />
        </div>
      )}
    </div>
  );
};

const CategoriasFilters = ({ defaultItem, categorias = [], filterFn }) => {
  const [activeTab, setActiveTab] = useState(-1);
  const [selectedCategoria, setSelectedCategoria] = useState(defaultItem);
  const onChangeTab = (idx) => {
    setActiveTab(idx);
    if (!!categorias[idx]) {
      setSelectedCategoria(categorias[idx]);
      if (!!filterFn) {
        filterFn(categorias[idx].nombre);
      }
    } else {
      setSelectedCategoria(defaultItem);
      if (!!filterFn) {
        filterFn("");
      }
    }
  };
  // useEffect(() => {
  //   if (activeTab === -1) {
  //     setSelectedCategoria(defaultItem);
  //   }
  // }, [defaultItem]);
  return (
    <div className="flex flex-wrap gap-2 text-sm">
      <button
        onClick={() => onChangeTab(-1)}
        className={`bg-slate-200 p-1 rounded-md border ${
          -1 === activeTab ? "border-sky-500" : "border-slate-200"
        }`}
      >
        Todos
      </button>
      {categorias.map((categoria, cidx) => (
        <button
          key={`cat-btn-${cidx}`}
          onClick={() => onChangeTab(cidx)}
          className={`bg-slate-200 p-1 rounded-md border ${
            cidx === activeTab ? "border-sky-500" : "border-slate-200"
          }`}
        >
          {categoria.nombre}
        </button>
      ))}
    </div>
  );
};

const RemuneracionEscalaDetalle = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { idEscala = null } = useParams();
  const tableRef = useRef(null);

  const onClickBack = () => {
    navigate(RutasRemuneracion.ESCALA);
  };
  //
  const escala_reporte = useSelector(
    (state) => state.remuneracion.escala_reporte
  );
  const escala_reporte_loading = useSelector(
    (state) => state.remuneracion.escala_reporte_loading
  );
  const escala_reporte_filter = useSelector(
    (state) => state.remuneracion.escala_reporte_filter
  );
  //
  const colaboradores_escala = useSelector(
    (state) => state.remuneracion.colaboradores_escala
  );
  const colaboradores_escala_loading = useSelector(
    (state) => state.remuneracion.colaboradores_escala_loading
  );
  const colaboradores_escala_filter = useSelector(
    (state) => state.remuneracion.colaboradores_escala_filter
  );
  const { nombre: nombreColaborador } = colaboradores_escala_filter;
  // /
  const colaboradores_comparativo = useSelector(
    (state) => state.remuneracion.colaboradores_comparativo
  );
  const colaboradores_comparativo_loading = useSelector(
    (state) => state.remuneracion.colaboradores_comparativo_loading
  );
  const colaboradores_comparativo_filter = useSelector(
    (state) => state.remuneracion.colaboradores_comparativo_filter
  );
  const { nombre: nombreComparativo } = colaboradores_comparativo_filter;
  const {
    count: colaboradoresComparativoCount = 0,
    results: colaboradoresComparativo = [],
  } = colaboradores_comparativo;
  // /
  const {
    nombre: nombreEscala = "Cargando...",
    estado: estadoEscala = "...",
    totales = [],
    categorias = [],
    grilla = [],
  } = escala_reporte;
  const [grillaLocal, setGrillaLocal] = useState([]);
  const [activarSalariosMercado, setActivarSalariosMercado] = useState(false);
  const [activarExtras, setActivarExtras] = useState(false);
  //
  const [
    activarSalariosMercadoComparativo,
    setActivarSalariosMercadoComparativo,
  ] = useState(false);
  const [activarExtrasComparativo, setActivarExtrasComparativo] =
    useState(false);
  //
  useEffect(() => {
    let grillaCopy = JSON.parse(JSON.stringify(grilla));
    grillaCopy.sort((a, b) => a.id - b.id);
    setGrillaLocal(grillaCopy);
  }, [grilla]);
  ///
  const setOrdering =
    (field) =>
    (order = "") => {
      const newOrdering = setOrderingHelper(
        field,
        order,
        colaboradores_escala_filter
      );
      //
      dispatch(
        remuneracionActions.setColaboradoresEscalaFilter({
          ...colaboradores_escala_filter,
          id: idEscala,
          ordering: newOrdering,
        })
      );
    };

  function addFilter(field) {
    let minSearchLen = 4;

    return (value) => {
      if (value.length <= minSearchLen && value.length > 0) {
        return;
      }
      dispatch(
        remuneracionActions.setColaboradoresEscalaFilter({
          ...colaboradores_escala_filter,
          [`${field}`]: value,
        })
      );
    };
  }
  function addFilterComparativo(field) {
    return (value) => {
      dispatch(
        remuneracionActions.setColaboradoresComparativoFilter({
          ...colaboradores_comparativo_filter,
          id: idEscala,
          [`${field}`]: value,
        })
      );
    };
  }
  const conExtrasHeaders = [
    {
      title: "Salario fijo",
      onOrder: setOrdering("salario_fijo"),
      onFilter: addFilter("salario_fijo"),
      accessor: "salario_fijo",
      contentRenderer: (item) => {
        return <div>Gs. {formatNumber(item.salario_fijo ?? 0)}</div>;
      },
    },
    {
      title: "Salario extra",
      onOrder: setOrdering("salario_extra"),
      onFilter: addFilter("salario_extra"),
      accessor: "salario_extra",
      contentRenderer: (item) => {
        return <div>Gs. {formatNumber(item.salario_extra ?? 0)}</div>;
      },
    },
    {
      title: "Salario percibido",
      onOrder: setOrdering("salario_total"),
      onFilter: addFilter("salario_total"),
      accessor: "salario_total",
      contentRenderer: (item) => {
        return <div>Gs. {formatNumber(item.salario_total ?? 0)}</div>;
      },
    },
  ];
  const conMercadoHeaders = [
    {
      title: "Promedio mercado",
      onOrder: setOrdering("salario_mercado"),
      onFilter: addFilter("salario_mercado"),
      accessor: "salario_mercado",
      contentRenderer: (item) => {
        return <div>Gs. {formatNumber(item.salario_mercado ?? 0)}</div>;
      },
    },
    {
      title: "Rango mercado",
      onOrder: setOrdering("rango_mercado"),
      onFilter: addFilter("rango_mercado"),
      accessor: "rango_mercado",
      contentRenderer: (item) => {
        if (item.rango_mercado.length < 2) {
          return <div className="text-center">Sin definir</div>;
        }
        let primerSalario = item.rango_mercado[0];
        let ultimoSalario = item.rango_mercado[1];
        //
        return (
          <div>
            Gs. {formatNumber(primerSalario ?? 0)} -{" "}
            {formatNumber(ultimoSalario ?? 0)}
          </div>
        );
      },
    },
    {
      title: "Estado mercado",
      onOrder: setOrdering("estado_mercado"),
      onFilter: addFilter("estado_mercado"),
      accessor: "estado_mercado",
      contentRenderer: (item) => {
        return (
          <div
            className={`font-bold ${getEstadoColaboradorColor(
              item.estado_mercado
            )}`}
          >
            {item.estado_mercado.split("(")[0] || "Sin especificar"}
          </div>
        );
      },
    },
    {
      title: "Diferencia mínima mercado",
      onOrder: setOrdering("estado_mercado"),
      onFilter: addFilter("estado_mercado"),
      accessor: "estado_mercado",
      contentRenderer: (item) => {
        let diferencia = item.diferencia_minima_mercado.toLocaleString("de-DE");
        if (diferencia && Number(diferencia.replace(".", "")) > 0) {
          diferencia = "+" + diferencia;
        }
        return (
          <div
            className={`font-bold ${getEstadoColaboradorColor(
              item.estado_mercado
            )}`}
          >
            {diferencia || "Sin especificar"}
          </div>
        );
      },
    },
    {
      title: "Diferencia promedio mercado",
      onOrder: setOrdering("estado_mercado"),
      onFilter: addFilter("estado_mercado"),
      accessor: "estado_mercado",
      contentRenderer: (item) => {
        let diferencia = item.diferencia_promedio_mercado.toLocaleString("de-DE");
        if (diferencia && Number(diferencia.replace(".", "")) > 0) {
          diferencia = "+" + diferencia;
        }
        return (
          <div
            className={`font-bold ${getEstadoColaboradorColor(
              item.estado_mercado
            )}`}
          >
            {diferencia || "Sin especificar"}
          </div>
        );
      },
    },
    {
      title: "Diferencia máxima mercado",
      onOrder: setOrdering("estado_mercado"),
      onFilter: addFilter("estado_mercado"),
      accessor: "estado_mercado",
      contentRenderer: (item) => {
        let diferencia = item.diferencia_maxima_mercado.toLocaleString("de-DE");
        if (diferencia && Number(diferencia.replace(".", "")) > 0) {
          diferencia = "+" + diferencia;
        }
        return (
          <div
            className={`font-bold ${getEstadoColaboradorColor(
              item.estado_mercado
            )}`}
          >
            {diferencia || "Sin especificar"}
          </div>
        );
      },
    },
  ];
  const datatableConfigInitial = {
    headers: [
      {
        title: "Lista de colaboradores",
        contentClass: "font-bold",
        accessor: "nombre",
        contentRenderer: (item) => {
          return (
            <ProfileInfoCard
              image={item.foto_empleado}
              name={
                item.id ? `${item.nombre} ${item.apellido}` : "Sin especificar"
              }
            >
              <div
                title={item.cargo?.nombre || "Sin asignar"}
                className="text-sm w-36 whitespace-nowrap text-ellipsis overflow-hidden"
              >
                {item.cargo?.nombre || "Sin asignar"}
              </div>
            </ProfileInfoCard>
          );
        },
      },
      {
        title: "Salario percibido",
        onOrder: setOrdering("salario_fijo"),
        onFilter: addFilter("salario_fijo"),
        accessor: "salario_fijo",
        contentRenderer: (item) => {
          return <div>Gs. {formatNumber(item.salario_fijo ?? 0)}</div>;
        },
      },
      /* TODO: extra y total */
      {
        title: "Escala Salarial",
        onOrder: setOrdering("rango_escala"),
        onFilter: addFilter("rango_escala"),
        accessor: "rango_escala",
        contentRenderer: (item) => {
          if (item.rango_escala.length < 2) {
            return <div className="text-center">Sin definir</div>;
          }
          let primerSalario = item.rango_escala[0];
          let ultimoSalario = item.rango_escala[1];
          return (
            <div>
              Gs. {formatNumber(primerSalario ?? 0)} -{" "}
              {formatNumber(ultimoSalario ?? 0)}
            </div>
          );
        },
      },
      {
        title: "Estado (E.S.)",
        onOrder: setOrdering("estado_escala"),
        onFilter: addFilter("estado_escala"),
        accessor: "estado_escala",
        contentRenderer: (item) => {
          return (
            <div
              className={`font-bold ${getEstadoColaboradorColor(
                item.estado_escala
              )}`}
            >
              {item.estado_escala?.split("(")[0] || "Sin especificar"}
            </div>
          );
        },
      },
      {
        title: "Diferencia (E.S.)",
        onOrder: setOrdering("estado_escala"),
        onFilter: addFilter("estado_escala"),
        accessor: "estado_escala",
        contentRenderer: (item) => {
          let diferencia = item.estado_escala.split("(")[1]?.replace(")", "");
          if (diferencia && Number(diferencia.replace(".", "")) > 0) {
            diferencia = "+" + diferencia;
          }
          return (
            <div
              className={`font-bold ${getEstadoColaboradorColor(
                item.estado_escala
              )}`}
            >
              {diferencia || "Sin especificar"}
            </div>
          );
        },
      },
      {
        title: "Categoría",
        onOrder: setOrdering("categoria"),
        onFilter: addFilter("categoria"),
        accessor: "categoria",
      },
    ],
    rowActions: [
      // {
      //   label: "Editar",
      //   action: (item) => {
      //     navigate(RutasRemuneracion.ESCALA_CONFIGURACION(item.id));
      //   },
      // },
    ],
  };
  const [todosIsSelected, setTodosIsSelected] = useState(false);
  const [datatableConfig, setDatatableConfig] = useState(
    datatableConfigInitial
  );
  const reporteHeadersInitial = [
    "nombre",
    "apellido",
    "cargo_asignado",
    "salario_fijo",
    "rango_escala",
    "estado_escala",
    "categoria",
  ];
  const reporteMercadoHeaders = [
    "salario_mercado",
    "rango_mercado",
    "estado_mercado",
  ];
  const reporteExtrasHeaders = [
    "salario_fijo",
    "salario_extra",
    "salario_total",
  ];
  const [reporteHeaders, setReporteHeaders] = useState(reporteHeadersInitial);

  useEffect(() => {
    const configCopy = { ...datatableConfigInitial };
    const { headers } = configCopy;
    let newHeaders = [...headers];
    //
    let newHeadersReporte = [...reporteHeadersInitial];
    //
    if (!!activarExtras) {
      newHeaders.splice(1, 1); // quitamos salario percibido que era el fijo
      newHeadersReporte.splice(3, 1); // quitamos salario percibido que era el fijo
      //
      newHeaders = insertArrayAt(newHeaders, 1, conExtrasHeaders);
      newHeadersReporte = insertArrayAt(
        newHeadersReporte,
        2,
        reporteExtrasHeaders
      );
    }
    if (!!activarSalariosMercado) {
      newHeaders = insertArrayAt(
        newHeaders,
        newHeaders.length,
        conMercadoHeaders
      );
      newHeadersReporte = insertArrayAt(
        newHeadersReporte,
        newHeadersReporte.length,
        reporteMercadoHeaders
      );
    }
    if (!todosIsSelected) {
      newHeaders = newHeaders.filter((h, hi) => h.accessor !== "categoria");
    }
    //
    configCopy.headers = newHeaders;
    setDatatableConfig(configCopy);
    setReporteHeaders(newHeadersReporte);
  }, [activarSalariosMercado, activarExtras, todosIsSelected]);
  ///
  const [Page, setPage] = useState(1);
  const [PageSize, setPageSize] = useState(50);
  //
  const [PageComparativo, setPageComparativo] = useState(1);
  const [PageSizeComparativo, setPageSizeComparativo] = useState(50);
  //
  // const colaboradores_escala = colaboradoresTemp; // useSelector((state) => state.remuneracion.colaboradores_escala);
  const { count: colaboradoresCount = 0, results: colaboradoresEscala = [] } =
    colaboradores_escala;
  //
  useEffect(() => {
    dispatch(commonActions.setTitle("Admin"));
    dispatch(commonActions.showSidebar(false));
    //
    if (!!idEscala) {
      dispatch(remuneracionActions.getEscalaReporte({ id: idEscala }));
      // TODO CHECK DOUBLE REQUESTS
      dispatch(remuneracionActions.getColaboradoresEscala({ id: idEscala }));
      dispatch(
        remuneracionActions.getColaboradoresComparativo({ id: idEscala })
      );
    } else {
      // dispatch(remuneracionActions.setEscalaSalarial({}));
    }
  }, []);
  //
  const BarComponent = ({ label = "..." }) => {
    return (
      <div className="flex gap-2 items-center text-sm">
        <div className={`p-1 ${getEstadoColor(label)} rounded-md`}>{label}</div>
        {label !== "Vigente" && (
          <button onClick={onFinalizar}>
            <span className="text-sky-500">Activar esta escala</span>
          </button>
        )}
      </div>
    );
  };
  const goToEditar = () => {
    navigate(RutasRemuneracion.ESCALA_CONFIGURACION(idEscala));
  };
  const onActivarSalarios = () => {
    setActivarSalariosMercado(!activarSalariosMercado);
  };
  const onActivarExtras = () => {
    dispatch(
      remuneracionActions.setEscalaReporteFilter({
        ...escala_reporte_filter,
        id: idEscala,
        con_extras: !activarExtras,
      })
    );
    dispatch(
      remuneracionActions.setColaboradoresEscalaFilter({
        ...colaboradores_escala_filter,
        id: idEscala,
        con_extras: !activarExtras,
      })
    );
    setActivarExtras(!activarExtras);
  };
  //
  const onActivarSalariosComparativo = () => {
    setActivarSalariosMercadoComparativo(!activarSalariosMercadoComparativo);
  };
  const onActivarExtrasComparativo = () => {
    // TODO
    setActivarExtrasComparativo(!activarExtrasComparativo);
  };
  //
  const onChangePageSize = (e) => {
    const value = e.target.value;
    setPageSize(value);
    setPage(1);
    //colaboradoresCount
  };

  const onChangePageSizeComparativo = (e) => {
    const value = e.target.value;
    setPageSizeComparativo(value);
    setPageComparativo(1);
    //
    dispatch(
      remuneracionActions.setColaboradoresComparativoFilter({
        ...colaboradores_comparativo_filter,
        id: idEscala,
        pagination: {
          page: 1,
          pageSize: value,
        },
      })
    );
  };

  const onPaginate = (page, pageSize) => {
    if (tableRef.current) {
      tableRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
    //
    dispatch(
      remuneracionActions.setColaboradoresEscalaFilter({
        ...colaboradores_escala_filter,
        id: idEscala,
        pagination: {
          page: page,
          pageSize: pageSize,
        },
      })
    );
  };

  const onPaginateComparativo = (page, pageSize) => {
    setPageSizeComparativo(pageSize);
    setPageComparativo(page);
    //
    dispatch(
      remuneracionActions.setColaboradoresComparativoFilter({
        ...colaboradores_comparativo_filter,
        id: idEscala,
        pagination: {
          page: page,
          pageSize: pageSize,
        },
      })
    );
  };

  const onSearchColaborador = (e) => {
    const searchTerm = e.target?.value ?? "";
    dispatch(
      remuneracionActions.setColaboradoresEscalaFilter({
        ...colaboradores_escala_filter,
        id: idEscala,
        nombre: searchTerm,
      })
    );
  };
  const onSearchColaboradorComparativo = (e) => {
    const searchTerm = e.target?.value ?? "";
    dispatch(
      remuneracionActions.setColaboradoresComparativoFilter({
        ...colaboradores_comparativo_filter,
        id: idEscala,
        nombre: searchTerm,
      })
    );
  };
  const [showFinalizar, setShowFinalizar] = useState(false);
  const onFinalizar = () => {
    setShowFinalizar(true);
    dispatch(commonActions.setSelectModal({ open: true }));
  };
  const onMantenerEscala = () => {
    setShowFinalizar(false);
    dispatch(commonActions.setSelectModal({ open: false }));
    navigate(RutasRemuneracion.ESCALA);
  };
  const onActualizarEscala = () => {
    dispatch(remuneracionActions.activarEscalaSalarial({ id: idEscala }));
    dispatch(commonActions.setSelectModal({ open: false }));
    setShowFinalizar(false);
    navigate(RutasRemuneracion.ESCALA);
  };

  const [reporteLoading, setReporteLoading] = useState(false);

  const exportColaboradoresToPDF = () => {
    DownloadReport({
      token: getToken(),
      url: `/remuneracion/${idEscala}/colaboradores/exportar`,
      method: "POST",
      type: "pdf",
      data: {
        headers: reporteHeaders,
        format: "pdf",
        con_extras: activarExtras,
        categoria: currentCategoria,
      },
      name: "colaboradores-escala",
      loadingSetter: setReporteLoading,
    });
  };
  const exportColaboradoresToXLS = () => {
    DownloadReport({
      token: getToken(),
      url: `/remuneracion/${idEscala}/colaboradores/exportar`,
      method: "POST",
      type: "xls",
      data: {
        headers: reporteHeaders,
        format: "xls",
        con_extras: activarExtras,
        categoria: currentCategoria,
      },
      name: "colaboradores-escala",
      loadingSetter: setReporteLoading,
    });
  };
  const [currentCategoria, setCurrentCategoria] = useState(null);
  //
  return (
    <RemuneracionConfiguracionLayout
      title={`${nombreEscala}`}
      onClickBack={onClickBack}
      currentStep={-1}
      BarComponent={() => <BarComponent label={estadoEscala} />}
    >
      <div className="mt-8 mx-4 md:mx-12">
        {/* HEADER */}
        <div className="w-full border-b pb-2 flex justify-between items-center">
          <h3 className="font-semibold text-lg">Escala Salarial</h3>
          <button
            onClick={goToEditar}
            className="bg-sky-500 font-semibold text-white rounded-md px-3 py-3"
          >
            Editar escala salarial
          </button>
        </div>
        {/* GRILLA TABLE */}
        {!escala_reporte_loading && (
          <div className="table-container custom-scrollbar overflow-auto mt-4">
            <table className="w-full">
              <thead>
                <tr>
                  <th className="text-left p-4">Categorías</th>
                  {!!grilla &&
                    grilla[0]?.salarios?.length > 0 &&
                    grilla[0]?.salarios?.map((salario, nidx) => (
                      <th key={`cat-th-${nidx}`} className="text-left p-4">
                        {obtenerLetrasColumna(nidx + 1)}
                      </th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {grillaLocal.length > 0 &&
                  grillaLocal.map((categoria, cidx) => (
                    <tr
                      key={`e-cat-tr-${cidx}`}
                      className="odd:bg-slate-200 even:bg-white rounded-md"
                    >
                      <td className="text-left p-4 rounded-tl-md rounded-bl-md">
                        {categoria.nombre}
                      </td>
                      {categoria.salarios?.map((salario, sidx) => (
                        <td
                          key={`salario-td-${sidx}`}
                          className={`text-left p-4 ${
                            sidx === categoria.salarios.length - 1
                              ? "rounded-tr-md rounded-br-md"
                              : ""
                          }`}
                        >
                          Gs. {formatNumber(salario)}
                        </td>
                      ))}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}
        {/* GRILLA LOADING */}
        {!!escala_reporte_loading && (
          <div>
            <table className="w-full">
              <thead>
                <tr>
                  <th className="text-left p-4">Categorías</th>
                  <th className="text-left p-4">A</th>
                  <th className="text-left p-4">B</th>
                  <th className="text-left p-4">C</th>
                </tr>
              </thead>
              <tbody>
                {[1, 2, 3].map((l, lidx) => (
                  <tr key={`l-tr-${lidx}`}>
                    <td className="py-4 px-1">
                      <div className="w-fullx h-4 bg-slate-200 animate-pulse rounded-md"></div>
                    </td>
                    <td className="px-1">
                      <div className="w-full h-4 bg-slate-200 animate-pulse rounded-md"></div>
                    </td>
                    <td className="px-1">
                      <div className="w-full h-4 bg-slate-200 animate-pulse rounded-md"></div>
                    </td>
                    <td className="px-1">
                      <div className="w-full h-4 bg-slate-200 animate-pulse rounded-md"></div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {/* REPORTE COMPARATIVO */}
        <div className="mt-4">
          <h3 className="font-semibold text-lg border-b pb-2 mb-4">
            Reporte comparativo
          </h3>
          {!escala_reporte_loading && (
            <div className="flex gap-4">
              <CheckItem
                label={"Activar salarios del mercado"}
                checked={activarSalariosMercado}
                onChange={onActivarSalarios}
              />
              <CheckItem
                label={"Ver extras salariales"}
                checked={activarExtras}
                onChange={onActivarExtras}
              />
            </div>
          )}
          <div className="border rounded-md p-4 my-4">
            {/* Tabs de Categorias */}
            <CategoriasTabs
              defaultItem={totales}
              categorias={categorias}
              showMercados={activarSalariosMercado}
              setTodosIsSelected={setTodosIsSelected}
              loading={escala_reporte_loading}
              filterFn={addFilter("categoria")}
              setCurrentCategoria={setCurrentCategoria}
            />
            {/* Tabla de colaboradores */}
            <div ref={tableRef} className="mt-10">
              <div className="w-full flex flex-col md:flex-row justify-between gap-4">
                <div className="flex gap-2 items-center w-full md:w-2/3">
                  <SearchInput
                    placeholder={"Buscar por nombre o cargo..."}
                    value={nombreColaborador}
                    onChange={onSearchColaborador}
                  />
                  <div className="flex gap-2 items-center">
                    <label className="font-semibold" htmlFor="pageSize">
                      Mostrar
                    </label>
                    <select
                      name="pageSize"
                      id="pageSize"
                      className="border rounded-md p-2"
                      onChange={onChangePageSize}
                    >
                      <option value="10">10</option>
                      <option value="50" selected>
                        50
                      </option>
                      <option value="100">100</option>
                    </select>
                  </div>
                </div>
                {/*  */}
                {!reporteLoading && (
                  <div className="flex gap-2 items-center">
                    <button
                      className="bg-red-500 text-white font-semibold rounded-md px-4 py-2 flex items-center"
                      onClick={() => exportColaboradoresToPDF()}
                    >
                      <FilePdf className="mr-2" size={22} />
                      Exportar PDF
                    </button>
                    <button
                      className="bg-green-600 text-white font-semibold rounded-md px-4 py-2 flex items-center"
                      onClick={() => exportColaboradoresToXLS()}
                    >
                      <FileExcel className="mr-2" size={22} />
                      Exportar XLS
                    </button>
                  </div>
                )}
                {!!reporteLoading && (
                  <div className="flex items-center">
                    <LoadingSpinButton
                      className="w-full justify-center"
                      message="Generando reporte..."
                    />
                  </div>
                )}
                {/*  */}
              </div>
              {/*  */}
              <div className="flex overflow-auto h-[500px] custom-scrollbar">
                <DataTable
                  containerClass={"my-8 w-full"}
                  data={colaboradoresEscala}
                  loading={colaboradores_escala_loading}
                  config={datatableConfig}
                  noHeaders={false}
                  fixedColumns={1}
                  // pagination
                  totalRecords={colaboradoresCount}
                  onPaginate={onPaginate}
                  outerPageSize={PageSize}
                  // check config
                  checkable={false}
                />
              </div>
            </div>
          </div>
        </div>
        {/* REPORTE COMPARATIVO COLABORADOR */}
        <div className="mt-8 w-full mb-8">
          <div className="">
            <div className="flex justify-between items-center mb-8">
              <h3 className="text-lg font-semibold">
                Reporte comparativo por colaborador
              </h3>
            </div>
            {/*  */}
            <div className="mb-8">
              <div className="w-full flex flex-col md:flex-row justify-between items-center gap-4 md:gap-1">
                <div className="flex flex-col justify-between gap-4 w-full md:w-1/2">
                  <div className="flex gap-2">
                    <SearchInput
                      containerClass={`w-1/2`}
                      placeholder={"Buscar por nombre o cargo..."}
                      value={nombreComparativo}
                      onChange={onSearchColaboradorComparativo}
                    />
                    <MostrarPaginas
                      containerClass={"w-1/2x"}
                      onChangePageSize={onChangePageSizeComparativo}
                    />
                  </div>
                  {/* /// */}
                  <div className="flex justify-between flex-col md:flex-row gap-4 md:gap-1">
                    {/*  */}
                    <div className="flex justify-between items-center gap-2">
                      <CheckItem
                        label={"Salarios del mercado"}
                        checked={activarSalariosMercadoComparativo}
                        onChange={onActivarSalariosComparativo}
                      />
                      <CheckItem
                        label={"Extras salariales"}
                        checked={activarExtrasComparativo}
                        onChange={onActivarExtrasComparativo}
                      />
                    </div>
                    {/*  */}
                    <CategoriasFilters
                      categorias={categorias}
                      filterFn={addFilterComparativo("categoria")}
                    />
                  </div>
                </div>
                <div className="w-full md:w-1/2 flex gap-4 flex-col items-end justify-end">
                  {/*  */}
                  <div className="flex gap-2">
                    {/* <button
                      className="bg-red-500 text-white font-semibold rounded-md px-4 py-2 flex items-center"
                      // onClick={() => exportToPDF()}
                    >
                      <FilePdf className="mr-2" size={22} />
                      Exportar PDF
                    </button> */}
                  </div>
                  {/*  */}
                  <div className="flex items-center gap-2">
                    <div className="bg-sky-500 bg-opacity-50 p-2 rounded-md">
                      Rango de la empresa
                    </div>
                    <div className="bg-yellow-500 bg-opacity-50 p-2 rounded-md">
                      Rango del mercado
                    </div>
                    <div className="bg-slate-200 bg-opacity-50 p-2 rounded-md flex items-center gap-2">
                      <div className="h-3 w-3 rounded-full bg-blue-500"></div>
                      <span>Salario actual</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*  */}
            <ComparativoColaborador
              colaboradores={colaboradoresComparativo}
              rangos={generarArrayConSalto(0, 50000000, 5000000)}
              salarioField={
                !!activarExtrasComparativo ? "salario_total" : "salario_fijo"
              }
              loading={colaboradores_comparativo_loading}
              showMercados={activarSalariosMercadoComparativo}
              // pagination
              totalRecords={colaboradoresComparativoCount}
              onPaginate={onPaginateComparativo}
              outerPageSize={PageSizeComparativo}
            />
          </div>
        </div>
      </div>
      <SelectModal
        title={"Activar escala"}
        titleClass={"text-2xl"}
        extraClass={`${!!showFinalizar ? "w-6/12" : "w-8/12"} px-4`}
        content={
          <FinalizarConfiguracion
            onSecondaryClick={onMantenerEscala}
            onPrimaryClick={onActualizarEscala}
          />
        }
      />
    </RemuneracionConfiguracionLayout>
  );
};

export default RemuneracionEscalaDetalle;
