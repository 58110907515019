import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NominaFixTable from "src/modules/colaborador/components/NominaFixTable";
import CollapseCard from "src/modules/common/components/CollapseCard";
import FileInput from "src/modules/common/components/FileInput";
import LoadingSpinButton from "src/modules/common/components/LoadingSpinButton";
import { ProfileInfoCard } from "src/modules/common/components/ProfileInfoCard";
import SelectModal from "src/modules/common/components/SelectModal";
import { SuccessToast } from "src/modules/common/components/SuccessToast";
import TableCustom from "src/modules/common/components/TableCustom";
import TableRow from "src/modules/common/components/TableRow";
import { commonActions } from "src/modules/common/handler/redux";
import { areaActions } from "src/modules/estructura/modules/area/handlers/redux";
import { cargoActions } from "src/modules/estructura/modules/cargo/handlers/redux";
import { departamentoActions } from "src/modules/estructura/modules/departamento/handlers/redux";
import { DownloadPlantilla } from "src/modules/estructura/modules/estructura/handlers/utils";
import { sucursalActions } from "src/modules/estructura/modules/sucursal/handlers/redux";
import { getToken } from "src/utils/helpers";
import { colaboradorActions } from "../handlers/redux";
import WeekdaySelector from "src/modules/common/components/WeekdaySelector";
import { PrimaryButton } from "src/modules/common/components/PrimaryButton";

const CHECKED_STATES = {
  SUCURSALES: "sucursales",
  AREAS: "areas",
  DEPARTAMENTOS: "departamentos",
  CARGOS: "cargos",
  ROLES: "roles",
  JEFES: "jefes",
  DIAS_HABILES: "diasHabiles",
}

const ItemCommonComponent = ({ item }) => {
  // const dispatch = useDispatch();

  return (
    <>
      <TableRow>
        <div
          className="p-4 flex gap-8 items-start justify-start cursor-pointer"
          onClick={() => { }}
        >
          <div className="flex flex-col">
            <div className="font-bold">{item.nombre || item.name}</div>
            <div className="text-sm">{item.descripcion}</div>
          </div>
        </div>
      </TableRow>
    </>
  );
};

const ItemJefeComponent = ({ item }) => {
  return (
    <>
      <TableRow>
        <div
          className="p-4 flex gap-8 items-start justify-start cursor-pointer"
          onClick={() => { }}
        >
          <ProfileInfoCard
            name={`${item.nombre} ${item.apellido}`}
            description={
              item.cargo?.nombre ? `Cargo: ${item.cargo?.nombre}` : ""
            }
            image={item.foto_empleado ? item.foto_empleado : ""}
          ></ProfileInfoCard>
        </div>
      </TableRow>
    </>
  );
};

function ColaboradorListPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = getToken();
  const scrollRef = useRef(null);
  const colaboradores = useSelector(
    (state) => state?.colaborador?.colaboradores
  );
  const link_invitacion = useSelector(
    (state) => state?.colaborador?.link_invitacion
  );
  const errores_importacion = useSelector(
    (state) => state?.colaborador?.errores_importacion
  );
  const sucursalesResults = useSelector((state) => state.sucursal.sucursales);
  const areasResults = useSelector((state) => state.area.areas);
  const departamentosResults = useSelector(
    (state) => state.departamento.departamentos
  );
  const cargosResults = useSelector((state) => state.cargo.cargos);
  const jefesResults = useSelector((state) => state.colaborador.jefes) || [];
  const rolesResults = useSelector((state) => state.colaborador.roles) || [];
  const colaboradores_filter = useSelector(
    (state) => state.colaborador.colaboradores_filter
  );
  const modalTableConfig = useSelector(
    (state) => state.colaborador.modalTableConfig
  );
  const importar_loading = useSelector(
    (state) => state.colaborador.importar_loading
  );
  const { results: sucursales = [] } = sucursalesResults;
  const { results: areas = [] } = areasResults;
  const { results: departamentos = [] } = departamentosResults;
  const { results: cargos = [] } = cargosResults;
  const { results: jefes = [] } = jefesResults || [];
  const { results: roles = [] } = rolesResults || [];

  const scrollToBottom = () => {
    scrollRef.current?.scrollIntoView({ behavior: "instant" });
  };

  const addButtonHandler = () => {
    dispatch(colaboradorActions.colaboradorSet({}));
    navigate("/nomina/colaborador");
  };

  useEffect(() => {
    const { checkedState = "" } = modalTableConfig
    if (sucursales.length < 1 && checkedState === CHECKED_STATES.SUCURSALES) {
      dispatch(sucursalActions.sucursales());
    }
    if (areas.length < 1 && checkedState === CHECKED_STATES.AREAS) {
      dispatch(areaActions.areas());
    }
    if (departamentos.length < 1 && checkedState === CHECKED_STATES.DEPARTAMENTOS) {
      dispatch(departamentoActions.departamentos());
    }
    if (cargos.length < 1 && checkedState === CHECKED_STATES.CARGOS) {
      dispatch(cargoActions.cargos());
    }
    if (jefes.length < 1 && checkedState === CHECKED_STATES.JEFES) {
      dispatch(colaboradorActions.jefes());
    }
    if (roles.length < 1 && checkedState === CHECKED_STATES.ROLES) {
      dispatch(colaboradorActions.roles());
    }
    // CHECKED_STATES.DIAS_HABILES: Para días hábiles no necesito consultar nada de la BD, porque los días a seleccionar ya vienen en el componente y nunca cambian (siempre van a ser Lunes, Martes, etc.)
  }, [modalTableConfig.checkedState]);

  useEffect(() => {
    dispatch(commonActions.setTitle("Nómina"));
    dispatch(commonActions.showSidebar(true));
    // dispatch(colaboradorActions.colaboradores());
    dispatch(colaboradorActions.resetCheckColaboradores());
    dispatch(colaboradorActions.setErroresImportacion([]));
    dispatch(
      commonActions.setSecondBar({
        hasSecondBar: false,
        secondBarTitle: "",
        secondBarBackRoute: "",
      })
    );
    //
    dispatch(colaboradorActions.setLinkInvitacion(null));
  }, []);

  useEffect(() => {
    if (!link_invitacion) {
      return;
    }
    window.focus();
    navigator.clipboard
      .writeText(link_invitacion)
      .then(() => {
        setTimeout(() => {
          SuccessToast({
            message: "Link de invitacion copiado al portapapeles",
          });
        }, 1000);
      })
      .catch((err) => {
        console.error("Error al copiar el texto:", err);
      });
  }, [link_invitacion]);

  useEffect(() => {
    if (errores_importacion.length > 0) scrollToBottom();
  }, [errores_importacion]);

  const onDelete = (id) => {
    dispatch(colaboradorActions.colaboradorDelete({ id: id }));
  };
  // ========== //
  const asignarSucursal = (item) => {
    dispatch(sucursalActions.sucursales());
    dispatch(
      colaboradorActions.checkColaborador({
        colaborador: item,
        checked: true,
      })
    );
    dispatch(commonActions.setSelectModal({ open: true }));
    dispatch(
      colaboradorActions.setModalTableConfig({
        tableSecondTitle: "Sucursales",
        addBtnClickHandler: () =>
          dispatch(colaboradorActions.asignarSucursal()),
        checkedState: "sucursales",
        onCheckHandler: (event, item) => {
          dispatch(sucursalActions.resetCheckSucursales());
          dispatch(
            sucursalActions.checkSucursal({
              sucursal: item,
              checked: event.target.checked,
            })
          );
        },
      })
    );
  };
  const asignarArea = (item) => {
    dispatch(
      colaboradorActions.checkColaborador({
        colaborador: item,
        checked: true,
      })
    );
    dispatch(commonActions.setSelectModal({ open: true }));
    dispatch(
      colaboradorActions.setModalTableConfig({
        tableSecondTitle: "Áreas",
        addBtnClickHandler: () => dispatch(colaboradorActions.asignarArea()),
        checkedState: "areas",
        onCheckHandler: (event, item) => {
          dispatch(areaActions.resetCheckAreas());
          dispatch(
            areaActions.checkArea({
              area: item,
              checked: event.target.checked,
            })
          );
        },
      })
    );
  };
  const asignarDepartamento = (item) => {
    dispatch(
      colaboradorActions.checkColaborador({
        colaborador: item,
        checked: true,
      })
    );
    dispatch(commonActions.setSelectModal({ open: true }));
    dispatch(
      colaboradorActions.setModalTableConfig({
        tableSecondTitle: "Departamentos",
        addBtnClickHandler: () =>
          dispatch(colaboradorActions.asignarDepartamento()),
        checkedState: "departamentos",
        onCheckHandler: (event, item) => {
          dispatch(departamentoActions.resetCheckDepartamentos());
          dispatch(
            departamentoActions.checkDepartamento({
              departamento: item,
              checked: event.target.checked,
            })
          );
        },
      })
    );
  };
  const asignarCargo = (item) => {
    dispatch(
      colaboradorActions.checkColaborador({
        colaborador: item,
        checked: true,
      })
    );
    dispatch(commonActions.setSelectModal({ open: true }));
    dispatch(
      colaboradorActions.setModalTableConfig({
        tableSecondTitle: "Cargos",
        addBtnClickHandler: () => dispatch(colaboradorActions.asignarCargo()),
        checkedState: "cargos",
        onCheckHandler: (event, item) => {
          dispatch(cargoActions.resetCheckCargos());
          dispatch(
            cargoActions.checkCargo({
              cargo: item,
              checked: event.target.checked,
            })
          );
        },
      })
    );
  };
  const asignarRol = (item) => {
    dispatch(
      colaboradorActions.checkColaborador({
        colaborador: item,
        checked: true,
      })
    );
    dispatch(commonActions.setSelectModal({ open: true }));
    dispatch(
      colaboradorActions.setModalTableConfig({
        tableSecondTitle: "Roles",
        addBtnClickHandler: () => dispatch(colaboradorActions.asignarRol()),
        checkedState: "roles",
        onCheckHandler: (event, item) => {
          dispatch(colaboradorActions.resetCheckRoles());
          dispatch(
            colaboradorActions.checkRol({
              rol: item,
              checked: event.target.checked,
            })
          );
        },
      })
    );
  };
  const asignarJefe = (item) => {
    dispatch(
      colaboradorActions.checkColaborador({
        colaborador: item,
        checked: true,
      })
    );
    dispatch(commonActions.setSelectModal({ open: true }));
    dispatch(
      colaboradorActions.setModalTableConfig({
        tableSecondTitle: "Jefes directos",
        addBtnClickHandler: () => dispatch(colaboradorActions.asignarJefe()),
        checkedState: "jefes",
        onCheckHandler: (event, item) => {
          dispatch(colaboradorActions.resetCheckJefes());
          dispatch(
            colaboradorActions.checkJefe({
              colaborador: item,
              checked: event.target.checked,
            })
          );
        },
      })
    );
  };
  const enviarInvitacionIndividual = (colaborador) => {
    let deseaEnviar = window.confirm(
      "Desea enviar la invitación para el Colaborador seleccionado?"
    );
    if (deseaEnviar) {
      dispatch(colaboradorActions.colaboradorSet(colaborador));
      dispatch(colaboradorActions.colaboradorAccesoSet(colaborador));
      dispatch(
        colaboradorActions.colaboradorAccesoInvitacion({
          forzarBienvenida: true,
        })
      );
    }
  };
  const copiarLinkInvitacion = (colaborador) => {
    setTimeout(() => {
      dispatch(colaboradorActions.colaboradorSet(colaborador));
      dispatch(colaboradorActions.colaboradorAccesoSet(colaborador));
      dispatch(
        colaboradorActions.colaboradorAccesoInvitacion({
          forzarBienvenida: true,
          retornarLink: true,
        })
      );
    }, 1800);
  };
  // ========== //
  const dropdownActions = (id, item = {}) => [
    {
      label: "Enviar invitacion",
      action: () => enviarInvitacionIndividual(item),
    },
    {
      label: "Copiar link invitacion",
      action: () => copiarLinkInvitacion(item),
    },
    { label: "Asignar Rol", action: () => asignarRol(item) },
    { label: "Asignar Sucursal", action: () => asignarSucursal(item) },
    { label: "Asignar Área", action: () => asignarArea(item) },
    { label: "Asignar Departamento", action: () => asignarDepartamento(item) },
    { label: "Asignar Cargo", action: () => asignarCargo(item) },
    { label: "Asignar Jefe ", action: () => asignarJefe(item) },
    //
    {
      label: "Eliminar",
      action: () => {
        let deseaEliminar = window.confirm(
          `Desea eliminar al colaborador ${item.nombre} ${item.apellido}?`
        );
        if (!!deseaEliminar) {
          onDelete(id);
        }
      },
      className: "text-red-500",
    },
  ];

  const uploadFile = (file) => {
    dispatch(colaboradorActions.setFileToUpload(file));
  };

  const onPlantilla = () => {
    const plantilla = "nomina";
    DownloadPlantilla({ plantilla, token });
  };

  const uploadFileGeneral = (file) => {
    dispatch(colaboradorActions.setImportarDestino("general"));
    dispatch(colaboradorActions.setFileToUploadGeneral(file));
  };

  const onPlantillaGeneral = () => {
    const plantilla = "general";
    DownloadPlantilla({ plantilla, token });
  };

  const uploadFileGeneralFull = (file) => {
    dispatch(colaboradorActions.setImportarDestino("general-full"));
    dispatch(colaboradorActions.setFileToUploadGeneralFull(file));
  };

  const onPlantillaGeneralFull = () => {
    const plantilla = "general-full";
    DownloadPlantilla({ plantilla, token });
  };

  const setOrdering =
    (field) =>
      (order = "") => {
        let { ordering = "" } = colaboradores_filter;
        let orderingArray = ordering.split(",") || [];
        let emptyIndex = orderingArray.indexOf("");
        if (emptyIndex) {
          orderingArray.splice(emptyIndex, 1);
        }
        let fieldIndex = orderingArray.indexOf(field);
        fieldIndex =
          fieldIndex === -1 ? orderingArray.indexOf(`-${field}`) : fieldIndex;
        // si ya esta el campo, vemos si se modifica o elimina
        !!process.env.REACT_APP_DEBUG &&
          console.info("S==== setOrdering", ordering, orderingArray, fieldIndex);
        if (fieldIndex > 0) {
          orderingArray.splice(fieldIndex, 1);
          if (order) {
            field = order === "desc" ? `-${field}` : field;
            orderingArray[fieldIndex] = field;
          }
        } else {
          // si no está, lo agregamos
          field = order === "desc" ? `-${field}` : field;
          orderingArray.push(field);
          !!process.env.REACT_APP_DEBUG &&
            console.info("orderingArray 2", orderingArray);
        }
        ordering = orderingArray.join(",");
        //
        dispatch(
          colaboradorActions.setColaboradoresFilter({
            ...colaboradores_filter,
            ordering: ordering,
          })
        );
      };

  const setPagination = (page, pageSize) => {
    //
    dispatch(
      colaboradorActions.setColaboradoresFilter({
        ...colaboradores_filter,
        pagination: {
          page: page,
          pageSize: pageSize,
        },
      })
    );
  };

  function addFilterFilter(field) {
    return (value) => {
      dispatch(
        colaboradorActions.setColaboradoresFilter({
          ...colaboradores_filter,
          [`${field}`]: value,
        })
      );
    };
  }

  const headers = [
    { text: "Colaboradores", onOrder: setOrdering("nombre"), onFilter: false },
    {
      text: "Jefe directo",
      onOrder: setOrdering("jefe_directo__nombre"),
      onFilter: addFilterFilter("jefeNombre"),
    },
    {
      text: "Rol",
      onOrder: setOrdering("usuario_empresa__login__groups__name"),
      onFilter: addFilterFilter("rol"),
    },
    {
      text: "Cédula de identidad",
      onOrder: setOrdering("ci"),
      onFilter: addFilterFilter("ci"),
    },
    {
      text: "Email",
      onOrder: setOrdering("email"),
      onFilter: addFilterFilter("email"),
    },
    {
      text: "Sucursal",
      onOrder: setOrdering("sucursal__nombre"),
      onFilter: addFilterFilter("sucursalNombre"),
    },
    {
      text: "Área",
      onOrder: setOrdering("area__nombre"),
      onFilter: addFilterFilter("areaNombre"),
    },
    {
      text: "Departamento",
      onOrder: setOrdering("departamento__nombre"),
      onFilter: addFilterFilter("departamentoNombre"),
    },
    { text: "Invitación", onOrder: false, onFilter: false },
  ];

  const { results: data, count: colaboradoresCount = 0 } = colaboradores;

  // ESTADOS PARA LAS TABLAS DEL MODAL DE ASIGNACION
  const sucursales_checked = useSelector(
    (state) => state.sucursal.sucursales_checked
  );
  const areas_checked = useSelector((state) => state.area.areas_checked);
  const departamentos_checked = useSelector(
    (state) => state.departamento.departamentos_checked
  );
  const cargos_checked = useSelector((state) => state.cargo.cargos_checked);
  const jefes_checked =
    useSelector((state) => state.colaborador.jefes_checked) || [];
  const dias_habiles_checked =
    useSelector((state) => state.colaborador.dias_habiles_checked) || [];
  const roles_checked =
    useSelector((state) => state.colaborador.roles_checked) || [];
  // filters
  const sucursales_filter = useSelector(
    (state) => state.sucursal.sucursales_filter
  );
  const areas_filter = useSelector((state) => state.area.areas_filter);
  const departamentos_filter = useSelector(
    (state) => state.departamento.departamentos_filter
  );
  const cargos_filter = useSelector((state) => state.cargo.cargos_filter);
  const jefes_filter = useSelector((state) => state.colaborador.jefes_filter);
  const { nombre: nombre_sucursal = "" } = sucursales_filter;
  const { nombre: nombre_area = "" } = areas_filter;
  const { nombre: nombre_departamento = "" } = departamentos_filter;
  const { nombre: nombre_cargo = "" } = cargos_filter;
  const { nombre: nombre_jefe = "" } = jefes_filter;
  //
  const colaboradores_loading = useSelector(
    (state) => state.colaborador.colaboradores_loading
  );
  //
  const asignacion_loading = useSelector(
    (state) => state.colaborador.asignacion_loading
  );
  const colaborador_acceso_invitacion_loading = useSelector(
    (state) => state.colaborador.colaborador_acceso_invitacion_loading
  );

  const onChangeSucursalText = (event) => {
    dispatch(
      sucursalActions.setSucursalesFilter({ nombre: event.target.value })
    );
  };
  const onChangeAreaText = (event) => {
    dispatch(areaActions.setAreasFilter({ nombre: event.target.value }));
  };
  const onChangeDepartamentoText = (event) => {
    dispatch(
      departamentoActions.setDepartamentosFilter({ nombre: event.target.value })
    );
  };
  const onChangeCargoText = (event) => {
    dispatch(cargoActions.setCargosFilter({ nombre: event.target.value }));
  };
  const onChangeJefeText = (event) => {
    dispatch(colaboradorActions.setJefesFilter({ nombre: event.target.value }));
  };
  const onDayToggle = (name, index, value) => {
    console.log(name, index, value);
    let dias_habiles = JSON.parse(JSON.stringify(dias_habiles_checked)) || [];
    if (name === "dia") {
      if (value) {
        // Si value es true y el día no está en dias_habiles, agregarlo con 0 horas
        if (!dias_habiles.some(dia => dia.dia === index)) {
          dias_habiles.push({ dia: index, cantidad_horas: 0 });
        }
      } else {
        // Si value es false, eliminar el día de dias_habiles
        dias_habiles = dias_habiles.filter(dia => dia.dia !== index);
      }
    } else if (name === "cantidad_horas") {
      // Buscar el día y actualizar la cantidad de horas
      const diaIndex = dias_habiles.findIndex(dia => dia.dia === index);
      if (diaIndex !== -1) {
        dias_habiles[diaIndex].cantidad_horas = value;
      }
    }
    dispatch(
      colaboradorActions.setCheckDiasHabiles(dias_habiles)
    );
  }
  // const { results: sucursales } = sucursalesResults;
  const getModalTable = () => {
    const commonProps = {
      ItemComponent: ItemCommonComponent,
      subject: "colaborador",
      subjectGender: "M",
      hasAddBtn: true,
      customAddBtnLabel: "Asignar",
      noBottomButtom: true,
      selectOptions: [],
    };
    let tables = {
      sucursales: (
        <TableCustom
          {...modalTableConfig}
          {...commonProps}
          data={sucursales}
          searchValue={nombre_sucursal}
          onChangeSearch={onChangeSucursalText}
          itemsChecked={sucursales_checked}
          fixedHeigth={true}
          multiple={false}
          checkType={"radio"}
        />
      ),
      areas: (
        <TableCustom
          {...modalTableConfig}
          {...commonProps}
          data={areas}
          searchValue={nombre_area}
          onChangeSearch={onChangeAreaText}
          itemsChecked={areas_checked}
          multiple={false}
          fixedHeigth={true}
          checkType={"radio"}
        />
      ),
      departamentos: (
        <TableCustom
          {...modalTableConfig}
          {...commonProps}
          data={departamentos}
          searchValue={nombre_departamento}
          onChangeSearch={onChangeDepartamentoText}
          itemsChecked={departamentos_checked}
          multiple={false}
          fixedHeigth={true}
          checkType={"radio"}
        />
      ),
      cargos: (
        <TableCustom
          {...modalTableConfig}
          {...commonProps}
          data={cargos}
          searchValue={nombre_cargo}
          onChangeSearch={onChangeCargoText}
          itemsChecked={cargos_checked}
          multiple={false}
          fixedHeigth={true}
          checkType={"radio"}
        />
      ),
      jefes: (
        <TableCustom
          {...modalTableConfig}
          {...commonProps}
          ItemComponent={ItemJefeComponent}
          data={jefes || []}
          searchValue={nombre_jefe}
          onChangeSearch={onChangeJefeText}
          itemsChecked={jefes_checked}
          multiple={false}
          fixedHeigth={true}
          checkType={"radio"}
        />
      ),
      roles: (
        <TableCustom
          {...modalTableConfig}
          {...commonProps}
          data={roles || []}
          checkType={"radio"}
          itemsChecked={roles_checked}
          fixedHeigth={true}
          multiple={false}
        />
      ),
      diasHabiles: (
        <div>
          <WeekdaySelector
            editable={true}
            selectedDays={dias_habiles_checked}
            onDayToggle={onDayToggle}
          />
          <div className="flex justify-center mt-4">
            <PrimaryButton className="bg-sky-500 w-1/2" onClick={modalTableConfig.addBtnClickHandler}>
              Guardar
            </PrimaryButton>
          </div>
        </div>
      )
    };
    return tables[modalTableConfig.checkedState];
  };

  return (
    <div className="flex flex-col">
      <div className="w-full overflow-autox">
        {(!!asignacion_loading ||
          !!colaborador_acceso_invitacion_loading ||
          !!colaboradores_loading) && (
            <div className="flex items-center justify-center h-screen">
              <LoadingSpinButton></LoadingSpinButton>
            </div>
          )}
        {!asignacion_loading &&
          !colaborador_acceso_invitacion_loading &&
          !colaboradores_loading && (
            <NominaFixTable
              data={data}
              headers={headers}
              extraClass={"mt-8"}
              actionButtonHandler={addButtonHandler}
              dropdownActions={dropdownActions}
              totalPaginate={colaboradoresCount}
              onPaginate={setPagination}
            ></NominaFixTable>
          )}
      </div>
      {!importar_loading && (
        <div className="flex w-full mb-4">
          <FileInput
            label={"Importador parcial"}
            className={"mt-3 w-3/4"}
            onSubmit={uploadFileGeneral}
            onPlantilla={onPlantillaGeneral}
          />
          <FileInput
            label={"Importador general"}
            className={"mt-3 w-3/4"}
            onSubmit={uploadFileGeneralFull}
            onPlantilla={onPlantillaGeneralFull}
          />
        </div>
      )}
      {!!importar_loading && (
        <div className="flex items-center justify-center h-20">
          <LoadingSpinButton message="Verificando datos, por favor espere..."></LoadingSpinButton>
        </div>
      )}
      {errores_importacion.length > 0 && (
        <CollapseCard
          titulo={"Ver errores"}
          extraClass="mb-8"
          titleClass={"text-red-500 font-semibold"}
          initCollapse={false}
        >
          <div className="flex flex-col gap-2">
            {errores_importacion.map((err, idx) => (
              <div className="p-1 text-red-400">{err}</div>
            ))}
          </div>
        </CollapseCard>
      )}
      <div ref={scrollRef}></div>
      <SelectModal
        title={`Asignar ${modalTableConfig.tableSecondTitle}`}
        content={getModalTable()}
      ></SelectModal>
    </div>
  );
}
export default ColaboradorListPage;
