import React, { useEffect, useState } from 'react'
import EncuestaReportePage from './EncuestaReportePage'
import { useDispatch, useSelector } from 'react-redux'
import { encuestaActions } from '../handlers/redux'
import { useParams } from 'react-router-dom'
import DataTable from 'src/modules/common/components/DataTable'
import userDefault from "src/modules/common/components/images/user_default.png";
import SearchInput from 'src/modules/remuneracion/components/SearchInput'
import MostrarPaginas from 'src/modules/common/components/MostrarPaginas'
import ReactSelect from 'react-select'

const EncuestaReporteColaboradoresSinResponder = () => {
    const dispatch = useDispatch()
    const { encuestaId } = useParams();

    const reporte_colaboradores_sin_respuesta = useSelector((state) => state.encuesta.reporte_colaboradores_sin_respuesta);
    const reporte_colaboradores_sin_respuesta_loading = useSelector((state) => state.encuesta.reporte_colaboradores_sin_respuesta_loading);
    const reporte_colaboradores_sin_respuesta_count = useSelector((state) => state.encuesta.reporte_colaboradores_sin_respuesta_count);
    const reporte_colaboradores_sin_respuesta_filter = useSelector((state) => state.encuesta.reporte_colaboradores_sin_respuesta_filter);
    const reporte_colaboradores_sin_respuesta_checked = useSelector((state) => state.encuesta.reporte_colaboradores_sin_respuesta_checked);
    const reenviar_notificacion_encuesta_loading = useSelector((state) => state.encuesta.reenviar_notificacion_encuesta_loading);

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const { nombre = "" } = reporte_colaboradores_sin_respuesta_filter;

    useEffect(() => {
        dispatch(encuestaActions.getReporteColaboradoresSinRespuesta({ encuestaId }))
        dispatch(encuestaActions.setReporteColaboradoresSinRespuestaChecked([]))
        dispatch(encuestaActions.setTitleReporte("Colaboradores sin responder"))
    }, [])

    const datatableConfig = {
        headers: [
            {
                title: "",
                contentClass: "px-2 py-4",
                contentRenderer: (item) => (
                    <div
                        className="flex items-center cursor-pointer gap-2"
                    >
                        <img
                            src={item.empleado.foto_empleado || userDefault}
                            className="h-12 w-12 rounded"
                        />
                        <div className="flex flex-col flex-1">
                            <p className="font-semibold whitespace-normal">
                                {item.empleado.nombre} {item.empleado.apellido}
                            </p>
                            <p className="text-sm whitespace-normal">
                                {item.empleado.cargo ? item.empleado.cargo.nombre : "Sin cargo"}
                            </p>
                        </div>
                    </div>
                ),
            },
        ],
        rowActions: [],
    };

    const onSearch = (e) => {
        let searchText = e.target.value;
        dispatch(
            encuestaActions.setReporteColaboradoresSinRespuestaFilter({
                ...reporte_colaboradores_sin_respuesta_filter,
                encuestaId,
                nombre: searchText,
            })
        );
    };

    const onChangePageSize = (e) => {
        let value = e.target.value;
        dispatch(
            encuestaActions.setReporteColaboradoresSinRespuestaFilter({
                ...reporte_colaboradores_sin_respuesta_filter,
                encuestaId,
                pagination: {
                    pageSize: parseInt(value),
                    page: 1,
                },
            })
        );
        setPageSize(parseInt(value));
    };

    const actionOptions = [
        {
            value: "enviarNotificacion",
            label: "Reenviar notificación",
        },
    ];
    const onAction = (v) => {
        if (v.value === "enviarNotificacion") {
            const deseaEnviar = window.confirm("Desea reenviar la notificación a los colaboradores seleccionados?");
            if (deseaEnviar) {
                const asignacionesIds = reporte_colaboradores_sin_respuesta_checked.map((e) => e.id);
                dispatch(encuestaActions.reenviarNotificacionEncuesta({ encuestaId, asignacionesIds }));
            }
            return;
        }
    };

    const onPaginate = (page, pageSize) => {
        console.log(page, pageSize);
        dispatch(
            encuestaActions.setReporteColaboradoresSinRespuestaFilter({
                ...reporte_colaboradores_sin_respuesta_filter,
                encuestaId,
                pagination: {
                    page: page,
                    pageSize: pageSize,
                },
            })
        );
    };

    const onCheckAll = () => {
        let checkedData = JSON.parse(JSON.stringify(reporte_colaboradores_sin_respuesta));
        if (reporte_colaboradores_sin_respuesta_checked.length > 0) {
            checkedData = [];
        }
        dispatch(encuestaActions.setReporteColaboradoresSinRespuestaChecked(checkedData));
    };

    const onCheckItem = (item) => (event) => {
        dispatch(
            encuestaActions.checkReporteColaboradoresSinRespuesta({
                item,
                checked: event.target.checked,
            })
        );
    };

    return (
        <EncuestaReportePage>
            <div className='flex justify-center'>
                <div className='w-2/3 my-4'>
                    <div className="w-full flex items-center mb-4 gap-4 justify-between px-6">
                        <SearchInput
                            value={nombre}
                            onChange={onSearch}
                            placeholder="Buscar por nombre o cargo"
                        />
                        <MostrarPaginas
                            containerClass={"w-1/2x"}
                            defaultOption={50}
                            onChangePageSize={onChangePageSize}
                        />
                        <ReactSelect
                            className="w-auto h-10 inline-grid text-sm"
                            value={{
                                value: "",
                                label: `(${reporte_colaboradores_sin_respuesta_checked.length}) seleccionados`,
                            }}
                            options={actionOptions}
                            onChange={onAction}
                        />
                    </div>
                    <div className='max-h-[500px] custom-scrollbar my-4'>
                        <DataTable
                            data={reporte_colaboradores_sin_respuesta}
                            loading={reporte_colaboradores_sin_respuesta_loading || reenviar_notificacion_encuesta_loading}
                            config={datatableConfig}
                            noHeaders={false}
                            // Pagination
                            totalRecords={reporte_colaboradores_sin_respuesta_count}
                            onPaginate={onPaginate}
                            outerPageSize={pageSize}
                            outerPage={page}
                            //
                            checkable={true}
                            onCheckAll={onCheckAll}
                            checkedItems={reporte_colaboradores_sin_respuesta_checked}
                            onItemCheck={onCheckItem}
                        />
                    </div>
                </div>
            </div>
        </EncuestaReportePage>
    )
}

export default EncuestaReporteColaboradoresSinResponder