import { createSlice } from "@reduxjs/toolkit";

export const encuestaDefault = {
  nombre: "",
  descripcion: "",
  fecha_inicio: null,
  fecha_fin: null,
  preguntas: [
    {
      texto: "",
      descripcion: "",
      tipo_respuesta: "TEXTO",
      opciones: [],
    },
  ],
  alcance_total: null,
  empleados_ids: [],
  etiquetas: [],
};

const encuestaSlice = createSlice({
  name: "encuesta",
  initialState: {
    encuestas: [],
    encuestas_loading: false,
    encuestas_count: 0,
    encuestas_filter: {},
    encuestas_checked: [],
    //
    encuesta: encuestaDefault,
    encuesta_loading: false,
    encuesta_previsualizar: null,
    duplicar_encuesta_loading: false,
    //
    colaboradores_seleccionables: [],
    colaboradores_seleccionables_loading: false,
    colaboradores_seleccionables_count: 0,
    colaboradores_seleccionables_checked: [],
    colaboradores_seleccionables_filter: {},
    //
    colaboradores_asignados: [],
    colaboradores_asignados_loading: false,
    //
    title_reporte: "",
    //
    reporte_general: {},
    reporte_general_loading: false,
    //
    reporte_resumen: {},
    reporte_resumen_loading: false,
    reporte_resumen_count: 0,
    reporte_resumen_filter: {},
    reporte_resumen_checked: [],
    reporte_resumen_downloading: false,
    //
    reporte_colaboradores_sin_respuesta: [],
    reporte_colaboradores_sin_respuesta_loading: false,
    reporte_colaboradores_sin_respuesta_count: 0,
    reporte_colaboradores_sin_respuesta_filter: {},
    reporte_colaboradores_sin_respuesta_checked: [],
    reenviar_notificacion_encuesta_loading: false,
    //
    reporte_colaborador_resumen: {},
    reporte_colaborador_resumen_loading: false,
    comprobante_colaborador_resumen_loading: false,
    //
    historial_etiquetas_asignadas: [],
    historial_etiquetas_asignadas_loading: false,
  },
  reducers: {
    // Sección: Encuestas
    getEncuestas: (state, { payload = {} }) => {
      state.encuestas_loading = true;
    },
    setEncuestas: (state, { payload = [] }) => {
      state.encuestas = payload;
      state.encuestas_loading = false;
    },
    setEncuestasCount: (state, { payload = 0 }) => {
      state.encuestas_count = payload;
    },
    setEncuestasFilter: (state, { payload = {} }) => {
      state.encuestas_filter = payload;
    },
    setEncuestasChecked: (state, { payload = [] }) => {
      state.encuestas_checked = payload;
    },
    resetEncuestasChecked: (state) => {
      state.encuestas_checked = [];
    },
    checkEncuesta: (state, { payload: { encuesta, checked = false } }) => {
      state.encuestas_checked = state.encuestas_checked.filter(
        (item) => item.id !== encuesta.id
      );
      if (checked) {
        state.encuestas_checked.push(encuesta);
      }
    },
    bulkUpdateEstados: (state, { payload = [] }) => {
      state.encuestas_loading = true;
    },
    bulkDeleteEncuestas: (state, { payload = [] }) => {
      state.encuestas_loading = true;
    },
    // Sección: Encuesta
    getEncuesta: (state, { payload = {} }) => {
      state.encuesta_loading = true;
    },
    getEncuestaComplete: (state, { payload = {} }) => {
      // Trae otro formato de respuesta de la encuesta nada más.
      state.encuesta_loading = true;
    },
    setEncuesta: (state, { payload = {} }) => {
      state.encuesta = payload;
      state.encuesta_loading = false;
    },
    setEncuestaLoading: (state, { payload = false }) => {
      state.encuesta_loading = payload;
    },
    createEncuesta: (state, { payload = {} }) => {
      state.encuesta_loading = true;
    },
    updateEncuesta: (state, { payload = {} }) => {
      state.encuesta_loading = true;
    },
    updateEtiquetas: (state, { payload = {} }) => {
      state.encuesta_loading = true;
    },
    deleteEtiqueta: (state, { payload = {} }) => {
      state.encuesta_loading = true;
    },
    setEncuestaPrevisualizar: (state, { payload = {} }) => {
      state.encuesta_previsualizar = payload;
    },
    duplicarEncuesta: (state, { payload = {} }) => {
      state.duplicar_encuesta_loading = true;
    },
    setDuplicarEncuestaFinished: (state, { payload = false }) => {
      state.duplicar_encuesta_loading = false;
    },
    // Sección: Colaboradores seleccionables
    getColaboradoresSeleccionables: (state, { payload = [] }) => {
      state.colaboradores_seleccionables_loading = true;
    },
    setColaboradoresSeleccionables: (state, { payload = [] }) => {
      state.colaboradores_seleccionables = payload;
      state.colaboradores_seleccionables_loading = false;
    },
    setColaboradoresSeleccionablesCount: (state, { payload = 0 }) => {
      state.colaboradores_seleccionables_count = payload;
    },
    setColaboradoresSeleccionablesFilter: (state, { payload = {} }) => {
      state.colaboradores_seleccionables_filter = payload;
      state.colaboradores_seleccionables_loading = true;
    },
    setColaboradoresSeleccionablesChecked: (state, { payload = [] }) => {
      state.colaboradores_seleccionables_checked = payload;
    },
    resetColaboradoresSeleccionablesChecked: (state) => {
      state.colaboradores_seleccionables_checked = [];
    },
    checkColaboradoresSeleccionables: (
      state,
      { payload: { item, checked = true } }
    ) => {
      let temp = state.colaboradores_seleccionables_checked.filter(
        (colaborador) => colaborador.id !== item.id
      );
      state.colaboradores_seleccionables_checked = temp;
      if (!!checked) {
        state.colaboradores_seleccionables_checked.push(item);
      }
    },
    // Sección: Colaboradores asignados
    getColaboradoresAsignados: (state, { payload = [] }) => {
      state.colaboradores_asignados_loading = true;
    },
    setColaboradoresAsignados: (state, { payload = [] }) => {
      state.colaboradores_asignados = payload;
      state.colaboradores_asignados_loading = false;
    },
    // Sección: Reportes
    setTitleReporte: (state, { payload = "" }) => {
      state.title_reporte = payload;
    },
    getReporteGeneral: (state, { payload = {} }) => {
      state.reporte_general_loading = true;
    },
    setReporteGeneral: (state, { payload = {} }) => {
      state.reporte_general = payload;
      state.reporte_general_loading = false;
    },
    //
    getReporteResumen: (state, { payload = {} }) => {
      state.reporte_resumen_loading = true;
    },
    setReporteResumen: (state, { payload = {} }) => {
      state.reporte_resumen = payload;
      state.reporte_resumen_loading = false;
    },
    setReporteResumenCount: (state, { payload = 0 }) => {
      state.reporte_resumen_count = payload;
    },
    setReporteResumenFilter: (state, { payload = {} }) => {
      state.reporte_resumen_filter = payload;
      state.reporte_resumen_loading = true;
    },
    setReporteResumenChecked: (state, { payload = [] }) => {
      state.reporte_resumen_checked = payload;
    },
    checkReporteResumen: (state, { payload: { item, checked = true } }) => {
      let temp = state.reporte_resumen_checked.filter(
        (colaborador) => colaborador.id !== item.id
      );
      state.reporte_resumen_checked = temp;
      if (!!checked) {
        state.reporte_resumen_checked.push(item);
      }
    },
    asignarEtiquetas: (state, { payload = {} }) => {
      state.reporte_resumen_loading = true;
    },
    exportarReporteResumen: (state, { payload = {} }) => {
      state.reporte_resumen_downloading = true;
    },
    exportarReporteResumenFinish: (state, { payload = {} }) => {
      state.reporte_resumen_downloading = false;
    },
    //
    getReporteColaboradoresSinRespuesta: (state, { payload = {} }) => {
      state.reporte_colaboradores_sin_respuesta_loading = true;
    },
    setReporteColaboradoresSinRespuesta: (state, { payload = {} }) => {
      state.reporte_colaboradores_sin_respuesta = payload;
      state.reporte_colaboradores_sin_respuesta_loading = false;
    },
    setReporteColaboradoresSinRespuestaCount: (state, { payload = 0 }) => {
      state.reporte_colaboradores_sin_respuesta_count = payload;
    },
    setReporteColaboradoresSinRespuestaFilter: (state, { payload = {} }) => {
      state.reporte_colaboradores_sin_respuesta_filter = payload;
      state.reporte_colaboradores_sin_respuesta_loading = true;
    },
    setReporteColaboradoresSinRespuestaChecked: (state, { payload = [] }) => {
      state.reporte_colaboradores_sin_respuesta_checked = payload;
    },
    checkReporteColaboradoresSinRespuesta: (
      state,
      { payload: { item, checked = true } }
    ) => {
      let temp = state.reporte_colaboradores_sin_respuesta_checked.filter(
        (colaborador) => colaborador.id !== item.id
      );
      state.reporte_colaboradores_sin_respuesta_checked = temp;
      if (!!checked) {
        state.reporte_colaboradores_sin_respuesta_checked.push(item);
      }
    },
    reenviarNotificacionEncuesta: (state, { payload = {} }) => {
      state.reenviar_notificacion_encuesta_loading = true;
    },
    setReenviarNotificacionEncuestaFinish: (state, { payload = {} }) => {
      state.reenviar_notificacion_encuesta_loading = false;
    },
    //
    getReporteColaboradorResumen: (state, { payload = {} }) => {
      state.reporte_colaborador_resumen_loading = true;
    },
    setReporteColaboradorResumen: (state, { payload = {} }) => {
      state.reporte_colaborador_resumen = payload;
      state.reporte_colaborador_resumen_loading = false;
    },
    descargarComprobanteRespuestasColaborador: (state, { payload = {} }) => {
      state.comprobante_colaborador_resumen_loading = true;
    },
    descargarComprobanteRespuestasColaboradorFinish: (state) => {
      state.comprobante_colaborador_resumen_loading = false;
    },
    //
    getHistorialEtiquetasAsignadas: (state, { payload = [] }) => {
      state.historial_etiquetas_asignadas_loading = true;
    },
    setHistorialEtiquetasAsignadas: (state, { payload = [] }) => {
      state.historial_etiquetas_asignadas = payload;
      state.historial_etiquetas_asignadas_loading = false;
    },
  },
});

export const encuestaActions = encuestaSlice.actions;
export const encuestaReducer = encuestaSlice.reducer;
