import { createSlice } from "@reduxjs/toolkit";

const empresaSlice = createSlice({
  name: "Empresa",
  initialState: {
    empresa_update_loading: false,
    empresa: {},
    rubros: { result: [] },
    empresa_loading: false,
    rubros_loading: false,
    empresa_rubro_update_modal: { open: false },
    empresa_nombre_update_modal: { open: false },
    //
    empresa_laboral: {},
    empresa_laboral_loading: false,
  },
  reducers: {
    empresaSet: (state, { payload = {} }) => {
      state.empresa = payload;
    },
    setEmpresa: (state, { payload }) => {
      state.empresa = payload.empresa;
      state.empresa_loading = false;
    },
    setRubros: (state, { payload }) => {
      state.rubros = payload.rubros;
      state.rubros_loading = false;
    },
    // update
    empresaUpdate: (state, { payload = {} }) => {
      state.empresa_update_loading = true;
    },
    empresaUpdateLogo: (state, { payload = {} }) => {
      state.empresa_update_loading = true;
    },
    empresaUpdateSuccess: (state) => {
      state.empresa_update_loading = false;
    },
    setEmpresaNombreModal: (state, { payload = {} }) => {
      state.empresa_nombre_update_modal = payload;
    },
    setEmpresaRubroModal: (state, { payload = {} }) => {
      state.empresa_rubro_update_modal = payload;
    },
    // list
    empresa: (state, { payload }) => {
      state.empresa_loading = true;
    },
    rubros: (state, { payload }) => {
      state.rubros_loading = true;
    },
    setHabilitarEdicionKpis: (state, { payload }) => { },
    setHabilitarEdicionObjetivosCargo: (state, { payload }) => { },
    setVisibilidadPlanCarrera: (state, { payload }) => { },
    updateAprobacionPermisos: (state, { payload }) => { },
    //
    setDeshabilitarEdicionKpis: (state, { payload }) => { },
    setDeshabilitarEdicionObjetivosCargo: (state, { payload }) => { },
    setHabilitarSuperarPuntajeEvaluacion: (state, { payload }) => { },
    setPermisosEmpresa: (state, { payload }) => { },
    //
    getEmpresaLaboral: (state, { payload }) => {
      state.empresa_laboral_loading = true;
    },
    setEmpresaLaboral: (state, { payload }) => {
      state.empresa_laboral = payload;
      state.empresa_laboral_loading = false;
    },
    createHorasPorDia: (state, { payload }) => {
      state.empresa_laboral_loading = true;
    },
    updateSalarioMinimo: (state, { payload }) => {
      state.empresa_laboral_loading = true;
    },
  },
});

export const empresaActions = empresaSlice.actions;
export const empresaReducer = empresaSlice.reducer;
