import React, { useEffect, useState } from 'react'
import { CheckLg, PencilFill, XLg } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';
import userDefault from "src/modules/common/components/images/user_default.png";
import { vacacionActions } from '../handlers/redux';

const ColaboradorVacacionDetail = ({ colaborador_vacacion }) => {
    const dispatch = useDispatch();

    const [colaboradorLocal, setColaboradorLocal] = useState({ ...colaborador_vacacion });
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        setColaboradorLocal({ ...colaborador_vacacion });
    }, [colaborador_vacacion])

    const onChange = (field) => ({ target }) => {
        let value = target.value;
        let colaboradorCopy = { ...colaboradorLocal };
        colaboradorCopy[field] = value;
        setColaboradorLocal(colaboradorCopy);
    }

    const onSubmit = () => {
        const params = {
            colaboradorId: colaboradorLocal.id,
            request: { ...colaboradorLocal }
        }
        console.log(params);
        dispatch(vacacionActions.updateColaboradorVacaciones(params));
        setIsEditing(false);
    }

    const onRestore = () => {
        setColaboradorLocal({ ...colaborador_vacacion });
        setIsEditing(false);
    }

    return (
        <div className="p-6 border rounded-2xl shadow-sm">
            <table className="w-full border-collapse">
                <thead>
                    <tr className="border-b text-md font-bold">
                        <th className="text-left py-2">Colaborador</th>
                        <th className="text-center">Antigüedad</th>
                        <th className="text-center">Causadas</th>
                        <th className="text-center">Utilizadas</th>
                        <th className="text-center">Disponibles</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="text-sm">
                        <td className="flex items-center gap-4 py-3">
                            <img
                                src={colaboradorLocal.foto_empleado || userDefault}
                                alt={`${colaboradorLocal.nombre} ${colaboradorLocal.apellido}`}
                                className="w-16 h-16 rounded-md object-cover"
                            />
                            <div>
                                <h2 className="font-bold">{colaboradorLocal.nombre} {colaboradorLocal.apellido}</h2>
                                <p className="text-gray-600">
                                    <span className="font-bold">Cargo:</span> {colaboradorLocal.cargo ? colaboradorLocal.cargo.nombre : "Sin asignar"}
                                </p>
                            </div>
                        </td>
                        <td className="text-center">
                            {colaboradorLocal.antiguedad}
                        </td>
                        <td className="text-center group">
                            <div className='flex items-center justify-center gap-2'>
                                {!isEditing && (
                                    <>
                                        <div className=''>{colaboradorLocal.dias_vacaciones_causados}</div>
                                        <div className='hidden group-hover:block'>
                                            <PencilFill
                                                size={14}
                                                className='text-sky-500 cursor-pointer'
                                                onClick={() => setIsEditing(true)}
                                            />
                                        </div>
                                    </>
                                )}
                                {isEditing && (
                                    <>
                                        <input
                                            type="number"
                                            value={colaboradorLocal.dias_vacaciones_causados}
                                            onChange={onChange("dias_vacaciones_causados")}
                                            className="text-center w-16 border rounded-sm"
                                        />
                                        <div className='flex flex-row gap-2 items-center'>
                                            <CheckLg
                                                size={20}
                                                className='text-sky-500 cursor-pointer'
                                                onClick={onSubmit}
                                            />
                                            <XLg size={16} className='cursor-pointer' onClick={onRestore} />
                                        </div>
                                    </>
                                )}
                            </div>
                        </td>
                        <td className="text-center group">
                            <div className='flex items-center justify-center gap-2'>
                                {!isEditing && (
                                    <>
                                        <div className=''>{colaboradorLocal.dias_vacaciones_utilizados}</div>
                                        <div className='hidden group-hover:block'>
                                            <PencilFill
                                                size={14}
                                                className='text-sky-500 cursor-pointer'
                                                onClick={() => setIsEditing(true)}
                                            />
                                        </div>
                                    </>
                                )}
                                {isEditing && (
                                    <>
                                        <input
                                            type="number"
                                            value={colaboradorLocal.dias_vacaciones_utilizados}
                                            onChange={onChange("dias_vacaciones_utilizados")}
                                            className="text-center w-16 border rounded-sm"
                                        />
                                        <div className='flex flex-row gap-2 items-center'>
                                            <CheckLg
                                                size={20}
                                                className='text-sky-500 cursor-pointer'
                                                onClick={onSubmit}
                                            />
                                            <XLg size={16} className='cursor-pointer' onClick={onRestore} />
                                        </div>
                                    </>
                                )}
                            </div>
                        </td>
                        <td className="text-center">{colaboradorLocal.dias_vacaciones_disponibles}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default ColaboradorVacacionDetail;