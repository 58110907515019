import React, { useState } from "react";
import { Search } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import CollapseCard from "src/modules/common/components/CollapseCard";
import { ErrorToast } from "src/modules/common/components/ErrorToast";
import SelectModal from "src/modules/common/components/SelectModal";
import { commonActions } from "src/modules/common/handler/redux";
import { ESTADOS_FORMULARIO, ETAPAS } from "../helpers/helpers";
import IndicadorFormularioItem from "./IndicadorFormularioItem";
import IndicadorKPIFormularioItem from "./IndicadorKPIFormularioItem";
import IndicadorObjetivoCargoFormularioItem from "./IndicadorObjetivoCargoFormularioItem";
import LoadingSpinButton from "src/modules/common/components/LoadingSpinButton";
import NineBox from "./Ninebox";

function FormularioCalificaciones({
  formulario,
  etapa = ETAPAS.EVALUACION,
  modificarCalificacionHandler,
  modificarCalificacionKPIHandler,
  modificarComentario,
  showPrevious = false,
  verAnotaciones = true,
  disabledFields = [],
  esEditable = true,
  inputComentarios = {},
  onSubmit,
  onSubmitParcial,
  showSubmitButton = true,
  puntuacionesCustom = [],
  mostrarPesosIndicadores = false,
  habilitarOpcionNoAplica = true,
  mostrarNinebox = false,
  porcentajesFormulario = {}
}) {
  const dispatch = useDispatch();

  const nineboxData = useSelector((state) => state.evaluacion.ninebox_data);
  const nineboxDataLoading = useSelector((state) => state.evaluacion.ninebox_data_loading);

  const {
    competencias_generales = [],
    competencias_areas = [],
    competencias_cargos = [],
    calificaciones_kpis = [],
    calificaciones_objetivos_cargo = [],
    competencias_potenciales = [],
  } = formulario;
  // Ordenar alfabéticamente las competencias
  const competencias_generales_sorted = [...competencias_generales].sort((a, b) => a.nombre.localeCompare(b.nombre))
  const competencias_areas_sorted = [...competencias_areas].sort((a, b) => a.nombre.localeCompare(b.nombre))
  const competencias_cargos_sorted = [...competencias_cargos].sort((a, b) => a.nombre.localeCompare(b.nombre))

  const calificacionesKpis = calificaciones_kpis
    .filter((ckpi, i) => ckpi.etapa === etapa)
    .sort((a, b) => a.kpi.nombre.localeCompare(b.kpi.nombre));
  const calificacionesObjetivosCargo = calificaciones_objetivos_cargo
    .filter((ckpi, i) => ckpi.etapa === etapa)
    .sort((a, b) => a.objetivo_cargo.nombre.localeCompare(b.objetivo_cargo.nombre));
  const [errores, setErrores] = useState({});
  const [anotacionesLocal, setAnotacionesLocal] = useState([]);
  const [anotacionesLocalFilter, setAnotacionesLocalFilter] = useState([]);
  const indicadoresEstanDisabled = () => {
    if (!esEditable) {
      return true;
    }
    return disabledFields.includes("indicadores");
  };
  const getCalificacionesCompetencias = (competencias) => {
    let calificaciones = [];
    //
    competencias.forEach((competencia, cIdx) => {
      competencia.conductas.forEach((conducta, conductaIdx) => {
        const calificacion = conducta.calificaciones.find(
          (calif, califIdx) => calif.etapa.value === etapa
        );
        calificaciones.push(calificacion);
      });
    });
    //
    return calificaciones;
  };

  const getErroresCalificaciones = (calificaciones) => {
    let errores = [];
    //
    calificaciones.forEach((calif, califIdx) => {
      // Si tiene KPI que no sea de Observacion controlamos Alcance
      if (
        calif.kpi?.id &&
        calif.kpi?.metrica?.value !== 4 &&
        calif.calificacion !== -1
      ) {
        if (
          calif.alcance === null ||
          calif.alcance === undefined ||
          calif.alcance === ""
        ) {
          // console.log("error " + calif.id, calif);
          errores.push({ id: calif.id, error: "Este campo es requerido." });
        }
        /// Si tiene objetivo por cargo que no sea de Observacion controlamos Alcance
      } else if (
        calif.objetivo_cargo?.id &&
        calif.objetivo_cargo?.metrica?.value !== 4 &&
        calif.calificacion !== -1
      ) {
        if (
          calif.alcance === null ||
          calif.alcance === undefined ||
          calif.alcance === ""
        ) {
          // console.log("error " + calif.id, calif);
          errores.push({ id: calif.id, error: "Este campo es requerido." });
        }
        /// Si no es de KPI controlamos calificacion directamente
      } else if (
        calif.calificacion === null ||
        calif.calificacion === undefined ||
        calif.calificacion === ""
      ) {
        errores.push({ id: calif.id, error: "Este campo es requerido." });
      }
    });
    //
    return errores;
  };

  const getCalificacionPrevia = (conducta, ca) => {
    let prevCal;
    //
    prevCal = conducta.calificaciones.find(
      (cap) => cap.id === ca.id - 1 && cap.etapa.value === etapa - 1
    );
    //
    return prevCal?.calificacion || null;
  };

  const getCalificacionKpiPrevio = (calificacionesK, cKPI) => {
    let calificacionKpi = {};
    //
    calificacionKpi = calificacionesK.find(
      (ckpi, idx) =>
        ckpi.id < cKPI.id &&
        ckpi.etapa === etapa - 1 &&
        ckpi.kpi.id === cKPI.kpi.id
    );
    //
    return calificacionKpi;
  };

  const getCalificacionObjetivoCargoPrevio = (calificacionesK, cobj) => {
    let calificacionObjetivoCargo = {};
    //
    calificacionObjetivoCargo = calificacionesK.find(
      (obj, idx) =>
        obj.id < cobj.id &&
        obj.etapa === etapa - 1 &&
        obj.objetivo_cargo.id === cobj.objetivo_cargo.id
    );
    //
    return calificacionObjetivoCargo;
  };

  const getErrores = () => {
    const {
      competencias_generales,
      competencias_areas,
      competencias_cargos,
      calificaciones_kpis,
      calificaciones_objetivos_cargo,
      competencias_potenciales,
    } = formulario;
    const calificacionesGenerales = getCalificacionesCompetencias(
      competencias_generales
    );
    const calificacionesAreas =
      getCalificacionesCompetencias(competencias_areas);
    const calificacionesCargos =
      getCalificacionesCompetencias(competencias_cargos);
    const calificacionesPotenciales = getCalificacionesCompetencias(
      competencias_potenciales
    );
    const calificacionesKpis = calificaciones_kpis.filter(
      (ckpi, cIdx) => ckpi.etapa === etapa
    );
    const calificacionesObjetivosCargo = calificaciones_objetivos_cargo.filter(
      (cobj, cIdx) => cobj.etapa === etapa
    );
    // kpis no hace falta obtener a calificaciones
    const erroresGenerales = getErroresCalificaciones(calificacionesGenerales);
    const erroresAreas = getErroresCalificaciones(calificacionesAreas);
    const erroresCargos = getErroresCalificaciones(calificacionesCargos);
    const erroresKpis = getErroresCalificaciones(calificacionesKpis);
    const erroresObjetivosCargo = getErroresCalificaciones(
      calificacionesObjetivosCargo
    );
    const erroresPotenciales = getErroresCalificaciones(
      calificacionesPotenciales
    );
    //
    return {
      erroresGenerales: erroresGenerales,
      erroresAreas: erroresAreas,
      erroresCargos: erroresCargos,
      erroresKpis: erroresKpis,
      erroresObjetivosCargo: erroresObjetivosCargo,
      erroresPotenciales: erroresPotenciales,
    };
    //
  };

  const onMostrarAnotaciones = (ckpi) => (e) => {
    const { kpi } = ckpi;
    const { anotaciones } = kpi;
    setAnotacionesLocal(anotaciones);
    setAnotacionesLocalFilter(anotaciones);
    dispatch(commonActions.setSelectModal({ open: true }));
  };

  const getTablaAnotaciones = () => {
    let sumaValores = anotacionesLocal.reduce(
      (accumulator, anotacion) => accumulator + anotacion.valor,
      0
    );
    const onSearchAnotaciones = (e) => {
      const searchText = e.target?.value;
      let anotacionesCopy = [...anotacionesLocal];

      anotacionesCopy = anotacionesCopy.filter((a, aidx) =>
        a.anotacion?.toUpperCase().includes(searchText.toUpperCase())
      );
      setAnotacionesLocalFilter(anotacionesCopy);
    };
    return (
      <div className="flex flex-col gap-4 mt-2">
        <div className="flex">
          <div className="flex items-center bg-slate-50 rounded-md px-2">
            <input
              onChange={onSearchAnotaciones}
              type="text"
              className="bg-slate-50 outline-none p-2"
            />
            <Search size={14}></Search>
          </div>
        </div>
        <div className="flex justify-between">
          <div>
            <div className="font-bold">Total # anotaciones</div>
            <div>{anotacionesLocal.length}</div>
          </div>
          <div>
            <div className="font-bold">Total valor registrado</div>
            <div>{sumaValores}</div>
          </div>
        </div>
        {/*  */}
        <div>
          <div className="font-bold border-b pb-2">Lista de anotaciones</div>
        </div>
        <div className="overflow-y-scroll px-4">
          {anotacionesLocalFilter.map((anotacion, aIdx) => (
            <div className="flex flex-col gap-8 border-b py-4">
              <div>{anotacion.anotacion}</div>
              <div className="flex justify-between">
                <div>
                  <div className="font-bold">Valor registrado</div>
                  <div>{anotacion.valor}</div>
                </div>
                <div>
                  <div className="font-bold">Creado</div>
                  <div>00/00/00</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const onSubmitLocal = () => {
    const errores = getErrores();
    const {
      erroresGenerales,
      erroresAreas,
      erroresCargos,
      erroresKpis,
      erroresObjetivosCargo,
      erroresPotenciales,
    } = errores;
    //.
    if (
      (erroresGenerales.length > 0 ||
        erroresAreas.length > 0 ||
        erroresCargos.length > 0 ||
        erroresKpis.length > 0 ||
        erroresObjetivosCargo.length > 0 ||
        erroresPotenciales.length > 0) && formulario.estado.value < ESTADOS_FORMULARIO.DEVUELTO
    ) {
      ErrorToast({
        message: "Tiene indicadores sin evaluar. Favor verificar.",
      });
      setErrores(errores);
      return;
    }
    //
    onSubmit();
  };
  const onSubmitSinValidar = () => {
    let deseaContinuar = window.confirm(
      "¿Desea guardar la evaluación para continuar más tarde?"
    );
    if (!!deseaContinuar && !!onSubmitParcial) {
      onSubmitParcial();
    }
  };

  const maxLengthComentarios = 2000;

  //Contador de caracteres para el comentario del evaluador
  const comentarioEvaluador = document.getElementById("comentarioEvaluadorId");
  const cantComentarioEvaluador = document.getElementById(
    "cantComentarioEvaluadorId"
  );

  if (comentarioEvaluador) {
    comentarioEvaluador.addEventListener("input", function (e) {
      const longitudMax = e.target.getAttribute("maxlength");
      const longitudAct = e.target.value.length;
      cantComentarioEvaluador.innerText = `${longitudAct}/${longitudMax}`;
    });
  }

  //Contador de caracteres para el comentario del calibrador
  const comentarioCalibrador = document.getElementById(
    "comentarioCalibradorId"
  );
  const cantComentarioCalibrador = document.getElementById(
    "cantComentarioCalibradorId"
  );

  if (comentarioCalibrador) {
    comentarioCalibrador.addEventListener("input", function (e) {
      const longitudMax = e.target.getAttribute("maxlength");
      const longitudAct = e.target.value.length;
      cantComentarioCalibrador.innerText = `${longitudAct}/${longitudMax}`;
    });
  }

  //Contador de caracteres para el comentario del plan de acción
  const comentarioPlanAccion = document.getElementById(
    "comentarioPlanAccionId"
  );
  const cantComentarioPlanAccion = document.getElementById(
    "cantComentarioPlanAccionId"
  );

  if (comentarioPlanAccion) {
    comentarioPlanAccion.addEventListener("input", function (e) {
      const longitudMax = e.target.getAttribute("maxlength");
      const longitudAct = e.target.value.length;
      cantComentarioPlanAccion.innerText = `${longitudAct}/${longitudMax}`;
    });
  }

  //Contador de caracteres para el comentario del colaborador
  const comentarioColaborador = document.getElementById(
    "comentarioColaboradorId"
  );
  const cantComentarioColaborador = document.getElementById(
    "cantComentarioColaboradorId"
  );

  if (comentarioColaborador) {
    comentarioColaborador.addEventListener("input", function (e) {
      const longitudMax = e.target.getAttribute("maxlength");
      const longitudAct = e.target.value.length;
      cantComentarioColaborador.innerText = `${longitudAct}/${longitudMax}`;
    });
  }

  const renderTitle = (title, percentage) => {
    return (
      <div className="flex justify-between w-full">
        <div>
          <span className="font-bold text-sky-500 text-xl">
            Competencias:
          </span>{" "}
          <span className="text-xl">{title}</span>
        </div>
        <div>
          <span className="font-bold text-sky-500 text-xl">
            Logrado:
          </span>{" "}
          <span className="text-xl">{percentage ? Number(percentage).toFixed(2) : 0}%</span>
        </div>
      </div>
    );
  };

  return (
    <>
      {/* COMPETENCIAS GENERALES */}
      {competencias_generales.length > 0 && (
        <div className="border-4x">
          <div>
            <div className="flex flex-col gap-4 mb-2">
              {renderTitle("Competencias Generales", porcentajesFormulario.generales)}
              <div>
                <h3 className="font-bold text-sm">Indicadores a evaluar:</h3>
              </div>
            </div>
            {/* LOOP */}
            <div className="flex flex-col gap-2">
              {competencias_generales_sorted.map((cg, i) => (
                <CollapseCard
                  titulo={cg.nombre}
                  descripcion={cg.descripcion}
                  extraEndContent={
                    cg.peso ? (
                      <div className="flex gap-3 rounded-md bg-slate-300 p-2 text-sm">
                        <div>{cg.peso}</div>
                        <div>%</div>
                      </div>
                    ) : (
                      ""
                    )
                  }
                >
                  {cg.conductas.map((conducta, cidx) =>
                    conducta.calificaciones
                      .filter((ca) => ca.etapa.value === etapa)
                      .map((ca, caIdx) => (
                        <>
                          <IndicadorFormularioItem
                            title={`${cidx + 1} - ` + conducta.descripcion}
                            mostrarPesosIndicadores={mostrarPesosIndicadores}
                            puntuacionesCustom={puntuacionesCustom}
                            previousValue={getCalificacionPrevia(conducta, ca)}
                            showPrevious={showPrevious}
                            value={ca.calificacion}
                            error={
                              errores.erroresGenerales?.find(
                                (e) => e.id === ca.id
                              )?.error
                            }
                            disabled={indicadoresEstanDisabled()}
                            comment={ca.comentarios}
                            name={`radio-group-cg-${ca.id}`}
                            onRadioCheck={(value) =>
                              modificarCalificacionHandler(
                                "competencias_generales",
                                competencias_generales,
                                cg.id,
                                conducta.id,
                                ca.id,
                                "calificacion",
                                value
                              )
                            }
                            onChangeComentario={(value) =>
                              modificarCalificacionHandler(
                                "competencias_generales",
                                competencias_generales,
                                cg.id,
                                conducta.id,
                                ca.id,
                                "comentarios",
                                value
                              )
                            }
                          ></IndicadorFormularioItem>
                        </>
                      ))
                  )}
                </CollapseCard>
              ))}
            </div>
          </div>
        </div>
      )}
      {/* FIN COMPETENCIAS GENERALES */}
      {/* COMPETENCIAS AREA */}
      {competencias_areas.length > 0 && (
        <div className="border-4x">
          <div>
            <div className="flex flex-col gap-4 mb-2">
              {renderTitle("Competencias de Área", porcentajesFormulario.areas)}
              <div>
                <h3 className="font-bold text-sm">Indicadores a evaluar:</h3>
              </div>
            </div>
            {/* LOOP */}
            <div className="flex flex-col gap-2">
              {competencias_areas_sorted.map((ca, i) => (
                <CollapseCard
                  titulo={ca.nombre}
                  descripcion={ca.descripcion}
                  extraEndContent={
                    ca.peso ? (
                      <div className="flex gap-3 rounded-md bg-slate-300 p-2 text-sm">
                        <div>{ca.peso}</div>
                        <div>%</div>
                      </div>
                    ) : (
                      ""
                    )
                  }
                >
                  {ca.conductas.map((conducta, cidx) =>
                    conducta.calificaciones
                      .filter((calif) => calif.etapa.value === etapa)
                      .map((calif) => (
                        <IndicadorFormularioItem
                          title={`${cidx + 1} - ` + conducta.descripcion}
                          mostrarPesosIndicadores={mostrarPesosIndicadores}
                          puntuacionesCustom={puntuacionesCustom}
                          previousValue={getCalificacionPrevia(conducta, calif)}
                          showPrevious={showPrevious}
                          value={calif.calificacion}
                          disabled={indicadoresEstanDisabled()}
                          comment={calif.comentarios}
                          name={`radio-group-ca-${calif.id}`}
                          error={
                            errores.erroresAreas?.find((e) => e.id === calif.id)
                              ?.error
                          }
                          onRadioCheck={(value) =>
                            modificarCalificacionHandler(
                              "competencias_areas",
                              competencias_areas,
                              ca.id,
                              conducta.id,
                              calif.id,
                              "calificacion",
                              value
                            )
                          }
                          onChangeComentario={(value) =>
                            modificarCalificacionHandler(
                              "competencias_areas",
                              competencias_areas,
                              ca.id,
                              conducta.id,
                              calif.id,
                              "comentarios",
                              value
                            )
                          }
                        ></IndicadorFormularioItem>
                      ))
                  )}
                </CollapseCard>
              ))}
            </div>
          </div>
        </div>
      )}
      {/* FIN COMPETENCIAS AREA */}
      {/* COMPETENCIAS CARGO */}
      {competencias_cargos.length > 0 && (
        <div className="border-4x">
          <div>
            <div className="flex flex-col gap-4 mb-2">
              {renderTitle("Competencias de Cargo", porcentajesFormulario.cargos)}
              <div>
                <h3 className="font-bold text-sm">Indicadores a evaluar:</h3>
              </div>
            </div>
            {/* LOOP */}
            <div className="flex flex-col gap-2">
              {competencias_cargos_sorted.map((cc, i) => (
                <CollapseCard
                  titulo={cc.nombre}
                  descripcion={cc.descripcion}
                  extraEndContent={
                    cc.peso ? (
                      <div className="flex gap-3 rounded-md bg-slate-300 p-2 text-sm">
                        <div>{cc.peso}</div>
                        <div>%</div>
                      </div>
                    ) : (
                      ""
                    )
                  }
                >
                  {cc.conductas.map((conducta, cidx) =>
                    conducta.calificaciones
                      .filter((ca) => ca.etapa.value === etapa)
                      .map((calif) => (
                        <IndicadorFormularioItem
                          title={`${cidx + 1} - ` + conducta.descripcion}
                          mostrarPesosIndicadores={mostrarPesosIndicadores}
                          puntuacionesCustom={puntuacionesCustom}
                          previousValue={getCalificacionPrevia(conducta, calif)}
                          showPrevious={showPrevious}
                          value={calif.calificacion}
                          disabled={indicadoresEstanDisabled()}
                          comment={calif.comentarios}
                          name={`radio-group-cc-${calif.id}`}
                          error={
                            errores.erroresCargos?.find(
                              (e) => e.id === calif.id
                            )?.error
                          }
                          onRadioCheck={(value) =>
                            modificarCalificacionHandler(
                              "competencias_cargos",
                              competencias_cargos,
                              cc.id,
                              conducta.id,
                              calif.id,
                              "calificacion",
                              value
                            )
                          }
                          onChangeComentario={(value) =>
                            modificarCalificacionHandler(
                              "competencias_cargos",
                              competencias_cargos,
                              cc.id,
                              conducta.id,
                              calif.id,
                              "comentarios",
                              value
                            )
                          }
                        ></IndicadorFormularioItem>
                      ))
                  )}
                </CollapseCard>
              ))}
            </div>
          </div>
        </div>
      )}
      {/* FIN COMPETENCIAS CARGO */}
      {/* COMPETENCIAS DE POTENCIAL */}
      {competencias_potenciales.length > 0 && (
        <div className="border-4x">
          <div>
            <div className="flex flex-col gap-4 mb-2">
              {renderTitle("Competencias de Potencial", porcentajesFormulario.potenciales)}
              <div>
                <h3 className="font-bold text-sm">Indicadores a evaluar:</h3>
              </div>
            </div>
            {/* LOOP */}
            <div className="flex flex-col gap-2">
              {competencias_potenciales.map((cp, i) => (
                <CollapseCard
                  titulo={cp.nombre}
                  descripcion={cp.descripcion}
                  extraEndContent={
                    cp.peso ? (
                      <div className="flex gap-3 rounded-md bg-slate-300 p-2 text-sm">
                        <div>{cp.peso}</div>
                        <div>%</div>
                      </div>
                    ) : (
                      ""
                    )
                  }
                >
                  {cp.conductas.map((conducta, cidx) =>
                    conducta.calificaciones
                      .filter((ca) => ca.etapa.value === etapa)
                      .map((ca, caIdx) => (
                        <>
                          <IndicadorFormularioItem
                            title={`${cidx + 1} - ` + conducta.descripcion}
                            mostrarPesosIndicadores={mostrarPesosIndicadores}
                            puntuacionesCustom={puntuacionesCustom}
                            previousValue={getCalificacionPrevia(conducta, ca)}
                            showPrevious={showPrevious}
                            value={ca.calificacion}
                            error={
                              errores.erroresPotenciales?.find(
                                (e) => e.id === ca.id
                              )?.error
                            }
                            disabled={indicadoresEstanDisabled()}
                            comment={ca.comentarios}
                            name={`radio-group-cp-${ca.id}`}
                            onRadioCheck={(value) =>
                              modificarCalificacionHandler(
                                "competencias_potenciales",
                                competencias_potenciales,
                                cp.id,
                                conducta.id,
                                ca.id,
                                "calificacion",
                                value
                              )
                            }
                            onChangeComentario={(value) =>
                              modificarCalificacionHandler(
                                "competencias_potenciales",
                                competencias_potenciales,
                                cp.id,
                                conducta.id,
                                ca.id,
                                "comentarios",
                                value
                              )
                            }
                            habilitarOpcionNoAplica={habilitarOpcionNoAplica}
                          ></IndicadorFormularioItem>
                        </>
                      ))
                  )}
                </CollapseCard>
              ))}
            </div>
          </div>
        </div>
      )}
      {/* FIN COMPETENCIAS DE POTENCIAL */}
      {/* KPIS */}
      {calificacionesKpis.length > 0 && (
        <div className="border-4x">
          <div>
            <div className="flex flex-col gap-4 mb-2">
              {renderTitle("KPIs", porcentajesFormulario.kpis)}
              <div>
                <h3 className="font-bold text-sm">Indicadores a evaluar:</h3>
              </div>
            </div>
            {/* LOOP */}
            <div className="flex flex-col gap-2">
              {calificacionesKpis.map((ckpi, idx) => (
                <CollapseCard
                  titulo={
                    `${ckpi.kpi.nombre} ${ckpi.kpi.rangos.length > 1 ? "RANGOS" : ""
                    }` || "Sin nombre"
                  }
                  extraEndContent={
                    <div className="flex gap-2">
                      {!!verAnotaciones &&
                        ckpi.kpi?.anotaciones?.length > 0 && (
                          <button
                            onClick={onMostrarAnotaciones(ckpi)}
                            className="bg-white rounded-md text-sky-500 text-sm border-1 border-sky-500 p-2"
                          >
                            Ver anotaciones
                          </button>
                        )}
                      {!!ckpi.kpi?.peso > -1 && (
                        <div className="flex gap-3 rounded-md bg-slate-300 p-2 text-sm">
                          <div>{ckpi.kpi?.peso}</div>
                          <div>%</div>
                        </div>
                      )}
                    </div>
                  }
                  descripcion={ckpi.kpi.descripcion || "Sin descripción"}
                >
                  <IndicadorKPIFormularioItem
                    calificacionKpi={ckpi}
                    mostrarPesosIndicadores={mostrarPesosIndicadores}
                    puntuacionesCustom={puntuacionesCustom}
                    name={`radio-group-ckpi-${ckpi.id}`}
                    previousKpi={getCalificacionKpiPrevio(
                      calificaciones_kpis,
                      ckpi
                    )}
                    showPrevious={showPrevious}
                    disabled={indicadoresEstanDisabled()}
                    error={
                      errores.erroresKpis?.find((e) => e.id === ckpi.id)?.error
                    }
                    onChangeAlcance={(value, secondValue) =>
                      !!modificarCalificacionKPIHandler
                        ? modificarCalificacionKPIHandler(
                          "calificaciones_kpis",
                          calificaciones_kpis,
                          ckpi.id,
                          "alcance",
                          value,
                          secondValue
                        )
                        : null
                    }
                    onChangeCalificacion={(value) =>
                      !!modificarCalificacionKPIHandler
                        ? modificarCalificacionKPIHandler(
                          "calificaciones_kpis",
                          calificaciones_kpis,
                          ckpi.id,
                          "calificacion",
                          value
                        )
                        : null
                    }
                    onChangeComentario={(value) =>
                      !!modificarCalificacionKPIHandler
                        ? modificarCalificacionKPIHandler(
                          "calificaciones_kpis",
                          calificacionesKpis,
                          ckpi.id,
                          "comentarios",
                          value
                        )
                        : null
                    }
                    habilitarOpcionNoAplica={habilitarOpcionNoAplica}
                  ></IndicadorKPIFormularioItem>
                </CollapseCard>
              ))}
            </div>
          </div>
        </div>
      )}
      {/* FIN KPIS */}
      {/* OBJETIVOS POR CARGO */}
      {calificacionesObjetivosCargo.length > 0 && (
        <div className="border-4x">
          <div>
            <div className="flex flex-col gap-4 mb-2">
              {renderTitle("Objetivos del cargo", porcentajesFormulario.objetivos_cargo)}
              <div>
                <h3 className="font-bold text-sm">Indicadores a evaluar:</h3>
              </div>
            </div>
            {/* LOOP */}
            <div className="flex flex-col gap-2">
              {calificacionesObjetivosCargo.map((cobj, idx) => (
                <CollapseCard
                  titulo={
                    `${cobj.objetivo_cargo.nombre} ${cobj.objetivo_cargo.rangos.length > 1 ? "RANGOS" : ""
                    }` || "Sin nombre"
                  }
                  extraEndContent={
                    <div className="flex gap-2">
                      {!!verAnotaciones &&
                        cobj.objetivo_cargo?.anotaciones?.length > 0 && (
                          <button
                            onClick={onMostrarAnotaciones(cobj)}
                            className="bg-white rounded-md text-sky-500 text-sm border-1 border-sky-500 p-2"
                          >
                            Ver anotaciones
                          </button>
                        )}
                      {!!cobj.objetivo_cargo?.peso > -1 && (
                        <div className="flex gap-3 rounded-md bg-slate-300 p-2 text-sm">
                          <div>{cobj.objetivo_cargo?.peso}</div>
                          <div>%</div>
                        </div>
                      )}
                    </div>
                  }
                  descripcion={
                    cobj.objetivo_cargo.descripcion || "Sin descripción"
                  }
                >
                  <IndicadorObjetivoCargoFormularioItem
                    calificacionObjetivoCargo={cobj}
                    mostrarPesosIndicadores={mostrarPesosIndicadores}
                    puntuacionesCustom={puntuacionesCustom}
                    name={`radio-group-cobj-${cobj.id}`}
                    previousObjetivoCargo={getCalificacionObjetivoCargoPrevio(
                      calificaciones_objetivos_cargo,
                      cobj
                    )}
                    showPrevious={showPrevious}
                    disabled={indicadoresEstanDisabled()}
                    error={
                      errores.erroresObjetivosCargo?.find(
                        (e) => e.id === cobj.id
                      )?.error
                    }
                    onChangeAlcance={(value, secondValue) =>
                      !!modificarCalificacionKPIHandler
                        ? modificarCalificacionKPIHandler(
                          "calificaciones_objetivos_cargo",
                          calificaciones_objetivos_cargo,
                          cobj.id,
                          "alcance",
                          value,
                          secondValue
                        )
                        : null
                    }
                    onChangeCalificacion={(value) =>
                      !!modificarCalificacionKPIHandler
                        ? modificarCalificacionKPIHandler(
                          "calificaciones_objetivos_cargo",
                          calificaciones_objetivos_cargo,
                          cobj.id,
                          "calificacion",
                          value
                        )
                        : null
                    }
                    onChangeComentario={(value) =>
                      !!modificarCalificacionKPIHandler
                        ? modificarCalificacionKPIHandler(
                          "calificaciones_objetivos_cargo",
                          calificacionesObjetivosCargo,
                          cobj.id,
                          "comentarios",
                          value
                        )
                        : null
                    }
                    habilitarOpcionNoAplica={habilitarOpcionNoAplica}
                  ></IndicadorObjetivoCargoFormularioItem>
                </CollapseCard>
              ))}
            </div>
          </div>
        </div>
      )}
      {/* FIN OBJETIVOS POR CARGO */}
      {mostrarNinebox && (
        <>
          {!nineboxDataLoading && (
            <>
              <NineBox
                nineboxData={nineboxData.boxes}
                rangosData={nineboxData.rangos}
                porcentajesFormulario={porcentajesFormulario}
                configuracionFormulario={formulario.configuracion}
                extraClassName="my-4" />
            </>
          )}
          {nineboxDataLoading && (
            <div className="flex justify-center items-center p-2">
              <LoadingSpinButton message="Cargando Nine Box..." />
            </div>
          )}
        </>
      )}
      {/* COMENTARIOS */}
      <>
        <div className="mb-2 mt-16">
          <h3 className="text-sky-500 text-xl font-semibold">
            Comentarios:
          </h3>
        </div>
        <div className="w-full flex justify-center">
          <div className="w-full md:w-2/3 flex flex-col gap-4">
            {/* COMENTARIO EVALUADOR */}
            {inputComentarios.comentarios_evaluador?.show && (
              <div className="flex flex-col gap-2">
                <h2 className="font-bold text-xl">
                  Áreas en las que requiera formación o capacitación específica
                </h2>
                <p className="text-sm">
                  Detallar los cursos o entrenamientos que se sugieren, para el
                  colaborador. <br></br> Ej: Cursos de Liderazgo, Atención al
                  Cliente, Excel, Idiomas, etc.
                </p>
                <label className="font-semibold text-sm text-gray-500">
                  Comentarios del evaluador:
                  <span className="text-red-500"> *</span>
                </label>
                <textarea
                  id="comentarioEvaluadorId"
                  className="border rounded-md p-2"
                  rows={6}
                  maxLength={maxLengthComentarios}
                  disabled={
                    !inputComentarios.comentarios_evaluador?.editable ||
                    !esEditable
                  }
                  value={formulario.comentarios_evaluador}
                  onChange={
                    !!modificarComentario
                      ? modificarComentario("comentarios_evaluador")
                      : null
                  }
                ></textarea>
                <div className="w-full flex justify-end">
                  <span
                    id="cantComentarioEvaluadorId"
                    className="text-xs text-gray-500"
                  >
                    {formulario.comentarios_evaluador
                      ? formulario.comentarios_evaluador.length
                      : 0}
                    /{maxLengthComentarios}
                  </span>
                </div>
              </div>
            )}
            {/* COMENTARIO CALIBRADOR */}
            {inputComentarios.comentarios_calibrador?.show && (
              <div className="flex flex-col gap-2">
                <h2 className="font-bold text-xl">
                  Áreas en las que requiera formación o capacitación específica
                </h2>
                <p className="text-sm">
                  Detallar los cursos o entrenamientos que se sugieren, para el
                  colaborador. <br></br> Ej: Cursos de Liderazgo, Atención al
                  Cliente, Excel, Idiomas, etc.
                </p>
                <label className="font-semibold text-sm text-gray-500">
                  Comentarios del calibrador:
                  <span className="text-red-500"> *</span>
                </label>
                <textarea
                  id="comentarioCalibradorId"
                  className="border rounded-md p-2"
                  rows={6}
                  maxLength={maxLengthComentarios}
                  disabled={
                    !inputComentarios.comentarios_calibrador?.editable ||
                    !esEditable
                  }
                  value={formulario.comentarios_calibrador}
                  onChange={
                    modificarComentario
                      ? modificarComentario("comentarios_calibrador")
                      : null
                  }
                ></textarea>
                <div className="w-full flex justify-end">
                  <span
                    id="cantComentarioCalibradorId"
                    className="text-xs text-gray-500"
                  >
                    {formulario.comentarios_calibrador
                      ? formulario.comentarios_calibrador.length
                      : 0}
                    /{maxLengthComentarios}
                  </span>
                </div>
              </div>
            )}
            {/* PLAN DE ACCION */}
            {inputComentarios.plan_accion?.show && (
              <div className="flex flex-col gap-2">
                <h2 className="font-bold text-xl">
                  Plan de acción (acuerdos con el colaborador)
                </h2>
                <p className="text-sm">
                  Este cuadro de texto se reserva para ser completado al momento
                  de la reunión con el colaborador para devolución de los
                  resultados de su evaluación
                </p>
                <label className="font-semibold text-sm text-gray-500">
                  Plan de acción:
                  <span className="text-red-500"> *</span>
                </label>
                <textarea
                  id="comentarioPlanAccionId"
                  className="border rounded-md p-2"
                  rows={6}
                  maxLength={maxLengthComentarios}
                  disabled={
                    !inputComentarios.plan_accion?.editable || !esEditable
                  }
                  value={formulario.plan_accion}
                  onChange={
                    modificarComentario
                      ? modificarComentario("plan_accion")
                      : null
                  }
                ></textarea>
                <div className="w-full flex justify-end">
                  <span
                    id="cantComentarioPlanAccionId"
                    className="text-xs text-gray-500"
                  >
                    {formulario.plan_accion ? formulario.plan_accion.length : 0}
                    /{maxLengthComentarios}
                  </span>
                </div>
              </div>
            )}
            {/* COMENTARIO DE COLABORADOR */}
            {inputComentarios.comentarios_evaluado?.show && (
              <div className="flex flex-col gap-2">
                <label className="font-semibold text-sm text-gray-500">
                  Comentarios del colaborador:
                  <span className="text-red-500"> *</span>
                </label>
                <textarea
                  id="comentarioColaboradorId"
                  className="border rounded-md p-2"
                  rows={6}
                  maxLength={maxLengthComentarios}
                  disabled={
                    !inputComentarios.comentarios_evaluado?.editable ||
                    !esEditable
                  }
                  value={formulario.comentarios_evaluado}
                  onChange={
                    modificarComentario
                      ? modificarComentario("comentarios_evaluado")
                      : null
                  }
                ></textarea>
                <div className="w-full flex justify-end">
                  <span
                    id="cantComentarioColaboradorId"
                    className="text-xs text-gray-500"
                  >
                    {formulario.comentarios_evaluado
                      ? formulario.comentarios_evaluado.length
                      : 0}
                    /{maxLengthComentarios}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
      {/* FIN COMENTARIOS */}
      {!!showSubmitButton && (
        <div className="w-full flex justify-center">
          <div className="w-full md:w-2/3">
            <button
              onClick={onSubmitSinValidar}
              className="border border-slate-500 text-slate-500 rounded-md bg-white p-1 w-full mb-3"
            >
              Guardar y continuar más tarde
            </button>
            <button
              disabled={!esEditable}
              onClick={onSubmitLocal}
              className={`${!esEditable ? "bg-slate-300" : "bg-sky-500"
                } text-white p-2 rounded-md w-full text-sm mb-3`}
            >
              Finalizar evaluación
            </button>
          </div>
        </div>
      )}
      {/* Modal Anotaciones */}
      <SelectModal
        title={`Anotaciones guardadas`}
        content={getTablaAnotaciones()}
      ></SelectModal>
    </>
  );
}
export default FormularioCalificaciones;
