import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import {
  ClockHistory,
  EyeFill,
  FileEarmarkArrowDownFill,
  XLg,
} from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { colaboradorActions } from "src/modules/colaborador/modules/colaborador/handlers/redux";
import CollapseCard from "src/modules/common/components/CollapseCard";
import { ErrorToast } from "src/modules/common/components/ErrorToast";
import { SuccessToast } from "src/modules/common/components/SuccessToast";
import backButton from "src/modules/common/components/images/back-arrow.png";
import { commonActions } from "src/modules/common/handler/redux";
import { formatNumber, getToken } from "src/utils/helpers";
import { PrimaryButton } from "src/modules/common/components/PrimaryButton";
import SelectModal from "src/modules/common/components/SelectModal";
import KpiColaboradorForm from "src/modules/kpi/pages/KpiColaboradorForm";
import userDefault from "../images/user_default.png";
import { objetivoActions } from "../handlers/redux";
import Modal from "src/modules/common/components/Modal";
import ObjetivoCargoHistorial from "../components/ObjetivoCargoHistorial";
import SearchInput from "src/modules/remuneracion/components/SearchInput";
import MostrarPaginas from "src/modules/common/components/MostrarPaginas";
import ReactSelect from "react-select";
import dayjs from "dayjs";
import DataTable from "src/modules/common/components/DataTable";
import { regexInputPeso } from "src/modules/common/helpers/helpers";

const initialObjetivo = {
  id: null,
  nombre: "",
  descripcion: "",
  periodo: null,
  metrica: null,
  meta: null,
  peso: 0.0,
  rangos: [],
};

export function ObjetivosColaboradorDetailPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const { idColaborador } = params; // Obtiene el id que se recibe desde la url

  const colaborador = useSelector((state) => state.colaborador.colaborador);
  const objetivos = useSelector((state) => state.objetivo.colaboradorObjetivos);
  const colaboradorObjetivos_loading = useSelector(
    (state) => state.objetivo.colaboradorObjetivos_loading
  );
  // NO VIGENTES / HISTORICO
  const objetivosNoVigentes = useSelector(
    (state) => state.objetivo.colaboradorObjetivosNoVigentes
  );
  const objetivosNoVigentesChecked = useSelector(
    (state) => state.objetivo.colaboradorObjetivosNoVigentes_checked
  );
  const objetivosNoVigentes_count = useSelector(
    (state) => state.objetivo.colaboradorObjetivosNoVigentes_count
  );
  const objetivosNoVigentes_loading = useSelector(
    (state) => state.objetivo.colaboradorObjetivosNoVigentes_loading
  );
  const objetivosNoVigentes_filter = useSelector(
    (state) => state.objetivo.colaboradorObjetivosNoVigentes_filter
  );

  const { results: objectivosResult = [] } = objetivos;

  const [isEditing, setIsEditing] = useState(false);
  const [objetivosData, setObjetivosData] = useState([]);
  const [pesoTotalObjetivos, setPesoTotalObjetivos] = useState(0);
  const [objetivosArchivados, setObjetivosArchivados] = useState([]);
  const [objetivoForm, setObjetivoForm] = useState(initialObjetivo);
  const [objetivoFormIndex, setObjetivoFormIndex] = useState(null);
  const [objetivoHistory, setObjetivoHistory] = useState([]);
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  //
  const [downloadLoading, setDownloadLoading] = useState(false);

  const onSave = () => {
    if (pesoTotalObjetivos != 0 && pesoTotalObjetivos != 100) {
      ErrorToast({ message: "La suma de los pesos debe ser de 100%." });
      return;
    }
    //
    const params = {
      id: idColaborador,
      objetivos: objetivosData,
      archivados: objetivosArchivados.map((kpi, i) => kpi.id),
    };
    dispatch(objetivoActions.colaboradorUpdateObjetivos(params));
    //
    setIsEditing(false);
  };

  const onCancel = () => {
    setObjetivosData(objetivos?.results);
    setIsEditing(false);
    undoRestoreObjetivos();
  };

  const showObjetivoMeta = (objetivo) => {
    if (objetivo.metrica_display === "Selección simple")
      return "Logro/No logro";
    return objetivo.meta
      ? `${formatNumber(objetivo.meta).toLocaleString("de-DE")}`
      : objetivo.rangos.length > 0
        ? "Por escala"
        : "Sin definir";
  };

  const showEscala = (objetivo) => {
    const { rangos } = objetivo;
    return (
      <div className="group flex justify-center items-center border rounded-md p-1">
        <EyeFill></EyeFill>
        <div className="border rounded-md p-2 bg-white z-10 hidden group-hover:block group-hover:absolute bottom-0 left-28">
          <div className="flex gap-2 font-bold text-sm">
            <div className="flex-1">Desde</div>
            <div className="flex-1">Hasta</div>
            <div className="flex-1">Resultado</div>
          </div>
          {rangos.map((r, i) => (
            <div className="flex gap-2 text-sm">
              <div className="flex-1">
                {r.desde ? r.desde.toLocaleString("de-DE") : r.desde}
              </div>
              <div className="flex-1">
                {r.hasta ? r.hasta.toLocaleString("de-DE") : r.hasta}
              </div>
              <div className="flex-1">
                {r.resultado
                  ? r.resultado.toLocaleString("de-DE")
                  : r.resultado}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderCreadoPor = (objetivo) => {
    return (
      <div className="flex flex-row gap-1 items-center">
        {!objetivo.creado_por?.foto_empleado && (
          <div className="h-8 w-8 rounded-lg bg-zinc-200"></div>
        )}
        {!!objetivo.creado_por?.foto_empleado && (
          <div className="w-8 bg-slate-200 rounded-md">
            <img
              className="object-cover h-8 w-8 rounded-md"
              src={objetivo.creado_por?.foto_empleado}
              alt={`Foto Jefe de Lider`}
            />
          </div>
        )}
        <div className="font-semibold">
          {objetivo.creado_por
            ? `${objetivo.creado_por.nombre} ${objetivo.creado_por.apellido}`
            : "Sin registro"}
        </div>
      </div>
    );
  };

  const onCloseHistoryModal = () => {
    setObjetivoHistory(null);
    setShowHistoryModal(false);
  };
  const kpisContainerRef = useRef(null);

  const scrollToComponent = () => {
    kpisContainerRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const restoreObjetivo = (kpi) => {
    const kpisDataCopy = JSON.parse(JSON.stringify(objetivosData));
    kpisDataCopy.push({ ...kpi, aRestaurar: true });
    setObjetivosData(kpisDataCopy);
    //
    let kpisNoVigentesCopy = JSON.parse(JSON.stringify(objetivosNoVigentes));
    kpisNoVigentesCopy = kpisNoVigentesCopy.filter(
      (kpinv, kidx) => kpinv.id !== kpi.id
    );
    const payload = {
      results: kpisNoVigentesCopy,
      count: kpisNoVigentesCopy.length,
    };
    dispatch(objetivoActions.setColaboradorObjetivosNoVigentesSimple(payload));
    //
    setIsEditing(true);
    scrollToComponent();
  };

  const restoreObjetivos = (kpisToRestore) => {
    const kpisDataCopy = JSON.parse(JSON.stringify(objetivosData));
    kpisToRestore.forEach((kpi) => {
      kpisDataCopy.push({ ...kpi, aRestaurar: true });
    });
    setObjetivosData(kpisDataCopy);
    let kpisNoVigentesCopy = JSON.parse(JSON.stringify(objetivosNoVigentes));
    kpisNoVigentesCopy = kpisNoVigentesCopy.filter(
      (kpinv) => !kpisToRestore.some((kpi) => kpinv.id === kpi.id)
    );
    const payload = {
      results: kpisNoVigentesCopy,
      count: kpisNoVigentesCopy.length,
    };
    dispatch(objetivoActions.setColaboradorObjetivosNoVigentesSimple(payload));
    setIsEditing(true);
    scrollToComponent();
  };

  const undoRestoreObjetivos = () => {
    let kpisDataCopy = JSON.parse(JSON.stringify(objetivosData));
    let kpisNoVigentesCopy = JSON.parse(JSON.stringify(objetivosNoVigentes));
    //
    kpisDataCopy = kpisDataCopy.filter((kpi) => {
      if (kpi.aRestaurar) {
        const { aRestaurar, ...kpiWithoutARestaurar } = kpi;
        kpisNoVigentesCopy.push(kpiWithoutARestaurar);
        return false;
      }
      return true;
    });
    //
    setObjetivosData(kpisDataCopy);
    //
    const payload = {
      results: kpisNoVigentesCopy,
      count: kpisNoVigentesCopy.length,
    };
    dispatch(objetivoActions.setColaboradorObjetivosNoVigentesSimple(payload));
    //
    setIsEditing(false);
    scrollToComponent();
  };

  const datatableConfig = {
    headers: [
      {
        title: "Lista de Objetivos",
        contentClass: "px-2 py-4",
        accessor: "nombre",
      },
      {
        title: "Métrica",
        accessor: "metrica_display",
      },
      {
        title: "Frecuencia",
        accessor: "periodo_display",
      },
      {
        title: "Creado",
        accessor: "creado_por.nombre",
        contentRenderer: (item) => (
          <div>
            {!!item.fecha_creacion
              ? dayjs(item.fecha_creacion.slice(0, 10)).format("DD/MM/YYYY")
              : "Sin registro"}
          </div>
        ),
      },
    ],
    rowActions: [
      {
        label: "Restaurar",
        action: (item) => {
          restoreObjetivo(item);
        },
      },
    ],
  };

  const actionOptions = [
    {
      value: "restaurar",
      label: "Restaurar",
      // action: () => {},
    },
  ];

  const onAction = (v) => {
    if (v.value === "restaurar") {
      if (objetivosNoVigentesChecked.length < 1) {
        ErrorToast({ message: "Debe seleccionar al menos un Objetivo." });
        return;
      }
      const deseaRestaurar = window.confirm(
        "¿Está seguro de restaurar los Objetivos seleccionados?"
      );
      if (deseaRestaurar) {
        restoreObjetivos(objetivosNoVigentesChecked);
        dispatch(objetivoActions.setObjetivoNoVigentesChecked([]));
      }
      return;
    }
  };

  useEffect(() => {
    dispatch(commonActions.setTitleMenu([]));
    dispatch(commonActions.setTitle(""));
    dispatch(commonActions.showSidebar(false));

    if (idColaborador) {
      let item = { id: idColaborador };
      dispatch(colaboradorActions.colaboradorGet(item));
      dispatch(objetivoActions.getColaboradorObjetivos(item));
      dispatch(objetivoActions.getColaboradorObjetivosNoVigentes(item));
    }
  }, []);

  useEffect(() => {
    setObjetivosData(objectivosResult);
    setObjetivosArchivados([]);
  }, [objectivosResult]);

  useEffect(() => {
    const kpisActualizados = objetivosData.filter(
      (kpi) => kpi.vigente === "false"
    );
    if (kpisActualizados.length > 0) {
      setObjetivosArchivados((prevArchivados) => [
        ...prevArchivados,
        ...kpisActualizados,
      ]);
      setObjetivosData(objetivosData.filter((kpi) => kpi.vigente !== "false"));
    }
    const sumaPesos = objetivosData.reduce((total, objetivo) => {
      return total + (objetivo.peso || 0);
    }, 0);
    const sumaRedondeada = parseFloat(sumaPesos.toFixed(5));
    setPesoTotalObjetivos(sumaRedondeada);
  }, [objetivosData]);

  const ObjetivoSkeletons = () => {
    return (
      <div className="flex flex-col gap-3 animate-pulse mt-4">
        <div className="h-20 border rounded-md bg-slate-200"></div>
        <div className="h-20 border rounded-md bg-slate-200"></div>
        <div className="h-20 border rounded-md bg-slate-200"></div>
      </div>
    );
  };

  const onAgregarObjetivo = () => {
    setObjetivoForm(initialObjetivo);
    setObjetivoFormIndex(null);
    dispatch(commonActions.setSelectModal({ open: true }));
  };

  const agregarObjetivo = (index) => (objetivo) => {
    let objetivosDataCopy = JSON.parse(JSON.stringify(objetivosData));
    if (parseInt(objetivo.metrica || 0) === 3 || parseInt(objetivo.metrica || 0) === 4) {
      objetivo.meta = 0;
    }
    //
    if (!!index || index === 0) {
      objetivosDataCopy[index] = objetivo;
    } else {
      objetivosDataCopy.push({ ...objetivo });
    }
    setObjetivosData(objetivosDataCopy);
    setObjetivoForm(initialObjetivo);
    dispatch(commonActions.setSelectModal({ open: false }));
    SuccessToast({
      message: !!index ? "Objetivo editado" : "Objetivo agregado al listado",
    });
  };

  const renderObjetivosColaboradorList = () => {
    const onChangePeso = (index) => (e) => {
      let value = e.target.value;
      // Expresión regular: permite números hasta 3 dígitos enteros y hasta 5 decimales
      const regex = regexInputPeso;
      if (!regex.test(value)) {
        return; // No actualizar el estado si el valor no cumple con la validación
      }
      value = Number(value);
      if (value < 0 || value > 100) {
        return;
      }
      let objetivosDataCopy = JSON.parse(JSON.stringify(objetivosData));
      objetivosDataCopy[index].peso = value;
      setObjetivosData(objetivosDataCopy);
    };
    const onEditar = (objetivo, index) => () => {
      setObjetivoForm(objetivo);
      setObjetivoFormIndex(index);
      dispatch(commonActions.setSelectModal({ open: true }));
    };
    const onDeleteObjetivo = (id) => () => {
      let deseaEliminar = true;
      if (!!deseaEliminar) {
        deseaEliminar = window.confirm(
          "¿Está seguro de eliminar este objetivo?"
        );
      }
      if (!!deseaEliminar) {
        const newObjetivos = objetivosData.filter(
          (objetivo, kidx) => kidx !== id
        );
        setObjetivosData(newObjetivos);
      }
    };
    const onVerHistorial = (objetivo) => () => {
      setObjetivoHistory(objetivo);
      setShowHistoryModal(true);
    };

    const getMaxWidthPeso = () => {
      // El tamaño de los inputs de peso que se muestran deben ser el mismo para todos.
      // El tamaño que se le va poner a todos es de acuerdo al número con mayor cantidad de caracteres.
      const maxDigits = Math.max(...objetivosData.map(obj => obj.peso.toString().length));
      return `${maxDigits * 10 + 40}px`;
    };

    return objetivosData.map((objetivo, kidx) => (
      <CollapseCard
        key={`obj-${kidx}-${objetivo.id}`}
        titulo={objetivo.nombre}
        extraClass="mb-8"
        titleClass={"font-semibold"}
        initCollapse={false}
        extraEndContent={
          <div className="flex justify-end items-center gap-2 text-sm">
            <input
              disabled={!isEditing}
              type="number"
              value={objetivo.peso}
              onChange={onChangePeso(kidx)}
              className="bg-slate-300x border border-slate-400 outline-none rounded-md p-2"
              style={{ width: getMaxWidthPeso() }}
            />
            <div>%</div>
          </div>
        }
      >
        <div className="flex flex-col gap-2">
          <div>{objetivo.descripcion}</div>
          <div className="flex">
            <div className="flex flex-col flex-1">
              <div className="font-semibold">Tiempo</div>
              <div>{objetivo.periodo_display}</div>
            </div>
            <div className="flex flex-col flex-1">
              <div className="font-semibold">Tipo de metrica</div>
              <div>{objetivo.metrica_display}</div>
            </div>
          </div>
          <div className="flex relative">
            <div className="flex flex-col flex-1">
              <div className="font-semibold">Meta</div>
              <div className="flex gap-2">
                {showObjetivoMeta(objetivo)}
                {showObjetivoMeta(objetivo) === "Por escala" && (
                  <div>{showEscala(objetivo)}</div>
                )}
              </div>
            </div>
            <div className="flex flex-col flex-1">
              <div className="font-semibold">Creado por</div>
              {renderCreadoPor(objetivo)}
            </div>
          </div>
        </div>
        {!!isEditing && (
          <div className="flex mt-4 gap-2">
            <button
              type="button"
              className="rounded bg-sky-500 text-white px-3 py-1 text-sm"
              onClick={onEditar(objetivo, kidx)}
            >
              Editar
            </button>
            <button
              type="button"
              className="rounded bg-red-500 text-white px-3 py-1 text-sm"
              onClick={onDeleteObjetivo(kidx)}
            >
              Eliminar
            </button>
          </div>
        )}
        {!isEditing && (
          <div className="flex mt-4 gap-2">
            <button
              onClick={onVerHistorial(objetivo)}
              className="text-sm text-sky-500 flex item-center gap-1"
            >
              <ClockHistory className="mt-1" />
              <span>Ver historial</span>
            </button>
          </div>
        )}
      </CollapseCard>
    ));
  };

  const renderObjetivosArchivadosList = () => {
    const onVerHistorial = (kpi) => () => {
      setObjetivoHistory(kpi);
      setShowHistoryModal(true);
    };
    //
    return objetivosArchivados.map((kpi, kidx) => (
      <CollapseCard
        key={`kpi-${kidx}-${kpi.id}`}
        titulo={kpi.nombre}
        extraClass="mb-8"
        titleClass={"font-semibold"}
        initCollapse={false}
      >
        <div className="flex flex-col gap-2">
          <div>{kpi.descripcion}</div>
          <div className="flex">
            <div className="flex flex-col flex-1">
              <div className="font-semibold">Tiempo</div>
              <div>{kpi.periodo_display}</div>
            </div>
            <div className="flex flex-col flex-1">
              <div className="font-semibold">Tipo de metrica</div>
              <div>{kpi.metrica_display}</div>
            </div>
          </div>
          <div className="flex relative">
            <div className="flex flex-col flex-1">
              <div className="font-semibold">Meta</div>
              <div className="flex gap-2">
                {showObjetivoMeta(kpi)}
                {showObjetivoMeta(kpi) === "Por escala" && (
                  <div>{showEscala(kpi)}</div>
                )}
              </div>
            </div>
            <div className="flex flex-col flex-1">
              <div className="font-semibold">Creado por</div>
              {renderCreadoPor(kpi)}
            </div>
          </div>
        </div>
        <div className="flex mt-4 gap-2">
          <button
            onClick={onVerHistorial(kpi)}
            className="text-sm text-sky-500 flex item-center gap-1"
          >
            <ClockHistory className="mt-1" />
            <span>Ver historial</span>
          </button>
        </div>
      </CollapseCard>
    ));
  };

  const downloadPDF = () => {
    const token = getToken();
    const URL_BASE =
      process.env.REACT_APP_API_URL || "http://localhost:8000/api/admin";
    setDownloadLoading(true);
    axios({
      url:
        URL_BASE +
        `/objetivo-cargo/objetivo-cargo-colaborador/colaborador/${idColaborador}/exportar`,
      method: "POST",
      responseType: "blob", // Indica que la respuesta será un archivo binario
      headers: {
        Authorization: `Bearer ${token}`, // Agregamos la cabecera de autorización con el token Bearer
      },
    })
      .then((response) => {
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute(
          "download",
          `colaborador-${idColaborador}-objetivos.pdf`
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
        setDownloadLoading(false);
      })
      .catch((error) => {
        console.error("Error al descargar el archivo PDF:", error);
        setDownloadLoading(false);
      });
  };

  const discardChanges = () => {
    let deseaSalir = window.confirm("¿Desea salir sin guardar los cambios?");
    return deseaSalir;
  };
  // DATATABLE UTILS
  const [Page, setPage] = useState(1);
  const [PageSize, setPageSize] = useState(10);
  const [outerPageSize, setOuterPageSize] = React.useState(50);

  const onSearchKPINoVigente = (event) => {
    dispatch(
      objetivoActions.setColaboradorObjetivosNoVigentesFilter({
        ...objetivosNoVigentes_filter,
        id: idColaborador,
        nombre: event.target.value,
      })
    );
  };

  const onChangePageSize = (e) => {
    let value = e.target.value;
    //
    dispatch(
      objetivoActions.setColaboradorObjetivosNoVigentesFilter({
        ...objetivosNoVigentes_filter,
        id: idColaborador,
        pagination: {
          pageSize: parseInt(value),
          page: 1,
        },
      })
    );
    //
    setOuterPageSize(parseInt(value));
  };

  const onPaginate = (page, pageSize) => {
    //
    dispatch(
      objetivoActions.setColaboradorObjetivosNoVigentesFilter({
        ...objetivosNoVigentes_filter,
        id: idColaborador,
        pagination: {
          page: page,
          pageSize: pageSize,
        },
      })
    );
  };

  const onCheckObjetivoNoVigente = (item) => (event) => {
    dispatch(
      objetivoActions.checkObjetivoNoVigente({
        objetivo: item,
        checked: event.target.checked,
      })
    );
  };

  const onCheckObjetivoNoVigenteAll = (event) => {
    const checked = event.target.checked;
    dispatch(
      objetivoActions.setObjetivoNoVigentesChecked(
        checked ? objetivosNoVigentes : []
      )
    );
  };

  return (
    <>
      {/* HEADER */}
      <div className="flex justify-between items-center bg-zinc-100 -mx-8 py-2.5 px-7 border-b">
        <div className="flex items-center">
          <div
            onClick={() => navigate("/objetivos/cargos")}
            className={
              "h-full flex items-center pr-2 -mr-2 z-10 cursor-pointer"
            }
          >
            <img className="back" src={backButton} alt="Regresar" />
          </div>
          <div className="font-medium px-4 text-xl">
            Objetivos del colaborador
          </div>
        </div>
      </div>
      {/*  */}
      <div className="flex justify-center items-center w-full mt-10">
        <div className="flex flex-col w-full md:w-5/6">
          <div className="grid grid-cols-1 md:grid-cols-12">
            {/* IDENTIFICADOR VISUAL */}
            <div className="col-span-1 md:col-span-4 flex flex-col  mb-4 md:mr-12">
              <span className="font-bold text-lg border-b mb-2 pb-5">
                Identificador del colaborador
              </span>
              {!colaborador.foto_empleado && (
                <div className="my-4">
                  <img src={userDefault} alt="Imagen del colaborador" />
                </div>
              )}
              {!!colaborador.foto_empleado && (
                <div className="w-1/2 bg-slate-200 rounded-md">
                  <img
                    className="object-cover h-full w-full rounded-md"
                    src={colaborador.foto_empleado}
                    alt={`pfp-${colaborador.id}`}
                  />
                </div>
              )}
              <div className="flex flex-col mt-4">
                <label className="font-bold font-md" htmlFor="">
                  {`${colaborador.nombre} ${colaborador.apellido}`}
                </label>
                <div>{`Cargo: ${colaborador.cargo?.nombre || "Sin asignar"
                  }`}</div>
              </div>
              {/*  */}
              <div className="flex">
                {!downloadLoading && (
                  <button
                    onClick={downloadPDF}
                    className="bg-sky-500 rounded-md text-white p-2 flex items-center gap-2"
                  >
                    <FileEarmarkArrowDownFill size={18} />
                    <span className="font-semibold">Exportar PDF</span>
                  </button>
                )}
                {!!downloadLoading && (
                  <button className="bg-sky-500 rounded-md text-white p-2 animate-pulse">
                    Generando informe...
                  </button>
                )}
              </div>
            </div>
            {/* Objetivos Asignados */}
            <div className="col-span-1 md:col-span-8" ref={kpisContainerRef}>
              <div className="flex justify-between items-center border-b mb-2 pb-2">
                <div className="font-bold text-lg">
                  Objetivos por cargo asignados al colaborador
                </div>
                {!colaboradorObjetivos_loading && (
                  <div className="flex gap-2">
                    {!isEditing && (
                      <PrimaryButton
                        type="button"
                        onClick={() => setIsEditing(true)}
                      >
                        {!!objetivos?.results && objetivos?.results?.length > 0
                          ? "Editar"
                          : "Agregar"}
                      </PrimaryButton>
                    )}
                    {!!isEditing && (
                      <>
                        <PrimaryButton type="button" onClick={onSave}>
                          Guardar
                        </PrimaryButton>
                        <PrimaryButton
                          className="bg-red-500"
                          onClick={onCancel}
                        >
                          Cancelar
                        </PrimaryButton>
                      </>
                    )}
                  </div>
                )}
              </div>
              <div className="flex items-center justify-end text-lg mb-2 my-4">
                <div>
                  <span className="font-semibold">Total pesos: </span>
                  {pesoTotalObjetivos}%
                </div>
              </div>
              {/* Objetivos COLABORADOR */}
              {!colaboradorObjetivos_loading &&
                objetivosData.length > 0 &&
                renderObjetivosColaboradorList()}
              {!colaboradorObjetivos_loading && objetivosData.length < 1 && (
                <div className="border rounded-md p-4 text-center my-2">
                  No existen objetivos para mostrar
                </div>
              )}
              {!!colaboradorObjetivos_loading && <ObjetivoSkeletons />}
              {!!isEditing && !colaboradorObjetivos_loading && (
                <div
                  className="flex flex-row gap-2 items-center pl-3"
                  onClick={onAgregarObjetivo}
                >
                  <div className="w-5 h-5 rounded-full bg-sky-500 text-white flex items-center justify-center text-2xl">
                    +
                  </div>
                  <div className="text-sky-500 text-sm">{`Agregar ${objetivos?.results?.length > 0 ? "otro" : ""
                    } objetivo`}</div>
                </div>
              )}
              {/*  */}
              {!colaboradorObjetivos_loading &&
                objetivosArchivados.length > 0 && (
                  <>
                    <div className="flex justify-between items-center border-b mt-8 mb-8 pb-2">
                      <div className="font-bold text-lg">
                        Objetivos No Vigentes
                      </div>
                    </div>
                    {renderObjetivosArchivadosList()}
                  </>
                )}
              <SelectModal
                title={`${!!objetivoForm.id ? "Editar" : "Agregar"} objetivo`}
                content={
                  <div className="max-h-[500px] overflow-auto custom-scrollbar">
                    <KpiColaboradorForm
                      kpiColaborador={objetivoForm}
                      onSave={agregarObjetivo(objetivoFormIndex)}
                    />
                  </div>
                }
                onClose={() => discardChanges()}
              />
              {/*  */}
              <Modal isOpen={showHistoryModal}>
                <div className="rounded-md bg-white p-4">
                  <div className="w-100 flex justify-between mb-4">
                    <div className="font-semibold text-xl">
                      Historial de objetivo
                    </div>
                    <div onClick={onCloseHistoryModal}>
                      <XLg />
                    </div>
                  </div>
                  {/*  */}
                  <ObjetivoCargoHistorial {...objetivoHistory} />
                </div>
              </Modal>
            </div>
          </div>
          {/* ============= OBJETIVOS HISTORICO =========== */}
          <div className="grid grid-cols-1 md:grid-cols-12 mb-8 mt-4">
            <div className="col-span-1 md:col-span-12 border-b mb-6 mt-4 pb-2">
              <div className="font-bold text-lg">Historial de Objetivos</div>
            </div>
            <div className="col-span-1 md:col-span-6 mb-4 flex gap-4">
              <SearchInput
                placeholder="Buscar kpi..."
                value={objetivosNoVigentes_filter.nombre}
                onChange={onSearchKPINoVigente}
              />
              <MostrarPaginas
                containerClass={"w-1/2x"}
                onChangePageSize={onChangePageSize}
              />
              <ReactSelect
                className="w-52 h-10 bg-blue-200 inline-grid"
                value={{
                  value: "",
                  label: `(${objetivosNoVigentesChecked.length}) seleccionados`,
                }}
                options={actionOptions}
                onChange={onAction}
              ></ReactSelect>
            </div>
            <div className="col-span-1 md:col-span-12">
              <DataTable
                containerClass={"my-8x"}
                data={objetivosNoVigentes}
                loading={objetivosNoVigentes_loading}
                config={datatableConfig}
                // pagination
                totalRecords={objetivosNoVigentes_count}
                onPaginate={onPaginate}
                outerPageSize={PageSize}
                // check config
                checkable={true}
                onCheckAll={onCheckObjetivoNoVigenteAll}
                checkedItems={objetivosNoVigentesChecked}
                onItemCheck={onCheckObjetivoNoVigente}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
