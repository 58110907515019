import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { getToken } from "src/utils/helpers";
import { vacacionActions } from "./redux";
import vacacionApis from "./apis";
import { ErrorToast } from "src/modules/common/components/ErrorToast";
import { SuccessToast } from "src/modules/common/components/SuccessToast";

function* vacacionesListSaga({ payload }) {
    const token = getToken();
    try {
        const data = yield call(vacacionApis(token).vacacionesList, payload);
        yield put(vacacionActions.setVacaciones(data.results));
        yield put(vacacionActions.setVacacionesCount(data.count));
    } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log({ error });
        const data = error.response?.data || {};
        ErrorToast({ message: data.error || "Ocurrió un error inesperado" });
        yield put(vacacionActions.setVacaciones([]));
    }
}

function* colaboradorVacacionesDetailSaga({ payload }) {
    const token = getToken();
    try {
        const data = yield call(vacacionApis(token).registrosVacacionesDetail, payload);
        yield put(vacacionActions.setColaboradorVacacionesDetail(data));
    } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log({ error });
        const data = error.response?.data || {};
        ErrorToast({ message: data.error || "Ha ocurrido un error inesperado" });
        yield put(vacacionActions.setColaboradorVacacionesDetail({}));
    }
}

function* cargarRegistroVacacionSaga({ payload }) {
    const token = getToken();
    const { colaboradorId, params } = payload;
    try {
        const data = yield call(vacacionApis(token).createRegistroVacacion, params);
        yield put(vacacionActions.getColaboradorVacacionesDetail(colaboradorId));
        SuccessToast({ message: "Vacaciones registradas con éxito." });
    } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log({ error });
        const data = error.response?.data || {};
        ErrorToast({ message: data.error || "Ha ocurrido un error inesperado" });
    } finally {
        yield put(vacacionActions.submitRegistroVacacionFinish());
    }
}

function* updateRegistroVacacionSaga({ payload }) {
    const token = getToken();
    const { colaboradorId } = payload;
    try {
        const data = yield call(vacacionApis(token).updateRegistroVacacion, payload);
        yield put(vacacionActions.getColaboradorVacacionesDetail(colaboradorId));
        SuccessToast({ message: "Vacaciones actualizadas con éxito." });
    } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log({ error });
        const data = error.response?.data || {};
        ErrorToast({ message: data.error || "Ha ocurrido un error inesperado" });
    } finally {
        yield put(vacacionActions.submitRegistroVacacionFinish());
    }
}

function* deleteRegistroVacacionSaga({ payload }) {
    const token = getToken();
    const { colaboradorId, registroId } = payload;
    try {
        const data = yield call(vacacionApis(token).deleteRegistroVacacion, registroId);
        yield put(vacacionActions.getColaboradorVacacionesDetail(colaboradorId));
        SuccessToast({ message: "Vacaciones eliminadas con éxito." });
    } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log({ error });
        const data = error.response?.data || {};
        ErrorToast({ message: data.error || "Ha ocurrido un error inesperado" });
    } finally {
        yield put(vacacionActions.submitRegistroVacacionFinish());
    }
}


function* updateColaboradorVacacionesSaga({ payload }) {
    const token = getToken();
    const { colaboradorId } = payload;
    try {
        const data = yield call(vacacionApis(token).updateColaboradorVacaciones, payload);
        yield put(vacacionActions.getColaboradorVacacionesDetail(colaboradorId));
        SuccessToast({ message: "Vacaciones actualizadas con éxito." });
    } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log({ error });
        const data = error.response?.data || {};
        ErrorToast({ message: data.error || "Ha ocurrido un error inesperado" });
    }
}

export function* vacacionSagas() {
    yield takeEvery(vacacionActions.getVacaciones.type, vacacionesListSaga);
    yield takeLatest(vacacionActions.setVacacionesFilter.type, vacacionesListSaga);
    //
    yield takeEvery(vacacionActions.getColaboradorVacacionesDetail.type, colaboradorVacacionesDetailSaga);
    yield takeEvery(vacacionActions.cargarRegistroVacacion.type, cargarRegistroVacacionSaga);
    yield takeEvery(vacacionActions.updateRegistroVacacion.type, updateRegistroVacacionSaga);
    yield takeEvery(vacacionActions.deleteRegistroVacacion.type, deleteRegistroVacacionSaga);
    //
    yield takeEvery(vacacionActions.updateColaboradorVacaciones.type, updateColaboradorVacacionesSaga);
}