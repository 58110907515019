import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom';
import { vacacionActions } from '../handlers/redux';
import { commonActions } from 'src/modules/common/handler/redux';
import { Header2 } from 'src/modules/common/components/Header2';
import backButton from "src/modules/common/components/images/back-arrow.png";
import DataTable from 'src/modules/common/components/DataTable';
import { registrosVacacionesDatatableConfig } from '../helpers/constants';
import { PrimaryButton } from 'src/modules/common/components/PrimaryButton';
import ColaboradorVacacionDetail from '../components/ColaboradorVacacionDetail';
import { ErrorToast } from 'src/modules/common/components/ErrorToast';
import dayjs from 'dayjs';
import { contarDiasHabiles } from '../helpers/helper';
import CargaVacacionModal from '../components/CargaVacacionModal';

const registroVacacionDefault = {
    colaborador: null,
    fecha_desde: null,
    fecha_hasta: null,
    dias_tomados: null,
}

const RegistrosVacacionesDetailPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { idColaborador } = useParams();

    const { colaborador_vacacion, colaborador_vacacion_loading } = useSelector((state) => state.vacacion);

    const { registros_vacaciones = [], dias_habiles = [], dias_habiles_empresa = [] } = colaborador_vacacion;

    const [showModal, setShowModal] = useState(false);
    const [titleModal, setTitleModal] = useState("");
    const [registroVacacionLocal, setRegistroVacacionLocal] = useState(registroVacacionDefault);
    const [isEdit, setIsEdit] = useState(false); // Nuevo estado para indicar si es edición o creación

    useEffect(() => {
        dispatch(commonActions.setTitle("Admin"));
        dispatch(commonActions.showSidebar(false));
        if (idColaborador) {
            dispatch(vacacionActions.getColaboradorVacacionesDetail(idColaborador));
        }
    }, [idColaborador]);

    const onClickBack = useCallback(() => {
        navigate("/vacaciones/");
    }, [navigate]);

    const onBackComponent = () => {
        return (
            <div
                onClick={onClickBack}
                className={"h-full flex items-center pr-2 z-10 cursor-pointer mr-2"}
            >
                <img className="back" src={backButton} alt="Regresar" />
            </div>
        );
    };

    const onChange = useCallback((field) => (data) => {
        let value = null;
        let registroVacacionCopy = { ...registroVacacionLocal };
        if (field.includes("fecha")) {
            value = data;
            registroVacacionCopy[field] = value;
            const dias_tomados = contarDiasHabiles(
                registroVacacionCopy.fecha_desde, registroVacacionCopy.fecha_hasta, getDiasHabiles()
            );
            registroVacacionCopy.dias_tomados = dias_tomados;
        }
        else {
            value = data.target.value;
            registroVacacionCopy[field] = value;
        };
        setRegistroVacacionLocal(registroVacacionCopy);
    }, [contarDiasHabiles, registroVacacionLocal]);

    const openModal = useCallback(() => {
        setIsEdit(false); // Es creación
        setTitleModal("Cargar vacaciones");
        setShowModal(true);
    }, []);

    const closeModal = useCallback(() => {
        setIsEdit(false); // Resetear a creación
        setTitleModal("");
        setShowModal(false);
        setRegistroVacacionLocal(registroVacacionDefault);
    }, []);

    const onEditRegistro = (item) => {
        setIsEdit(true); // Se activa modo edición
        setRegistroVacacionLocal(item);
        setTitleModal("Editar registro");
        setShowModal(true);
    }

    const onDeleteRegistro = (item) => {
        const confirm = window.confirm("Estas seguro de eliminar el registro de vacaciones selecciondo?");
        if (!confirm) return;
        dispatch(vacacionActions.deleteRegistroVacacion({
            colaboradorId: idColaborador,
            registroId: item.id
        }));
    }

    const validarRegistroVacacion = useCallback(() => {
        return ["fecha_desde", "fecha_hasta", "dias_tomados"].every(
            field => registroVacacionLocal[field] !== null
        );
    }, [registroVacacionLocal]);

    const handleSubmit = useCallback((isEdit = false) => {
        if (!validarRegistroVacacion()) {
            ErrorToast({ message: "Todos los campos son obligatorios" });
            return;
        }
        const params = {
            ...registroVacacionLocal,
            colaborador: colaborador_vacacion.id,
            fecha_desde: dayjs(registroVacacionLocal.fecha_desde).format('YYYY-MM-DD'),
            fecha_hasta: dayjs(registroVacacionLocal.fecha_hasta).format('YYYY-MM-DD')
        };
        if (isEdit) {
            dispatch(vacacionActions.updateRegistroVacacion({
                colaboradorId: idColaborador,
                registroId: registroVacacionLocal.id,
                request: params
            }));
        } else {
            dispatch(vacacionActions.cargarRegistroVacacion({
                colaboradorId: idColaborador,
                params: params
            }));
        }
        closeModal();
    }, [dispatch, registroVacacionLocal, colaborador_vacacion, idColaborador, closeModal, validarRegistroVacacion]);

    const getDiasHabiles = useCallback(() => {
        if (colaborador_vacacion.tiene_dias_habiles_personalizados) {
            return dias_habiles.map((item) => {
                return item.dia;
            });
        }
        else if (dias_habiles_empresa.length > 0) {
            return dias_habiles_empresa.map((item) => {
                return item.dia;
            });
        }
        else {
            return [0, 1, 2, 3, 4]; // Lunes a Viernes por defecto.
        }
    }, [dias_habiles]);

    return (
        <>
            <Header2
                titulo={"Detalle del colaborador"}
                className={`bg-slate-100 -mx-8 px-6`}
                LeftComponent={onBackComponent}
            />
            <div className='flex justify-center my-8'>
                <div className='w-10/12'>
                    {/* Datos del colaborador */}
                    <ColaboradorVacacionDetail colaborador_vacacion={colaborador_vacacion} />
                    {/* Registros de vacaciones */}
                    <div className='border rounded-md mt-8 px-2'>
                        <div className='flex justify-between items-center p-4'>
                            <h1 className='font-bold text-lg'>Historial de vacaciones</h1>
                            <PrimaryButton onClick={openModal}>
                                Cargar vacaciones
                            </PrimaryButton>
                        </div>
                        <div className='max-h-[780px] custom-scrollbar'>
                            {/* @ts-ignore */}
                            <DataTable
                                // Data
                                containerClass={""}
                                data={registros_vacaciones}
                                loading={colaborador_vacacion_loading}
                                config={registrosVacacionesDatatableConfig(onEditRegistro, onDeleteRegistro)}
                                // Pagination
                                totalRecords={registros_vacaciones.length}
                                // Checkbox
                                checkable={false}
                            />
                        </div>
                    </div>
                </div>
                <CargaVacacionModal
                    showModal={showModal}
                    closeModal={closeModal}
                    title={titleModal}
                    registroVacacionLocal={registroVacacionLocal}
                    onSubmit={() => handleSubmit(isEdit)}
                    onChange={onChange}
                />
            </div>
        </>
    )
}

export default RegistrosVacacionesDetailPage