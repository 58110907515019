import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactSelect from "react-select";
import { areaActions } from "../handlers/redux";

export const AreaSelect = ({
  disabled,
  value,
  noDefaultValue = false,
  defaultLabel = "Todas las áreas",
  ...props
}) => {
  const dispatch = useDispatch();
  const areas = useSelector((state) => state.area.areas || {});
  const { results = [] } = areas;
  let areasParsed = results.map((s) => ({
    value: s.id,
    label: s.nombre,
  }));
  //
  if (!noDefaultValue) {
    areasParsed.unshift({ value: null, label: defaultLabel });
  }

  const selected = !props.isMulti
    ? areasParsed.find((v) => v.value == value)
    : areasParsed.filter((v) => value.includes(v.value));

    useEffect(() => {
      dispatch(areaActions.setAreasFilter({ nombre: "", pagination: { page: 1, pageSize: 1000 }}));
    }, []);

  return (
    <ReactSelect
      {...props}
      onChange={(item) =>
        props.onChange &&
        props.onChange({
          target: {
            value: !!props.isMulti ? item.map((i) => i.value) : item.value,
          },
        })
      }
      value={selected}
      options={areasParsed}
      isDisabled={disabled}
    />
  );
};
