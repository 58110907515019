import { createSlice } from "@reduxjs/toolkit";

const vacacionSlice = createSlice({
    name: "vacacion",
    initialState: {
        vacaciones: [],
        vacaciones_loading: false,
        vacaciones_count: 0,
        vacaciones_filter: {},
        vacaciones_checked: [],
        //
        colaborador_vacacion: {},
        colaborador_vacacion_loading: false,
        colaborador_vacacion_submit_loading: false,
    },
    reducers: {
        getVacaciones: (state, { payload = {} }) => {
            state.vacaciones_loading = true;
        },
        setVacaciones: (state, { payload = [] }) => {
            state.vacaciones = payload;
            state.vacaciones_loading = false;
        },
        setVacacionesCount: (state, { payload = 0 }) => {
            state.vacaciones_count = payload;
        },
        setVacacionesFilter: (state, { payload = {} }) => {
            state.vacaciones_filter = payload;
            state.vacaciones_loading = true;
        },
        setVacacionesChecked: (state, { payload = [] }) => {
            state.vacaciones_checked = payload;
        },
        resetVacacionesChecked: (state) => {
            state.vacaciones_checked = [];
        },
        checkVacaciones: (state, { payload: { vacacion, checked = false } }) => {
            state.vacaciones_checked = state.vacaciones_checked.filter(
                (item) => item.id !== vacacion.id
            );
            if (checked) {
                state.vacaciones_checked.push(vacacion);
            }
        },
        //
        getColaboradorVacacionesDetail: (state, { payload = {} }) => {
            state.colaborador_vacacion_loading = true;
        },
        setColaboradorVacacionesDetail: (state, { payload = {} }) => {
            state.colaborador_vacacion = payload;
            state.colaborador_vacacion_loading = false;
        },
        //
        cargarRegistroVacacion: (state, { payload = {} }) => {
            state.colaborador_vacacion_submit_loading = true;
        },
        submitRegistroVacacionFinish: (state, { payload = {} }) => {
            state.colaborador_vacacion_submit_loading = false;
        },
        updateRegistroVacacion: (state, { payload = {} }) => {
            state.colaborador_vacacion_submit_loading = true;
        },
        deleteRegistroVacacion: (state, { payload = {} }) => {
            state.colaborador_vacacion_submit_loading = true;
        },
        //
        updateColaboradorVacaciones: (state, { payload = {} }) => { },
    },
});

export const vacacionActions = vacacionSlice.actions;
export const vacacionReducer = vacacionSlice.reducer;