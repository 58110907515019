import React, { useCallback, useEffect, useState } from "react";
import { ChevronDown, ChevronUp, Search, X } from "react-bootstrap-icons";
import { areArraysEqual } from "../helpers/helpers";
import { DotMenu, DotMenuItem } from "./DotMenu";
import ReactSelect from "react-select";

function DataTableHeader({
  text,
  onOrder,
  onFilter,
  filterWithOptions = false, // Si la busqueda va ser mediante texto escrito (false) o selección de opciones (true). Se usa en Encuesta -> Reporte -> Resumen
  optionsFilter = [], // Las opciones para el filtro. Se usaran solo si filterWithOptions es true
}) {
  const [ordering, setOrdering] = useState("");
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  //
  const onOrderingClick = (e) => {
    e.stopPropagation();
    switch (ordering) {
      case "asc":
        setOrdering("desc");
        onOrder("desc");
        break;
      case "desc":
        setOrdering("");
        onOrder("");
        break;
      default:
        setOrdering("asc");
        onOrder("asc");
        break;
    }
  };
  const onShowSearchBar = () => {
    setShowSearchBar(true);
  };
  const onHideSearchBar = () => {
    setShowSearchBar(false);
    setInputValue("");
    setSelectedOptions([]);
    if (filterWithOptions) {
      onFilter(text, []);
    } else {
      onFilter(text, "");
    }
  };
  const onChangeText = (e) => {
    const inputText = e.target.value;
    setInputValue(inputText);
    onFilter(text, inputText);
  };
  const onChangeOption = (selected) => {
    console.log("Seleccionados:", selected);
    setSelectedOptions(selected);
    onFilter(text, selected);
  };
  //
  return (
    <div className="flex items-center gap-4">
      {!showSearchBar && <span>{text}</span>}
      {!!showSearchBar && (
        <div>
          {!filterWithOptions ? (
            <input
              value={inputValue}
              onChange={onChangeText}
              className="border rounded-md bg-slate-100 h-8 p-2 text-md font-medium"
              type="text"
            />
          ) : (
            <ReactSelect
              value={selectedOptions}
              onChange={onChangeOption}
              options={optionsFilter}
              isMulti
              placeholder={
                selectedOptions.length === 0
                  ? "Seleccione..."
                  : "Varios seleccionados"
              }
              noOptionsMessage={() => "No hay opciones"} // Mensaje si no hay resultados
              className="font-normal text-sm"
            />
          )}
        </div>
      )}
      {!!onOrder && (
        <span className="hover:cursor-pointer" onClick={onOrderingClick}>
          <ChevronUp
            color={ordering === "asc" || !ordering ? "black" : "gray"}
            size={11}
          ></ChevronUp>
          <ChevronDown
            color={ordering === "desc" || !ordering ? "black" : "gray"}
            size={11}
          ></ChevronDown>
        </span>
      )}
      {!!onFilter && (
        <span className="hover:cursor-pointer">
          {!showSearchBar && (
            <Search onClick={onShowSearchBar} size={18}></Search>
          )}
          {!!showSearchBar && <X onClick={onHideSearchBar} size={24}></X>}
        </span>
      )}
    </div>
  );
}

function DataTable({
  data = [],
  config,
  height = "",
  loading,
  containerClass = "",
  rowClass = "",
  totalRecords = 0,
  onPaginate,
  outerPage = 1,
  outerSetPage = null,
  outerPageSize = 100,
  checkable = true,
  checkedItems = [],
  checkedIdField = "id",
  onCheckAll,
  onItemCheck,
  onRowClick,
  noHeaders = false,
  fixedColumns = 0,
  minWidthColumn = "",
  addBorder = false,
}) {
  const getPageButtonsArray = (pagesCount) => {
    let pagesArr = [1];
    const pagesCountCeil = Math.ceil(pagesCount);
    for (let index = 2; index <= pagesCountCeil; index++) {
      pagesArr.push(index);
    }
    return pagesArr;
  };
  const { headers, rowActions } = config;
  // Pagination
  const [isFirstPagination, setIsFirstPagination] = useState(true);
  const [Page, setPage] = useState(outerPage);
  const [PageSize, setPageSize] = useState(outerPageSize);
  const [PagesButtonsArray, setPagesButtonsArray] = useState(
    getPageButtonsArray(totalRecords / PageSize)
  );
  //
  useEffect(() => {
    if (!!onPaginate && !!Page && !!PageSize) {
      if (!isFirstPagination) {
        onPaginate(Page, PageSize);
      } else {
        setIsFirstPagination(false);
      }
      setPagesButtonsArray(getPageButtonsArray(totalRecords / PageSize));
    }
  }, [Page, PageSize]);
  useEffect(() => {
    if (!!PageSize) {
      setPagesButtonsArray(getPageButtonsArray(totalRecords / PageSize));
    }
  }, [totalRecords]);

  useEffect(() => {
    setPageSize(outerPageSize);
  }, [outerPageSize]);

  useEffect(() => {
    setPage(outerPage);
  }, [outerPage]);

  const renderPageButtons = useCallback((setPage, Page, PagesButtonsArray) => {
    const buttonRender = (b, bidx) => (
      <button
        key={`pagebtn-${b}`}
        onClick={() => setPage(b)}
        className={`rounded-md ${b === Page
          ? "bg-sky-500 text-white"
          : "hover:bg-sky-500 hover:text-white"
          } py-1 px-2`}
      >
        {b}
      </button>
    );
    //-----------------------------------------------------------------------------------
    // Si hay menos de 7 botones, se renderizan todos juntos
    const totalButtons = PagesButtonsArray.length;
    const maxVisibleButtons = 6;

    if (totalButtons <= maxVisibleButtons) {
      return PagesButtonsArray.map((b, bidx) => buttonRender(b, bidx));
    }
    //-----------------------------------------------------------------------------------
    // Se hace dinámico los botones de paginación. Ej: 1 2 3 ... 17 18 19 ... 100 101 102
    const primerasPaginas = PagesButtonsArray.slice(0, 3);
    const ultimasPaginas = PagesButtonsArray.slice(-3);
    //Se eliminan los botones de primerasPaginas y de ultimasPaginas de la parte que se mostrará en medio
    const pageIndex = PagesButtonsArray.indexOf(Page);
    const paginasVariables = PagesButtonsArray.slice(
      pageIndex - 2,
      pageIndex + 3
    ).filter(
      (element) =>
        !primerasPaginas.includes(element) && !ultimasPaginas.includes(element)
    );
    const mostrarPaginasVariable = () => {
      // Retorna falso si la página seleccionada es la primer, la segunda, la penultuma o la ultima
      return (
        Page !== PagesButtonsArray[0] &&
        Page !== PagesButtonsArray[1] &&
        Page !== PagesButtonsArray[totalButtons - 1] &&
        Page !== PagesButtonsArray[totalButtons - 2]
      );
    };

    return (
      <>
        {primerasPaginas.map((b, bidx) => buttonRender(b, bidx))}
        {mostrarPaginasVariable() && (
          <>
            <span>...</span>
            {paginasVariables.map((b, bidx) => buttonRender(b, bidx))}
          </>
        )}
        <span>...</span>
        {ultimasPaginas.map((b, bidx) => buttonRender(b, bidx))}
      </>
    );
  }, [setPage, Page, PagesButtonsArray]);

  return (
    <div className={containerClass}>
      <div className={`${height} overflow-auto custom-scrollbar`}>
        <table className="min-w-full divide-y divide-gray-200">
          {/* TABLE HEAD */}
          {!noHeaders && (
            <thead className="">
              <tr>
                {!!checkable && !!onCheckAll && (
                  <th className="text-left sticky left-0 bg-white z-10">
                    <input
                      className="h-5 w-5 mr-2"
                      type="checkbox"
                      name="select-all"
                      id="select-all"
                      checked={
                        data.length > 0 &&
                        areArraysEqual(
                          data.map((di, dix) => di.id),
                          checkedItems.map((ci, cidx) => ci.id)
                        )
                      }
                      onChange={(e) => onCheckAll(e)}
                    />
                  </th>
                )}
                {!onCheckAll && <th></th>}
                {headers.map((header, hidx) => (
                  <th
                    key={`header-${hidx}`}
                    scope="col"
                    className={`${hidx < fixedColumns ? "sticky left-0 bg-white" : ""
                      } z-10 px-6 py-3 text-left tracking-wider ${minWidthColumn} ${addBorder ? "border-r border-gray-200" : ""
                      }`}
                  >
                    {/* {header.title} */}
                    <DataTableHeader
                      text={header.title}
                      onOrder={header.onOrder}
                      onFilter={header.onFilter}
                      filterWithOptions={header.filterWithOptions || false}
                      optionsFilter={header.optionsFilter || []}
                    />
                  </th>
                ))}
                <th></th>
              </tr>
            </thead>
          )}
          {/* TABLE BODY */}
          <tbody className="bg-white divide-y divide-gray-200">
            {!loading &&
              data.length > 0 &&
              data.map((item, idx) => (
                <tr
                  key={`row-${idx}`}
                  className={
                    rowClass + `${!!onRowClick ? "cursor-pointer" : ""}`
                  }
                >
                  {!!checkable && (
                    <td
                      style={{ width: "10px" }}
                      className="sticky left-0 bg-white z-10"
                    >
                      <input
                        className="h-5 w-5 ml-4"
                        type="checkbox"
                        name={`select-${idx}`}
                        id={`select-${idx}`}
                        checked={
                          !!checkedItems.find(
                            (citem, iidx) => citem[checkedIdField] === item.id
                          )
                        }
                        onChange={
                          !!onItemCheck
                            ? (e) => {
                              // e.stopPropagation();
                              onItemCheck(item)(e);
                            }
                            : null
                        }
                      // onChange={(e) => onSelectAllClick(e)}
                      />
                    </td>
                  )}
                  {!checkable && <td></td>}
                  {headers.map((header, hidx) => (
                    <td
                      key={`tdc-${hidx}`}
                      onClick={(e) => (!!onRowClick ? onRowClick(item) : null)}
                      className={`px-6 py-4 whitespace-nowrap ${addBorder ? "border-r border-gray-200" : ""
                        } ${minWidthColumn} ${hidx < fixedColumns ? "sticky left-0 bg-white z-10" : ""
                        }`}
                    >
                      {!!header.contentRenderer ? (
                        header.contentRenderer(item)
                      ) : (
                        <>
                          <div className={header.contentClass}>
                            {`${header.accessor
                              .split(".")
                              .reduce(function (obj, prop) {
                                return obj && obj[prop];
                              }, item)}`}
                          </div>
                          {!!header.descriptionRenderer
                            ? header.descriptionRenderer(item)
                            : !!header.description && (
                              <div>
                                {`${header.descriptionLabel
                                  ? header.descriptionLabel + " "
                                  : ""
                                  }`}
                                {item[header.description]}
                              </div>
                            )}
                        </>
                      )}
                    </td>
                  ))}
                  {rowActions.length > 0 && (
                    <td className="px-6x py-4x whitespace-nowrap">
                      <DotMenu
                        dir="left"
                        className="w-10 h-6 flex justify-end items-center"
                        onClick={(e) => e.stopPropagation()}
                      >
                        {rowActions.map((action, aidx) => (
                          <DotMenuItem
                            key={`action-${aidx}`}
                            onClick={() => action.action(item)}
                            className={`${action.className || ""}`}
                          >
                            {action.label}
                          </DotMenuItem>
                        ))}
                      </DotMenu>
                    </td>
                  )}
                </tr>
              ))}
            {/* LOADING */}
            {!!loading &&
              [1, 2, 3].map((l, lidx) => (
                <tr key={`loading-${lidx}`} className="animate-pulse">
                  {[...headers, ...[1, 2]].map((header, hidx) => (
                    <td key={`tdl-${hidx}`} className="px-2 py-8">
                      <div className="h-4 w-full bg-slate-200 rounded-md"></div>
                    </td>
                  ))}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {!loading && data.length < 1 && (
        <div className="w-full flex justify-center p-4 border-t">
          Sin registros para mostrar
        </div>
      )}
      {/* PAGINATION FOOTER */}
      <div className="flex items-center gap-2 justify-between border-t border-dashed pt-2 mt-1">
        <div>{`Mostrando ${Page} de ${PagesButtonsArray.length} de un total de ${totalRecords} registros`}</div>
        <div className="flex justify-end gap-0 border rounded-md">
          {renderPageButtons(setPage, Page, PagesButtonsArray)}
        </div>
      </div>
    </div>
  );
}

export default DataTable;
