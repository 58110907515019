import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ErrorToast } from "src/modules/common/components/ErrorToast";
import PercentageCircular from "src/modules/common/components/PercentageCircular";
import SelectModal from "src/modules/common/components/SelectModal";
import backButton from "src/modules/common/components/images/back-arrow.png";
import { commonActions } from "src/modules/common/handler/redux";
import CalibradoresList from "../components/CalibradoresList";
import CalibradoresMasivoList from "../components/CalibradoresMasivoList";
import ColaboradoresValidosList from "../components/ColaboradoresValidosList";
import ConfiguracionesAgregarList from "../components/ConfiguracionesAgregarList";
import ConfiguracionesAgregarMasivoList from "../components/ConfiguracionesAgregarMasivoList";
import ConfiguracionesList from "../components/ConfiguracionesList";
import EvaluacionColaboradoresTable from "../components/EvaluacionColaboradoresTable";
import EvaluadoresColaboradorList from "../components/EvaluadoresColaboradorList";
import EvaluadoresDefecto from "../components/EvaluadoresDefecto";
import EvaluadoresDefectoMasivo from "../components/EvaluadoresDefectoMasivo";
import { evaluacionActions } from "../handlers/redux";
import { ESTADOS_EVALUACION } from "../helpers/helpers";
import { getUserFromCookie } from "src/utils/helpers";
import LoadingSpinButton from "src/modules/common/components/LoadingSpinButton";
import LoadingSpinButton2 from "src/modules/common/components/LoadingSpinButton2";
import EvaluadoresRelacionadoColaboradorList from "../components/EvaluadoresRelacionadoColaboradorList";

const getModalContent = (modalContent) => {
  let content = <div></div>;
  switch (modalContent) {
    case "add-colaborador":
      content = <ColaboradoresValidosList></ColaboradoresValidosList>;
      break;
    case "add-evaluador":
      content = <EvaluadoresColaboradorList></EvaluadoresColaboradorList>;
      break;
    case "add-evaluador-relacionado":
      content = (
        <EvaluadoresRelacionadoColaboradorList></EvaluadoresRelacionadoColaboradorList>
      );
      break;
    case "add-evaluador-default":
      content = <EvaluadoresDefecto></EvaluadoresDefecto>;
      break;
    case "add-calibrador":
      content = <CalibradoresList></CalibradoresList>;
      break;
    case "add-configuracion":
      content = <ConfiguracionesAgregarList></ConfiguracionesAgregarList>;
      break;
    case "configuraciones":
      content = <ConfiguracionesList></ConfiguracionesList>;
      break;
    //
    case "add-evaluadores-default":
      content = <EvaluadoresDefectoMasivo></EvaluadoresDefectoMasivo>;
      break;
    case "add-calibradores":
      content = <CalibradoresMasivoList></CalibradoresMasivoList>;
      break;
    // case "add-calibradores-default":
    //   content = <ConfiguracionesList></ConfiguracionesList>;
    //   break;
    case "add-configuraciones":
      content = (
        <ConfiguracionesAgregarMasivoList></ConfiguracionesAgregarMasivoList>
      );
      break;
    default:
      break;
  }
  return content;
};

function Alert({ className = "", count = 0, message, onClick }) {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };
  return (
    <div
      className={`"border-3 rounded p-2 border border-sky-400 bg-sky-100" ${className}`}
    >
      <div className="flex flex-col items-centerx justify-center gap-1 text-sm px-2">
        <div>{message}</div>
        <div>
          <button onClick={handleClick} className="font-semibold">
            Ver cambios
          </button>
        </div>
      </div>
    </div>
  );
}
function CambiosNominaAlert({ className = "", count = 0, idEvaluacion }) {
  const navigate = useNavigate();
  const goToCambiosNomina = () => {
    navigate(`/evaluaciones/${idEvaluacion}/cambios-nomina`);
  };
  const message =
    count === 1
      ? `Se regristró ${count} cambio en su Nómina.`
      : `Se regristraron ${count} cambios en su Nómina.`;
  return (
    <Alert
      className={className}
      count={count}
      message={message}
      onClick={goToCambiosNomina}
    />
  );
}
function CambiosCompetenciasAlert({ className = "", count = 0, idEvaluacion }) {
  const navigate = useNavigate();
  const goToCambiosCompentecias = () => {
    navigate(`/evaluaciones/${idEvaluacion}/cambios-competencias`);
  };
  const message =
    count === 1
      ? `Se regristró ${count} cambio en sus Competencias.`
      : `Se regristraron ${count} cambios en sus Competencias.`;
  return (
    <Alert
      className={className}
      count={count}
      message={message}
      onClick={goToCambiosCompentecias}
    />
  );
}

const EvaluacionDetallePage = React.memo(function () {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const usuario = getUserFromCookie();
  const { es_admin_parcial = false } = usuario;
  const params = useParams();
  const [modalContent, setModalContent] = useState("");
  //
  const { idEvaluacion } = params;
  const evaluacion = useSelector((state) => state.evaluacion.evaluacion);
  const {
    estado,
    tiene_calibracion_superior,
    total_configuraciones,
    tipo_evaluacion,
  } = evaluacion;
  const evaluacion_loading = useSelector(
    (state) => state.evaluacion.evaluacion_loading
  )
  //
  const evaluacion_cambios = useSelector(
    (state) => state.evaluacion.evaluacion_cambios
  );
  const evaluacion_cambios_loading = useSelector(
    (state) => state.evaluacion.evaluacion_cambios_loading
  );
  //
  const formularios = useSelector((state) => state.evaluacion.formularios);
  const formulariosCount = useSelector(
    (state) => state.evaluacion.formulariosCount
  );
  const formularios_loading = useSelector(
    (state) => state.evaluacion.formularios_loading
  );
  const formularios_checked = useSelector(
    (state) => state.evaluacion.formularios_checked
  );
  const formularios_filter = useSelector(
    (state) => state.evaluacion.formularios_filter
  );
  const openColaboradoresModal = () => {
    setModalContent("add-colaborador");
    dispatch(commonActions.setSelectModal({ open: true }));
  };

  const openEvaluadoresModal = (formulario) => {
    setModalContent("add-evaluador");
    const { id: formulario_id } = formulario;
    const { id, nombre, apellido, foto_empleado } = formulario.evaluado;
    //
    dispatch(
      evaluacionActions.setEvaluadoresEvaluado({
        id: id,
        nombre: nombre,
        apellido: apellido,
        foto_empleado: foto_empleado,
        formulario_id: formulario_id,
      })
    );
    //
    dispatch(commonActions.setSelectModal({ open: true }));
  };

  const openEvaluadoresRelacionadoModal = (formulario) => {
    setModalContent("add-evaluador-relacionado");
    const { id: formulario_id } = formulario;
    const { id, nombre, apellido, foto_empleado } = formulario.evaluado;
    //
    dispatch(
      evaluacionActions.setEvaluadoresEvaluado({
        id: id,
        nombre: nombre,
        apellido: apellido,
        foto_empleado: foto_empleado,
        formulario_id: formulario_id,
      })
    );
    //
    dispatch(commonActions.setSelectModal({ open: true }));
  };

  const openEvaluadoresDefaultModal = (formulario) => {
    setModalContent("add-evaluador-default");
    const { id: formulario_id } = formulario;
    const { id, nombre, apellido, foto_empleado } = formulario.evaluado;
    //
    dispatch(
      evaluacionActions.setEvaluadoresEvaluado({
        id: id,
        nombre: nombre,
        apellido: apellido,
        foto_empleado: foto_empleado,
        formulario_id: formulario_id,
      })
    );
    //
    dispatch(commonActions.setSelectModal({ open: true }));
  };

  const agregarCalibradorDefecto = (formulario) => {
    dispatch(evaluacionActions.agregarCalibradorDefecto(formulario));
  };

  const openCalibradoresModal = (formulario) => {
    setModalContent("add-calibrador");
    const { id: formulario_id } = formulario;
    const { id, nombre, apellido, foto_empleado } = formulario.evaluado;
    //
    dispatch(
      evaluacionActions.setEvaluadoresEvaluado({
        id: id,
        nombre: nombre,
        apellido: apellido,
        foto_empleado: foto_empleado,
        formulario_id: formulario_id,
      })
    );
    //
    dispatch(commonActions.setSelectModal({ open: true }));
  };

  const openConfiguracionesAgregarModal = (formulario) => {
    setModalContent("add-configuracion");
    const { id: formulario_id } = formulario;
    const { id, nombre, apellido, foto_empleado } = formulario.evaluado;
    //
    dispatch(
      evaluacionActions.setEvaluadoresEvaluado({
        id: id,
        nombre: nombre,
        apellido: apellido,
        foto_empleado: foto_empleado,
        formulario_id: formulario_id,
      })
    );
    //
    dispatch(commonActions.setSelectModal({ open: true }));
  };
  const checkIfFormularioConfigurado = (formulario) => {
    let formularioEstaConfigurado = true;
    const { evaluador, configuracion } = formulario;
    if (!evaluador || !configuracion) {
      formularioEstaConfigurado = false;
    }
    return formularioEstaConfigurado;
  };
  const enviarEvaluacion = (formulario) => {
    if (!checkIfFormularioConfigurado(formulario)) {
      ErrorToast({
        message: "No puede enviar una evaluación sin evaluador y/o formulario",
      });
      return;
    }
    dispatch(evaluacionActions.setEnviarFormulario(formulario.id));
  };
  //
  const openEvaluadoresDefaultMasivoModal = () => {
    setModalContent("add-evaluadores-default");
    //

    //
    dispatch(commonActions.setSelectModal({ open: true }));
  };

  const openCalibradoresMasivoModal = () => {
    setModalContent("add-calibradores");
    //
    dispatch(commonActions.setSelectModal({ open: true }));
  };

  const asignarCalibradoresDefaultMasivo = () => {
    dispatch(evaluacionActions.agregarCalibradoresDefault());
  };

  const openConfiguracionesMasivoModal = () => {
    setModalContent("add-configuraciones");
    dispatch(commonActions.setSelectModal({ open: true }));
  };

  const enviarEvaluaciones = () => {
    let sonValidos = true;
    //
    for (let index = 0; index < formularios_checked.length; index++) {
      const form = formularios.find(
        (f, i) => f.id === formularios_checked[index]
      );
      if (!checkIfFormularioConfigurado(form)) {
        ErrorToast({
          message: "No puede enviar un formulario sin evaluador y/o formulario",
        });
        sonValidos = false;
        break;
      }
    }
    //
    if (sonValidos) {
      dispatch(evaluacionActions.setEnviarFormularios());
    }
  };

  const eliminarFormularios = () => {
    dispatch(evaluacionActions.setEliminarFormularios());
  };

  const openConfiguracionesModal = () => {
    setModalContent("configuraciones");
    dispatch(commonActions.setSelectModal({ open: true }));
  };

  const onEliminar = (formulario) => {
    let deseaEliminar = window.confirm(
      "Desea eliminar al colaborador seleccionado?"
    );
    if (deseaEliminar) {
      dispatch(evaluacionActions.setFormularioEliminar(formulario));
    }
  };
  const goToInforme = (formulario) => {
    const { evaluado } = formulario;
    if (!evaluado) {
      return;
    }
    navigate(`/evaluaciones/${idEvaluacion}/colaborador/${evaluado.id}`);
  };
  // TABLE HEADERS
  const setOrdering =
    (field) =>
      (order = "") => {
        let { ordering = "" } = formularios_filter;
        let orderingArray = ordering.split(",") || [];
        let emptyIndex = orderingArray.indexOf("");
        if (emptyIndex) {
          orderingArray.splice(emptyIndex, 1);
        }
        let fieldIndex = orderingArray.indexOf(field);
        fieldIndex =
          fieldIndex === -1 ? orderingArray.indexOf(`-${field}`) : fieldIndex;
        // si ya esta el campo, vemos si se modifica o elimina
        if (fieldIndex > 0) {
          orderingArray.splice(fieldIndex, 1);
          if (order) {
            field = order === "desc" ? `-${field}` : field;
            orderingArray[fieldIndex] = field;
          }
        } else {
          // si no está, lo agregamos
          field = order === "desc" ? `-${field}` : field;
          orderingArray.push(field);
        }
        ordering = orderingArray.join(",");
        //
        dispatch(
          evaluacionActions.setFormulariosFilter({
            ...formularios_filter,
            id: idEvaluacion,
            ordering: ordering,
          })
        );
      };

  const addFilterFilter = (field) => (value) => {
    dispatch(
      evaluacionActions.setFormulariosFilter({
        ...formularios_filter,
        id: idEvaluacion,
        [`${field}`]: value,
      })
    );
  };
  // Acciones de Quitar para un solo formulario
  const quitarEvaluador = (formulario) => {
    let confirmacion = window.confirm(
      "Está seguro que desea desvincular al evaluador del formulario seleccionado?"
    );
    if (confirmacion) {
      dispatch(
        evaluacionActions.setQuitarEvaluador({
          formularios_checked: [formulario.id],
        })
      );
    }
  };
  const quitarCalibrador = (formulario) => {
    let confirmacion = window.confirm(
      "Está seguro que desea desvincular al calibrador del formulario seleccionado?"
    );
    if (confirmacion) {
      dispatch(
        evaluacionActions.setQuitarCalibrador({
          formularios_checked: [formulario.id],
        })
      );
    }
  };
  const quitarFormulario = (formulario) => {
    let confirmacion = window.confirm(
      "Está seguro que desea desvincular el formulario de la filas seleccionada?"
    );
    if (confirmacion) {
      dispatch(
        evaluacionActions.setQuitarConfiguracion({
          formularios_checked: [formulario.id],
        })
      );
    }
  };
  // Acciones de Quitar para varios formularios
  const quitarEvaluadores = () => {
    if (formularios_checked.length < 1) {
      ErrorToast({ message: "No ha seleccionado ningun formulario" });
      return;
    }
    let confirmacion = window.confirm(
      "Está seguro que desea desvincular los evaluadores de los formularios seleccionados?"
    );
    if (confirmacion) {
      dispatch(
        evaluacionActions.setQuitarEvaluador({
          formularios_checked: formularios_checked,
        })
      );
    }
  };
  const quitarCalibradores = () => {
    if (formularios_checked.length < 1) {
      ErrorToast({ message: "No ha seleccionado ningun formulario" });
      return;
    }
    let confirmacion = window.confirm(
      "Está seguro que desea desvincular los calibradores de los formularios seleccionados?"
    );
    if (confirmacion) {
      dispatch(
        evaluacionActions.setQuitarCalibrador({
          formularios_checked: formularios_checked,
        })
      );
    }
  };
  const quitarFormularios = () => {
    if (formularios_checked.length < 1) {
      ErrorToast({ message: "No ha seleccionado ningun formulario" });
      return;
    }
    let confirmacion = window.confirm(
      "Está seguro que desea desvincular los formularios de las filas seleccionadas?"
    );
    if (confirmacion) {
      dispatch(
        evaluacionActions.setQuitarConfiguracion({
          formularios_checked: formularios_checked,
        })
      );
    }
  };

  const headers = [
    { text: "Colaborador", onOrder: false, onFilter: false },
    {
      text: "Evaluador",
      onOrder: setOrdering("evaluador__nombre"),
      onFilter: addFilterFilter("evaluador"),
    },
    {
      text: "Relación",
      onOrder: setOrdering("relacion"),
      onFilter: addFilterFilter("relacion"),
    },
    {
      text: "Calibrador",
      onOrder: setOrdering("calibrador__nombre"),
      onFilter: addFilterFilter("calibrador"),
    },
    {
      text: "Formulario",
      onOrder: setOrdering("configuracion__nombre"),
      onFilter: addFilterFilter("configuracionNombre"),
    },
    {
      text: "Puntaje",
      onOrder: setOrdering("puntaje_obtenido"),
      onFilter: addFilterFilter("puntaje"),
    },
    {
      text: "Estado",
      onOrder: setOrdering("estado"),
      onFilter: addFilterFilter("estado"),
    },
    { text: "Acción", onOrder: false, onFilter: false },
  ]
    .filter((header, hIdx) =>
      !tiene_calibracion_superior ? header.text !== "Calibrador" : header
    )
    .filter((header, hIdx) => {
      if (
        es_admin_parcial &&
        (header.text === "Puntaje" || header.text === "Acción")
      ) {
        return false;
      }
      return true;
    });
  const dropdownMasivoOptions = () => {
    const actions = [
      {
        action: openEvaluadoresDefaultMasivoModal,
        label: "Añadir evaluador por defecto",
      },
      {
        action: () => { },
        label: "Añadir calibrador",
        isDisabled: true,
        className: !tiene_calibracion_superior ? "!text-slate-500" : "",
        items: !!tiene_calibracion_superior
          ? [
            {
              action: openCalibradoresMasivoModal,
              label: "Añadir manualmente",
            },
            {
              action: asignarCalibradoresDefaultMasivo,
              label: "Añadir por defecto",
            },
          ]
          : [],
      },
      { action: openConfiguracionesMasivoModal, label: "Añadir formulario" },
      { action: enviarEvaluaciones, label: "Enviar evaluaciones" },
      {
        label: "Quitar",
        items: [
          { action: quitarEvaluadores, label: "Evaluadores" },
          { action: quitarCalibradores, label: "Calibradores" },
          { action: quitarFormularios, label: "Formularios" },
        ],
      },
      { action: () => { }, label: "Reiniciar" },
      // {
      //   action: () => dispatch(evaluacionActions.resetCheckFormularios()),
      //   label: "Cancelar",
      // },
      {
        action: () => {
          let deseaEliminar = window.confirm(
            "Desea eliminar los formularios seleccionados?"
          );
          if (deseaEliminar) {
            eliminarFormularios();
          }
        },
        label: "Eliminar",
        labelClass: "text-red-500",
      },
    ];
    return actions;
  };
  //
  const dropdownActions = (id, item = {}) => {
    const esJefeDirecto = item.relacion === "Jefe directo";
    let actions = [
      {
        label: "Añadir evaluador manualmente",
        disabled: !evaluacionEstaEnCurso(),
        action: () => {
          openEvaluadoresModal(item);
        },
      },
      {
        label: "Añadir evaluador (proceso relacionado)",
        disabled: !evaluacionEstaEnCurso(),
        action: () => {
          openEvaluadoresRelacionadoModal(item);
        },
      },
      {
        label: "Añadir evaluador por defecto",
        disabled: !evaluacionEstaEnCurso(),
        action: () => {
          openEvaluadoresDefaultModal(item);
        },
      },
      {
        label: "Añadir calibrador",
        disabled:
          !evaluacionEstaEnCurso() ||
          !esJefeDirecto ||
          !tiene_calibracion_superior,
        action: () => {
          agregarCalibradorDefecto(item);
        },
      },
      {
        label: "Añadir calibrador manualmente",
        disabled:
          !evaluacionEstaEnCurso() ||
          !esJefeDirecto ||
          !tiene_calibracion_superior,
        action: () => {
          openCalibradoresModal(item);
        },
      },
      {
        label: "Añadir formulario",
        disabled: !evaluacionEstaEnCurso(),
        action: () => {
          openConfiguracionesAgregarModal(item);
        },
      },
      //
      {
        label: "Quitar evaluador",
        disabled: !evaluacionEstaEnCurso(),
        action: () => {
          quitarEvaluador(item);
        },
      },
      {
        label: "Quitar calibrador",
        disabled: !evaluacionEstaEnCurso() || !esJefeDirecto,
        action: () => {
          quitarCalibrador(item);
        },
      },
      {
        label: "Quitar formulario",
        disabled: !evaluacionEstaEnCurso(),
        action: () => {
          quitarFormulario(item);
        },
      },
      //
      {
        label: "Enviar evaluación",
        disabled: !evaluacionEstaEnCurso(),
        action: () => {
          enviarEvaluacion(item);
        },
      },
      {
        label: "Ver informe",
        action: () => {
          goToInforme(item);
        },
      },
      // {
      //   label: "Reiniciar",
      //   action: () => {},
      // },
      {
        label: "Eliminar",
        disabled: !evaluacionEstaEnCurso(),
        action: () => onEliminar(item),
        className: "text-red-500",
      },
    ];
    return actions;
  };

  const getModalTitle = () => {
    let title = "";
    switch (modalContent) {
      case "add-colaborador":
        title = "Colaboradores";
        break;
      case "add-evaluador":
        title = "Evaluadores de:";
        break;
      case "add-evaluador-relacionado":
        title = "Colaboradores:";
        break;
      case "add-evaluador-default":
        title = "Añadir por defecto";
        break;
      case "add-calibrador":
        title = "Colaboradores";
        break;
      case "add-configuracion":
        title = "Formularios";
        break;
      case "configuraciones":
        title = "Formularios";
        break;
      case "add-evaluadores-default":
        title = "Añadir por defecto";
        break;
      case "add-calibradores":
        title = "Colaboradores";
        break;
      case "add-configuraciones":
        title = "Formularios";
        break;
      default:
        break;
    }
    return title;
  };
  const finalizarEvaluacion = () => {
    let deseaFinalizar = window.confirm(
      "Está seguro que desea finalizar la evaluación?"
    );
    if (deseaFinalizar) {
      dispatch(evaluacionActions.setEvaluacionFinalizar(evaluacion.id));
    }
  };
  const evaluacionEstaEnCurso = () => {
    return evaluacion?.estado?.value === ESTADOS_EVALUACION.EN_CURSO;
  };

  const onPaginate = (page, pageSize) => {
    dispatch(
      evaluacionActions.setFormulariosFilter({
        ...formularios_filter,
        pagination: {
          page: page,
          pageSize: pageSize,
        },
      })
    );
  };
  //
  useEffect(() => {
    dispatch(commonActions.setTitle(""));
    dispatch(commonActions.showSidebar(false));
    dispatch(evaluacionActions.getEvaluacion({ id: idEvaluacion }));
    dispatch(evaluacionActions.getEvaluacionCambios({ id: idEvaluacion }));
    dispatch(
      evaluacionActions.setFormulariosFilter({ id: idEvaluacion, nombre: "" })
    );
    dispatch(evaluacionActions.resetCheckFormularios());
  }, []);

  const onCloseSelectModal = () => {
    setModalContent(null);
    dispatch(evaluacionActions.resetCheckEvaluadoresColaborador());
  };

  //
  return (
    <>
      <div className="flex items-center bg-white py-2 px-8 -mx-8 gap-8 border-b">
        <div
          onClick={() => navigate("/evaluaciones/" + tipo_evaluacion)}
          className={"h-full  flex items-center pr-2 -mr-2 z-10 cursor-pointer"}
        >
          <img className="back" src={backButton} alt="Regresar" />
        </div>
        <div className="font-medium px-4 text-lg">{evaluacion.nombre}</div>
        <div className="flex flex-row flex-grow justify-end">
          <div className="flex flex-row gap-2 items-center">
            <div className=""></div>
            <div className="text-sm font-bold">Finaliza en:</div>
            <div className="text-sm">{evaluacion.dias_finalizar} días</div>
            <div className="h-2 w-56 flex items-center">
              <div className="bg-zinc-300 w-full h-2/3 rounded overflow-hidden">
                {!!evaluacion && evaluacion.porcentaje_finalizar >= 0 && (
                  <div
                    style={{
                      width: `${100 - evaluacion.porcentaje_finalizar}%`,
                    }}
                    className={`h-full bg-sky-500 `}
                  ></div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-2 items-center">
          <div className="text-sm">
            <button onClick={openConfiguracionesModal}>
              <span className="font-semibold text-sky-500">Formularios</span>
            </button>
          </div>
          <div className="rounded text-sm bg-sky-500 py-1 px-2 text-white">
            {total_configuraciones || 0}
          </div>
        </div>
        <div className="flex flex-row gap-2">
          {estado?.value === ESTADOS_EVALUACION.EN_CURSO && (
            <button
              onClick={finalizarEvaluacion}
              className="rounded py-2 px-3 bg-sky-500 text-white"
            >
              Finalizar evaluacion
            </button>
          )}
          {estado?.value === ESTADOS_EVALUACION.FINALIZADO && (
            <button
              disabled={true}
              className="rounded py-2 px-3 bg-slate-400 text-white"
            >
              Evaluación finalizada
            </button>
          )}
        </div>
      </div>
      {/* body */}
      <div className="flex flex-col gap-3 p-4">
        {/* totales */}
        {evaluacion_cambios_loading && (
          <div className="flex justify-start">
            {/* @ts-ignore */}
            <LoadingSpinButton2 message={"Verificando cambios"} />
          </div>
        )}
        {!evaluacion_cambios_loading && (
          <div className="flex flex-row justify-start gap-3">
            {evaluacion_cambios.cambios_nomina > 0 &&
              evaluacion.estado &&
              evaluacion.estado?.value === 1 && (
                <div>
                  <CambiosNominaAlert
                    count={evaluacion_cambios.cambios_nomina}
                    idEvaluacion={idEvaluacion}
                  />
                </div>
              )}
            {evaluacion_cambios.cambios_competencias_cargos +
              evaluacion_cambios.cambios_competencias_areas +
              evaluacion_cambios.cambios_competencias_generales +
              evaluacion_cambios.cambios_competencias_potenciales >
              0 &&
              evaluacion.estado &&
              evaluacion.estado?.value === 1 && (
                <div>
                  <CambiosCompetenciasAlert
                    count={
                      evaluacion_cambios.cambios_competencias_cargos +
                      evaluacion_cambios.cambios_competencias_areas +
                      evaluacion_cambios.cambios_competencias_generales +
                      evaluacion_cambios.cambios_competencias_potenciales
                    }
                    idEvaluacion={idEvaluacion}
                  />
                </div>
              )}
          </div>
        )}

        {!!evaluacion.totales_formularios && (
          <div className="flex flex-row justify-center gap-8 h-40x">
            <PercentageCircular
              strokeWidth={3}
              strokeColor={"blue"}
              value={evaluacion.totales_formularios?.evaluados_porcentaje}
              content={
                <>
                  <div className="font-bold text-2xl">{`${evaluacion.totales_formularios?.evaluados}/${evaluacion.cantidad_formularios}`}</div>
                  <div className="text-sm text-slate-400">Evaluaciones</div>
                </>
              }
            ></PercentageCircular>
            {!!evaluacion.tiene_calibracion_superior && (
              <PercentageCircular
                strokeWidth={3}
                strokeColor={"yellow"}
                value={evaluacion.totales_formularios?.calibrados_porcentaje}
                content={
                  <>
                    <div className="font-bold text-2xl">{`${evaluacion.totales_formularios?.calibrados}/${evaluacion.totales_formularios?.total_calibraciones}`}</div>
                    <div className="text-sm text-slate-400">Calibraciones</div>
                  </>
                }
              ></PercentageCircular>
            )}
            {!!evaluacion.tiene_devolucion && (
              <PercentageCircular
                strokeWidth={3}
                strokeColor={"red"}
                value={`${evaluacion.totales_formularios?.devueltos_porcentaje}`}
                content={
                  <>
                    <div className="font-bold text-2xl">{`${evaluacion.totales_formularios?.devueltos}/${evaluacion.totales_formularios?.total_devoluciones}`}</div>
                    <div className="text-sm text-slate-400">Devoluciones</div>
                  </>
                }
              ></PercentageCircular>
            )}
            {!!evaluacion.tiene_comentarios_evaluado && (
              <PercentageCircular
                strokeWidth={3}
                strokeColor={"green"}
                value={`${evaluacion.totales_formularios?.comentados_porcentaje}`}
                content={
                  <>
                    <div className="font-bold text-2xl">{`${evaluacion.totales_formularios?.comentados}/${evaluacion.totales_formularios?.total_comentarios}`}</div>
                    <div className="text-sm text-slate-400">Comentarios</div>
                  </>
                }
              ></PercentageCircular>
            )}
          </div>
        )}
        {/* loading totales */}
        {!evaluacion.totales_formularios && (
          <div className="flex flex-row justify-center gap-8 animate-pulse">
            <div className="w-36 h-36 bg-slate-200 rounded-full"></div>
            <div className="w-36 h-36 bg-slate-200 rounded-full"></div>
            <div className="w-36 h-36 bg-slate-200 rounded-full"></div>
            <div className="w-36 h-36 bg-slate-200 rounded-full"></div>
          </div>
        )}
        {/*  */}
        <div className="flex flex-col gap-2 border-b pb-2 mt-2">
          <div className="font-bold text-md">Lista de evaluaciones</div>
        </div>
        {/*  */}
        <div className="relative">
          <EvaluacionColaboradoresTable
            data={formularios}
            headers={headers}
            hayCalibracion={!!tiene_calibracion_superior}
            extraClass={"mt-4"}
            loading={formularios_loading || evaluacion_loading}
            actionButtonHandler={
              estado?.value === ESTADOS_EVALUACION.EN_CURSO
                ? openColaboradoresModal
                : null
            }
            actionButtonLabel={"Añadir colaborador a evaluar"}
            dropdownActions={dropdownActions}
            headerDropdownOptions={dropdownMasivoOptions()}
            colActions={[
              openEvaluadoresModal,
              openCalibradoresModal,
              openConfiguracionesAgregarModal,
            ]}
            esAdminParcial={es_admin_parcial}
            extraData={{
              ...params,
              ...{
                tieneCalibracion: evaluacion.tiene_calibracion_superior,
                tieneDevolucion: evaluacion.tiene_devolucion,
                tieneComentarios: evaluacion.tiene_comentarios_evaluado,
              },
            }}
            onPaginate={onPaginate}
            totalPaginate={formulariosCount}
          ></EvaluacionColaboradoresTable>
        </div>
      </div>
      {/*  */}
      <SelectModal
        title={getModalTitle()}
        content={getModalContent(modalContent)}
        extraClass={"w-8/12"}
        onClose={onCloseSelectModal}
      ></SelectModal>
    </>
  );
});

export default EvaluacionDetallePage;
