import React from "react";
import { CheckLg, PencilFill, XLg } from "react-bootstrap-icons";
const BoxNineboxRow = (props) => {
    const {
        box,
        index,
        onEditRow,
        isEditable = false,
        isEditing = false,
        setIsEditing,
    } = props;

    const onEdit = () => {
        setIsEditing(true);
    };

    const onSave = () => {
        setIsEditing(false);
    };

    const onCancel = () => {
        setIsEditing(false, true);
    };

    const onChange = (e) => {
        let value = e.target.value;
        onEditRow(index, value);
    };

    return (
        <div className="w-full justify-between flex border-2x border-red-500x">
            {!!isEditable && (
                <>
                    <div className="group h-12 flex justify-between items-center gap-2 borderx border-blue-400x w-1/2 font-bold uppercase py-2">
                        {!isEditing && <div>{box.nombre}</div>}
                        {!!isEditing && (
                            <div>
                                <input
                                    type="text"
                                    className="border rounded placeholder-zinc-600 border-zinc-200 w-2/3 px-2 py-1"
                                    value={box.nombre || ""}
                                    onChange={onChange}
                                />
                            </div>
                        )}
                        <div className="gap-1 mr-8 hidden group-hover:flex">
                            {!isEditing && (
                                <button className="border p-2 rounded-md" onClick={onEdit}>
                                    <PencilFill />
                                </button>
                            )}
                            {!!isEditing && (
                                <>
                                    <button
                                        className="border p-2 rounded-md bg-sky-500"
                                        onClick={onSave}
                                    >
                                        <CheckLg size={20} color={"white"}></CheckLg>
                                    </button>
                                    <button className="border p-2 rounded-md" onClick={onCancel}>
                                        <XLg size={20}></XLg>
                                    </button>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="flex flex-1 gap-2 items-center borderx border-blue-500x py-2 w-1/2">
                        {/* Puntaje Desde */}
                        <div className="flex-1">
                            {box.nivel_potencial}
                        </div>
                        {/* Puntaje Hasta */}
                        <div className="flex-1">
                            <div className="flex items-center justify-between gap-2 w-1/2">
                                {box.nivel_desempeno}
                            </div>
                        </div>
                    </div>
                </>
            )}
            {/* ReadOnly Row */}
            {!isEditable && (
                <>
                    <div className="group h-12 flex justify-between items-center gap-2 borderx border-blue-400x w-1/2 font-bold uppercase py-2">
                        <div>{box.nombre}</div>
                    </div>
                    <div className="flex flex-1 gap-2 items-center borderx border-blue-500x py-2 w-1/2">
                        <div className="flex-1">{box.nivel_potencial}%</div>
                        <div className="flex-1">{box.nivel_desempeno}%</div>
                    </div>
                </>
            )}
        </div>
    );
};

export default BoxNineboxRow;
