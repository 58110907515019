import React, { useCallback, useEffect, useState } from 'react'
import VacacionesPageLayout from '../layouts/VacacionesPageLayout'
import { useDispatch, useSelector } from 'react-redux';
import useSucursales from 'src/modules/evaluacion/hooks/useSucursales';
import useAreas from 'src/modules/evaluacion/hooks/useAreas';
import useDepartamentos from 'src/modules/evaluacion/hooks/useDepartamentos';
import SearchSelect from 'src/modules/common/components/SearchSelect';
import { vacacionActions } from '../handlers/redux';
import SearchInput from 'src/modules/remuneracion/components/SearchInput';
import ReactSelect from 'react-select'
import MostrarPaginas from 'src/modules/common/components/MostrarPaginas';
import { ErrorToast } from 'src/modules/common/components/ErrorToast';
import DataTable from 'src/modules/common/components/DataTable';
import { vacacionesDatatableConfig } from '../helpers/constants';
import { useNavigate } from 'react-router-dom';

const VacacionesPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {
        vacaciones,
        vacaciones_loading,
        vacaciones_count,
        vacaciones_filter,
        vacaciones_checked,
        // @ts-ignore
    } = useSelector((state) => state.vacacion);
    const { searchField = "" } = vacaciones_filter;

    const [outerPage, setOuterPage] = useState(1);
    const [outerPageSize, setOuterPageSize] = useState(50);

    // Hooks de Estructura para los filtros
    const { sucursales, nombre: nombreSucursal, filterSucursales, } = useSucursales();
    const { areas, nombre: nombreArea, filterAreas } = useAreas();
    const { departamentos, nombre: nombreDepartamento, filterDepartamentos, } = useDepartamentos();

    useEffect(() => {
        dispatch(vacacionActions.setVacacionesFilter({}));
        dispatch(vacacionActions.getVacaciones({}));
    }, []);

    const handleFilterChange = useCallback((key, value) => {
        setOuterPage(1);
        dispatch(vacacionActions.setVacacionesFilter({
            ...vacaciones_filter,
            [key]: value,
        }));
    }, [dispatch, vacaciones_filter]);

    const renderSearchSelect = useCallback((data, defaultText, searchValue, filterFunction, filterKey) => (
        <SearchSelect
            data={data}
            defaultText={defaultText}
            searchValue={searchValue}
            filterFunction={filterFunction}
            onSelectItem={(item) => handleFilterChange(filterKey, item)}
        />
    ), [handleFilterChange]);

    const onSearchFieldChange = useCallback((e) => {
        setOuterPage(1);
        let searchText = e.target.value;
        dispatch(
            vacacionActions.setVacacionesFilter({
                ...vacaciones_filter,
                searchField: searchText,
            })
        );
    }, [dispatch, vacaciones_filter]);

    const actionOptions = [
        {
            value: "export-all",
            label: "Exportar todos",
        },
        {
            value: "export-selected",
            label: "Exportar seleccionados",
        },
    ];
    const onAction = (v) => {
        if (v.value === "export-all") {
            const vacacionesIds = vacaciones.map((e) => e.id);
            console.log(vacacionesIds)
        } else if (v.value === "export-selected") {
            const vacacionesIds = vacaciones_checked.map((e) => e.id);
            if (vacacionesIds.length === 0) {
                ErrorToast({ message: "Debes seleccionar al menos un colaborador." });
                return;
            }
            console.log(vacacionesIds)
        }
    };

    const onPaginate = useCallback((page, pageSize) => {
        setOuterPage(page);
        setOuterPageSize(pageSize);
        dispatch(
            vacacionActions.setVacacionesFilter({
                ...vacaciones_filter,
                pagination: {
                    page: page,
                    pageSize: pageSize,
                },
            })
        );
    }, [dispatch, vacaciones_filter]);

    const onCheckAll = () => {
        let checkedData = JSON.parse(JSON.stringify(vacaciones));
        if (vacaciones_checked.length > 0) {
            checkedData = [];
        }
        dispatch(vacacionActions.setVacacionesChecked(checkedData));
    };

    const onCheckItem = (item) => (event) => {
        dispatch(
            vacacionActions.checkVacaciones({
                vacacion: item,
                checked: event.target.checked,
            })
        );
    };

    const onRowClick = (item) => {
        navigate(`/vacaciones/${item.id}/`);
    }

    const setOrdering = useCallback(
        (field) =>
            (order = "") => {
                let { ordering = "" } = vacaciones_filter;
                let orderingArray = ordering.split(",") || [];
                let emptyIndex = orderingArray.indexOf("");
                if (emptyIndex) {
                    orderingArray.splice(emptyIndex, 1);
                }
                let fieldIndex = orderingArray.indexOf(field);
                fieldIndex =
                    fieldIndex === -1 ? orderingArray.indexOf(`-${field}`) : fieldIndex;
                // si ya esta el campo, vemos si se modifica o elimina
                if (fieldIndex > 0) {
                    orderingArray.splice(fieldIndex, 1);
                    if (order) {
                        field = order === "desc" ? `-${field}` : field;
                        orderingArray[fieldIndex] = field;
                    }
                } else {
                    // si no está, lo agregamos
                    field = order === "desc" ? `-${field}` : field;
                    orderingArray.push(field);
                }
                ordering = orderingArray.join(",");
                //
                dispatch(
                    vacacionActions.setVacacionesFilter({
                        ...vacaciones_filter,
                        ordering: ordering,
                    })
                );
            }, [dispatch, vacaciones_filter]);

    const onChangePageSize = useCallback((e) => {
        let value = e.target.value;
        const page = 1;
        const pageSize = parseInt(value);
        dispatch(
            vacacionActions.setVacacionesFilter({
                ...vacaciones_filter,
                pagination: {
                    pageSize: pageSize,
                    page: page,
                },
            })
        );
        setOuterPage(page);
        setOuterPageSize(pageSize);
    }, [dispatch, vacaciones_filter]);

    return (
        <VacacionesPageLayout>
            <h1 className='text-xl font-bold'>Control de vacaciones</h1>
            {/* Busqueda, paginacion y acciones masivas */}
            <div className="flex justify-between gap-4 mt-4">
                {/* @ts-ignore */}
                <SearchInput
                    value={searchField}
                    onChange={onSearchFieldChange}
                    placeholder="Buscar por nombre o cargo"
                />
                {/* @ts-ignore */}
                <MostrarPaginas
                    containerClass={"w-1/2x"}
                    defaultOption={50}
                    options={[10, 50, 100, 200, 500]}
                    onChangePageSize={onChangePageSize}
                />
                {/* @ts-ignore */}
                <ReactSelect
                    className="w-auto h-10 inline-grid text-sm"
                    value={{
                        value: "",
                        label: `(${vacaciones_checked.length}) seleccionados`,
                    }}
                    options={actionOptions}
                    onChange={onAction}
                />
            </div>
            {/* Filtros de estructura */}
            <div className="flex gap-8 mt-4">
                {renderSearchSelect(sucursales, "Sucursales: Todos", nombreSucursal, filterSucursales, 'sucursal')}
                {renderSearchSelect(areas, "Áreas: Todas", nombreArea, filterAreas, 'area')}
                {renderSearchSelect(departamentos, "Departamentos: Todos", nombreDepartamento, filterDepartamentos, 'departamento')}
            </div>
            {/* Tabla de registros */}
            <div className='border rounded-md mt-4 px-2'>
                {/* @ts-ignore */}
                <DataTable
                    // Data
                    containerClass={""}
                    height="max-h-[550px]"
                    data={vacaciones}
                    loading={vacaciones_loading}
                    config={vacacionesDatatableConfig(setOrdering)}
                    onRowClick={onRowClick}
                    // Pagination
                    totalRecords={vacaciones_count}
                    onPaginate={onPaginate}
                    outerPage={outerPage}
                    outerPageSize={outerPageSize}
                    // Checkbox
                    checkable={true}
                    onCheckAll={onCheckAll}
                    checkedItems={vacaciones_checked}
                    onItemCheck={onCheckItem}
                />
            </div>
        </VacacionesPageLayout>
    )
}

export default VacacionesPage