import RegistrosVacacionesDetailPage from "../pages/RegistrosVacacionesDetailPage";
import VacacionesPage from "../pages/VacacionesPage";

export const vacacionRoutes = [
    {
        path: "/vacaciones/",
        // @ts-ignore
        element: <VacacionesPage />
    },
    {
        path: "/vacaciones/:idColaborador/",
        // @ts-ignore
        element: <RegistrosVacacionesDetailPage />
    }
];