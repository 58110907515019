import React from 'react'
import { X } from 'react-bootstrap-icons'
import Modal from 'src/modules/common/components/Modal'
import DatePicker from "react-datepicker";
import { utcToZonedTime } from "date-fns-tz";
import { PrimaryButton } from 'src/modules/common/components/PrimaryButton';

const CargaVacacionModal = ({
    showModal,
    closeModal,
    title = "Cargar vacaciones",
    registroVacacionLocal,
    onSubmit,
    onChange,
}) => {
    return (
        <Modal isOpen={showModal}>
            <div className="bg-white w-1/3 p-2 rounded-md shadow-lg flex flex-col px-8">
                <div className="modal-title">
                    <div className="w-full flex justify-between items-center my-4">
                        <div className="flex items-center gap-4">
                            <h2 className="font-semibold text-xl">{title}</h2>
                        </div>
                        <button
                            onClick={closeModal}
                        >
                            <X size={32} />
                        </button>
                    </div>
                </div>
                <div className="modal-body custom-scrollbar">
                    <div className='flex flex-col mb-4 gap-4'>
                        <div className='flex flex-col'>
                            <label className='text-sm font-semibold'>Periodo</label>
                            <div className='flex justify-between gap-4'>
                                <DatePicker
                                    className="border rounded placeholder-zinc-600 border-zinc-200 px-2 py-1.5 w-full"
                                    locale="es"
                                    placeholderText="Desde"
                                    selected={utcToZonedTime(
                                        registroVacacionLocal.fecha_desde,
                                        "America/Asuncion"
                                    ).getTime()}
                                    onChange={onChange("fecha_desde")}
                                    dateFormat="dd/MM/yyyy"
                                />
                                <DatePicker
                                    className="border rounded placeholder-zinc-600 border-zinc-200 px-2 py-1.5 w-full"
                                    locale="es"
                                    placeholderText="Hasta"
                                    selected={utcToZonedTime(
                                        registroVacacionLocal.fecha_hasta,
                                        "America/Asuncion"
                                    ).getTime()}
                                    onChange={onChange("fecha_hasta")}
                                    dateFormat="dd/MM/yyyy"
                                />
                            </div>
                        </div>
                        <div className='flex flex-col'>
                            <label className='text-sm font-semibold'>Días tomados</label>
                            <input
                                className="border rounded placeholder-zinc-600 border-zinc-200 px-2 py-1.5"
                                type="number"
                                value={registroVacacionLocal.dias_tomados}
                                placeholder="Cantidad de días"
                                onChange={onChange("dias_tomados")}
                            />
                        </div>
                        <PrimaryButton onClick={onSubmit}>
                            Guardar
                        </PrimaryButton>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default CargaVacacionModal